<div class="roles-list" *ngIf="roles" [ngClass]="{'select-view-mode': viewSelectMode}">
    <div class="role" *ngFor="let role of roles" [@flyIn]="'in'" [ngClass]="roleStyles(role)"
        (click)="isRoleSelected(role) ? null : select.next(role)">

        <div class="info">
            <div class="name">{{role.name}}</div>
        </div>

        <div class="inline-button edit-button" (click)="editClick(role);$event.stopPropagation();">
            <i class="zmdi zmdi-edit"></i>
        </div>
        <div class="inline-button delete-button" (click)="deleteClick(role);$event.stopPropagation();">
            <i class="zmdi zmdi-delete"></i>
        </div>
    </div>
</div>
