import { Component, Input, OnInit } from '@angular/core';
import { List } from 'immutable';
import '../../global/helpers/rxjsExtension';

export interface IDropdownItem {
    label: string;
    callback: Function;
    icon?: string;
    isSeparated?: boolean;
}

@Component({
    selector: 'wf-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

    @Input() items: List<IDropdownItem>;

    isMenuShown = false;

    constructor() { }

    ngOnInit() {
    }

    toggleMenu() {
        this.isMenuShown = !this.isMenuShown;
    }


}
