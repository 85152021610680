/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./permission-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../highlight-on-hover.directive";
import * as i4 from "@angular/forms";
import * as i5 from "./permission-selector.component";
var styles_PermissionSelectorComponent = [i0.styles];
var RenderType_PermissionSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PermissionSelectorComponent, data: {} });
export { RenderType_PermissionSelectorComponent as RenderType_PermissionSelectorComponent };
export function View_PermissionSelectorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "horizontal-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "permissions-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "option"], ["wfHighlightOnHover", ""]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectNone() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "selected": 0 }), i1.ɵdid(8, 16384, null, 0, i3.HighlightOnHoverDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "zmdi zmdi-eye-off"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "option"], ["wfHighlightOnHover", ""]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectViewOnly() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "selected": 0 }), i1.ɵdid(14, 16384, null, 0, i3.HighlightOnHoverDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "zmdi zmdi-eye"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "option"], ["wfHighlightOnHover", ""]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.selectEdit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(18, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(19, { "selected": 0 }), i1.ɵdid(20, 16384, null, 0, i3.HighlightOnHoverDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "zmdi zmdi-edit"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "option"; var currVal_2 = _ck(_v, 7, 0, _co.isNoneSelected()); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = "option"; var currVal_4 = _ck(_v, 13, 0, _co.isViewSelected()); _ck(_v, 12, 0, currVal_3, currVal_4); var currVal_5 = "option"; var currVal_6 = _ck(_v, 19, 0, _co.isEditSelected()); _ck(_v, 18, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
export function View_PermissionSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "wf-permission-selector", [], null, null, null, View_PermissionSelectorComponent_0, RenderType_PermissionSelectorComponent)), i1.ɵprd(4608, null, i4.DefaultValueAccessor, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]]), i1.ɵdid(2, 49152, null, 0, i5.PermissionSelectorComponent, [[2, i4.NgControl]], null, null)], null, null); }
var PermissionSelectorComponentNgFactory = i1.ɵccf("wf-permission-selector", i5.PermissionSelectorComponent, View_PermissionSelectorComponent_Host_0, { label: "label", isEdit: "isEdit" }, {}, []);
export { PermissionSelectorComponentNgFactory as PermissionSelectorComponentNgFactory };
