/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dropdown.component";
var styles_DropdownComponent = [i0.styles];
var RenderType_DropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "zmdi zmdi-", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.$implicit.callback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { separated: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.isSeparated); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.icon; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.label; _ck(_v, 6, 0, currVal_3); }); }
function View_DropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "menu tile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dropdown"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuShown; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-dropdown", [], null, null, null, View_DropdownComponent_0, RenderType_DropdownComponent)), i1.ɵdid(1, 114688, null, 0, i3.DropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownComponentNgFactory = i1.ɵccf("wf-dropdown", i3.DropdownComponent, View_DropdownComponent_Host_0, { items: "items" }, {}, []);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
