import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Client } from '@wildflowerhealth/console-shared';

@Injectable()
export class ClientService {

    private API_URL: string;

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
        this.API_URL = this.environmentService.getAPIUrl() + '/clients';
    }

    fetch(): Observable<List<Client>> {
        return this.http
            .get<any[]>(this.API_URL)
            .pipe(map(data => List(data)
                .map(item => new Client(item))
                .toList()
            ));
    }

    deleteClient(client: Client) {
        return this.http.delete(this.API_URL + `/${client.id}`);
    }

    createNewClient(client: Client) {
        return this.http.post(this.API_URL, client.toJSON());
    }

    updateClient(client: Client) {
        return this.http.put(this.API_URL, client.toJSON());
    }

}
