import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'wf-image-icon',
    templateUrl: './image-icon.component.html',
    styleUrls: ['./image-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageIconComponent implements OnInit {

    @Input() image: string = null;
    @Input() size = 6;
    @Input() icon = 'zmdi-help';
    @Input() showIconBackground = true;

    constructor() { }

    ngOnInit() {
    }

}
