<div class="input-group">
    <div class="select-wrapper">
        <select #wfSelect [ngClass]="{
                    'ng-touched': parentNgControl.touched,
                    'ng-untouched': !parentNgControl.touched,
                    'ng-valid': parentNgControl.valid,
                    'ng-invalid': !parentNgControl.valid,
                    'ng-pristine': parentNgControl.pristine,
                    'ng-dirty': !parentNgControl.pristine,
                    'wf-empty': value === '',
                    'wf-filled': value !== '' && value !== null,
                    'wf-active': alwaysActivated
                }" [value]="value" [disabled]="wfDisabled" (blur)="onTouchCallback()"
            (change)="update(wfSelect.value)">
            <option *ngIf="showDisabledFirstOption" value disabled [selected]="!parentNgControl.value"></option>
            <option *ngFor="let option of options"
                [selected]="(option.value != null ? option.value : option) === parentNgControl.value"
                value="{{option.value != null ? option.value : option}}">
                {{ option.name != null ? option.name : option }}
            </option>
        </select>
        <span class="bar"></span>
        <label>{{label}}</label>

        <div class="errors">
            <div *ngIf="error === 'required'">
                This field is required
            </div>
            <div *ngIf="error === 'wrongValue'">
                Wrong value selected
            </div>
        </div>
    </div>
</div>
