import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { Application, PhoneEnvironment, IDistributedConfig } from '@wildflowerhealth/console-shared';
import { ApplicationUserService } from '../../services/application-user.service';

const PHONE_ENV_LABELS = Object.freeze({
    [PhoneEnvironment.DEVELOPMENT]: 'Development (DEV)',
    [PhoneEnvironment.IAT]: 'Internal Testing (IAT)',
    [PhoneEnvironment.UAT]: 'Customer Testing (UAT)',
    [PhoneEnvironment.PRODUCTION]: 'Live (PRODUCTION)',
});

@Component({
    selector: 'wf-application-users-page',
    templateUrl: './application-users-page.component.html',
    styleUrls: ['./application-users-page.component.scss']
})
export class ApplicationUsersPageComponent implements OnInit {

    public application: Application;
    public title: string;
    public logo: string;
    public phoneEnv: string = PhoneEnvironment.IAT;
    public email = '';
    public busy = false;
    public responses: Array<{ mode: 'test'|'delete', success: boolean, email: string, env: string, err?: any }> = [];
    public phoneEnvOptions: Array<{ value: PhoneEnvironment, name: string }>;

    public readonly PhoneEnvironment = PhoneEnvironment;

    constructor(
        private activatedRoute: ActivatedRoute,
        private store: Store<GlobalState>,
        private appUserSvc: ApplicationUserService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'] as string)),
            this.store.select(state => state.core.data.applications),
            this.store.select(state => state.core.data.distributedConfig),
        ]).pipe(
            map(([id, applications, cfg]) => [applications.find(application => application.id === id), cfg] as [Application, IDistributedConfig]),
            skipWhile(([app, _cfg]) => app == null),
        ).subscribe(([application, distributedConfig]) => {
            this.application = application;
            this.title = `Manage Users for ${this.application.fullName}`;
            this.logo = application.logo;
            this.phoneEnvOptions = [PhoneEnvironment.DEVELOPMENT, PhoneEnvironment.IAT, PhoneEnvironment.UAT, PhoneEnvironment.PRODUCTION]
                .filter(pe => distributedConfig.app.activeEnvironments.includes(pe))
                .map(pe => ({ value: pe, name: PHONE_ENV_LABELS[pe] }));
        });
    }

    test() {
        this.busy = true;
        this.appUserSvc.testApplicationUser(this.phoneEnv, this.application.bundleId, this.email).subscribe(success => {
            this.responses.push({ mode: 'test', success, email: this.email, env: this.phoneEnv });
            this.busy = false;
        }, err => {
            this.responses.push({ mode: 'test', success: false, err, email: this.email, env: this.phoneEnv });
            this.busy = false;
        });
    }

    delete() {
        if (!confirm(`Really delete user ${this.email} from ${this.application.bundleId} in ${this.phoneEnv.toUpperCase()}?`)) {
            return;
        }
        this.busy = true;
        this.appUserSvc.deleteApplicationUser(this.phoneEnv, this.application.bundleId, this.email).subscribe(success => {
            this.responses.push({ mode: 'delete', success, email: this.email, env: this.phoneEnv });
            this.busy = false;
        }, err => {
            this.responses.push({ mode: 'delete', success: false, err, email: this.email, env: this.phoneEnv });
            this.busy = false;
        });
    }

    close() {
        this.router.navigate(['/configuration/applications']);
    }

}
