<div class="tile">
    <wf-tile-header [icon]="'collection-item'" [label]="'Units'"></wf-tile-header>
    <wf-units-list [applications]="store | select:'core.data.applications' | animateList | async"
        [selectedApplication]="store | select:'core.data.selectedApplication' | async"
        [units]="store | select:'core.data.units' | async" [clients]="store | select:'core.data.clients' | async"
        [selectedUnit]="store | select:'core.data.selectedUnit' | async" (selectApplication)="selectApplication($event)"
        (selectUnit)="selectUnit($event)" (addUnit)="addUnit()" (editUnit)="editUnit($event)"
        (manageUsers)="manageUsers($event)" (deleteUnit)="deleteUnit($event)"></wf-units-list>

    <wf-spinner *ngIf="store | select: 'core.data.isUnitDeletion' | async"></wf-spinner>
</div>
