import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { ToggleSideMenu } from '../../store/core.actions';
import { AuthService } from '../../services/auth.service';
import { take, filter } from 'rxjs/operators';
import { User } from '@wildflowerhealth/console-shared';
import { EnvironmentService } from '../../services/environment.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { IDropdownItem } from '../../../ui-components/dropdown/dropdown.component';

@Component({
    selector: 'wf-app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent implements OnInit {

    activeUser: Observable<User>;
    dropDownItems = List<IDropdownItem>([
        {
            icon: 'account-o',
            label: 'Profile',
            callback: () => {
                this.router.navigate(['profile']);
            }
        },
        {
            icon: 'power-off',
            label: 'Sign Out',
            callback: () => {
                this.authService.signOut();
            }
        },
        {
            icon: 'alert-circle-o',
            label: 'Reload and Reset',
            isSeparated: true,
            callback: () => {
                this.authService.refresh();
            }
        },
    ]);
    banner = { enabled: false, label: null, style: null };
    version: string;
    serverVersion$: Observable<string>;

    constructor(
        private store: Store<GlobalState>,
        private authService: AuthService,
        private env: EnvironmentService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.activeUser = this.store.select(s => s.core.data.activeUser);
        this.banner = {
            enabled: !this.env.isProduction(),
            label: this.env.bannerLabel(),
            style: this.env.bannerStyle(),
        };
        this.version = environment.version;
        this.serverVersion$ = this.store.select(s => s.core.data.serverVersion);
    }

    toggleNavigationMenu() {
        this.store.select(s => s.core.data.isSideMenuOpened).pipe(
            take(1),
            filter(isSideMenuOpened => !isSideMenuOpened),
        ).subscribe(() => this.store.next(new ToggleSideMenu()));
    }

    goHome() {
        this.router.navigate(['/home']);
    }
}
