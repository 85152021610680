<div class="fixed-container" [ngClass]="{ hidden: !isUploaderShown }"
     (click)="closeUploader($event)">
    <div class="tile">
        <button class="btn btn-flat" (click)="closeUploader($event)"><i class="zmdi zmdi-close"></i></button>
        <div class="image-cropper">
                <span class="upload-button">
                    <button type="button" class="btn btn-warning"
                            (click)="fileInput.click();$event.preventDefault();$event.stopPropagation()">Select Image to
                        Upload
                    </button>
                    <input #fileInput id="custom-input" type="file" (change)="fileChangeListener($event)"
                           (click)="$event.stopPropagation()">
                </span>

            <img-cropper #cropper [image]="imageData" [settings]="cropperSettings"
                         (click)="$event.stopPropagation();"></img-cropper>

            <i class="zmdi zmdi-long-arrow-down"></i>

            <img *ngIf="imageData.image" [src]="imageData.image" [width]="cropperSettings.croppedWidth"
                 [height]="cropperSettings.croppedHeight" [ngClass]="{'round': isRound }">
            <div class="img-stub" *ngIf="!imageData.image" [style.width]="cropperSettings.croppedWidth + 'px'"
                 [style.height]="cropperSettings.croppedHeight + 'px'" [ngClass]="{'round': isRound }"></div>

            <button type="button" class="btn btn-primary" [disabled]="!imageData.image" (click)="select()">DONE</button>
        </div>
    </div>
</div>
