<h3>Change Password:</h3>
<div class="user-details tile">
    <form [formGroup]="form" (ngSubmit)="submit(form)">
        <wf-input label="Old Password"
                  type="password"
                  formControlName="oldPassword"></wf-input>
        <wf-input label="New Password"
                  type="password"
                  formControlName="newPassword"></wf-input>
        <wf-input label="Confirm Password"
                  type="password"
                  formControlName="confirmPassword"></wf-input>
        <div class="controls">
            <button type="submit"
                    class="btn btn-primary-v2">Save</button>
        </div>
    </form>
</div>

<wf-spinner [hidden]="!isPasswordChangingInProgress" [label]="'Changing in progress...'"></wf-spinner>
