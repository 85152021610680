import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { AssignUsersToUnit } from '../../store/core.actions';
import { first, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Unit, Client, User } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-unit-user-assignment-page',
    templateUrl: './unit-user-assignment-page.component.html',
    styleUrls: ['./unit-user-assignment-page.component.scss']
})
export class UnitUserAssignmentPageComponent implements OnInit {

    private unit: Unit;
    private client: Client;
    private relatedUsers: List<User>;
    public form: FormGroup;

    constructor(private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private store: Store<GlobalState>,
        private router: Router) {
    }

    static getUnit(state: GlobalState, id): Unit {
        return state.core.data.units.find(unit => unit.id === id) || null;
    }

    static getClient(state: GlobalState, unit: Unit): Client {
        return unit
            ? state.core.data.clients.find(client => client.id === unit.clientId)
            : null;
    }

    static getRelatedUsers(state: GlobalState, client: Client): List<User> {
        return !client || client.isSuper
            ? List([])
            : state.core.data.users
                .filter(user => user.clientId === client.id)
                .toList();
    }

    static buildFromForUsers(formBuilder: FormBuilder, users: List<User>, unit: Unit): FormGroup {
        return formBuilder.group(
            users
                .reduce(
                    (reduction, user) => Object.assign(
                        reduction,
                        { [user.id]: unit.userIds.indexOf(user.id) !== -1 }
                    ),
                    {})
        );
    }

    ngOnInit() {
        combineLatest([
            this.store.pipe(first(state => !state.core.data.isUserUnitAssignment)),
            this.activatedRoute.params.pipe(map(params => params['id'])),
        ]).subscribe((data: [GlobalState, string]) => {
            const [state, id] = data;

            this.unit = UnitUserAssignmentPageComponent.getUnit(state, id);
            this.client = UnitUserAssignmentPageComponent.getClient(state, this.unit);
            this.relatedUsers = UnitUserAssignmentPageComponent.getRelatedUsers(state, this.client);
            this.form = UnitUserAssignmentPageComponent.buildFromForUsers(this.formBuilder, this.relatedUsers, this.unit);
        });
    }

    save() {
        const userIds: List<string> = List(Object
            .keys(this.form.value)
            .filter((key: string) => this.form.value[key]));


        this.store.dispatch(new AssignUsersToUnit({ unit: this.unit, userIds }));
    }

    close() {
        this.router.navigate(['/configuration/units']);
    }

}
