/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notification.component";
import * as i4 from "@ngrx/store";
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
export function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "notification"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "show": 0, "success": 1, "fail": 2, "warning": 3 }), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "zmdi zmdi-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "notification"; var currVal_1 = _ck(_v, 3, 0, (_co.notification !== null), (_co.notification && _co.notification.isSuccess()), (_co.notification && _co.notification.isFail()), (_co.notification && _co.notification.isWarning())); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.notification ? _co.notification.message : ""); _ck(_v, 4, 0, currVal_2); }); }
export function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationComponentNgFactory = i1.ɵccf("wf-notification", i3.NotificationComponent, View_NotificationComponent_Host_0, {}, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
