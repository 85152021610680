import { Component, EventEmitter, HostListener, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'wf-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [DefaultValueAccessor]
})
export class InputComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() type = 'text';
    @Input() textStub: string;
    @Input() wfDisabled = false;
    @Input() alwaysActivated = false;
    @Input() displayErrors = false;
    @Output() focus: EventEmitter<void> = new EventEmitter<void>();
    @Output() blur: EventEmitter<void> = new EventEmitter<void>();

    value: string;

    private onChangeCallback: any;
    private onTouchCallback: any;
    private parentNgControl: NgControl;

    constructor(@Optional() ngControl: NgControl) {
        ngControl.valueAccessor = this;
        this.parentNgControl = ngControl;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchCallback = fn;
    }

    update(changes): void {
        this.value = changes;

        (this.onChangeCallback || (() => {
        }))(changes);
    }

    touch() {
        if (this.onTouchCallback) {
            this.onTouchCallback();
        }
        this.blur.next();
    }

    get error(): string {
        return Object
            .keys(this.parentNgControl.errors || {})
            .reverse()
            .reduce((prev, current) => current, '');
    }

}
