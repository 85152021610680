/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-initials.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-initials.component";
var styles_UserInitialsComponent = [i0.styles];
var RenderType_UserInitialsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInitialsComponent, data: {} });
export { RenderType_UserInitialsComponent as RenderType_UserInitialsComponent };
export function View_UserInitialsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "user-initials"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.user.firstName == null) ? null : _co.user.firstName.charAt(0)) + ((_co.user.lastName == null) ? null : _co.user.lastName.charAt(0))); _ck(_v, 1, 0, currVal_0); }); }
export function View_UserInitialsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-user-initials", [], null, null, null, View_UserInitialsComponent_0, RenderType_UserInitialsComponent)), i1.ɵdid(1, 114688, null, 0, i2.UserInitialsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserInitialsComponentNgFactory = i1.ɵccf("wf-user-initials", i2.UserInitialsComponent, View_UserInitialsComponent_Host_0, { user: "user" }, {}, []);
export { UserInitialsComponentNgFactory as UserInitialsComponentNgFactory };
