import { List, Map, Set } from 'immutable';
import { BaseModel } from '@wildflowerhealth/console-shared';

export class DataContainer<T extends BaseModel<any>> {

    protected list: List<T>;
    protected map: Map<string, T>;

    constructor(items: List<T> = List()) {
        this.list = items;
        this.map = Map<string, T>().asMutable();

        this.list.forEach((item: T) => {
            if (this.map.has(item.id)) {
                throw Error(`Primary Key Vialation. ID=${item.id} duplicated`);
            }

            this.map.set(item.id, item);
        });

        this.map = this.map.asImmutable();
    }

    /**
     * Returns size of container
     */
    get size() {
        return this.list.size;
    }

    /**
     * DataConainer support iteration
     */
    [Symbol.iterator]() {
        let index = 0;
        const items = List(this.list);

        return {
            next: () => {
                if (index < items.size) {
                    return {
                        value: items.get(index++),
                        done: false
                    };
                }

                return { done: true };
            }
        };
    }

    /**
     * Returns container as Immutable.List<T>
     */
    getList(): List<T> {
        return this.list;
    }

    /**
     * Check is key (ID) in the container already O(1)
     * @param keyValue
     */
    has(keyValue: string): boolean {
        return this.map.has(keyValue);
    }

    /**
     * Returns first element in the container O(1)
     */
    first(): T {
        return this.list.first();
    }

    /**
     * Returns element by index from container O(1)
     */
    getByIndex(index: number): T {
        return this.list.get(index);
    }

    /**
     * Returns element by key (ID) from container O(1)
     * @param key
     */
    getByKey(key: string): T {
        return this.map.get(key) || null;
    }

    /**
     * Immutabe operation. Returns new DataContainer<T>
     * If element is not present in container works in O(1) time
     * If element with ID already exists takes up to O(n) time
     */
    addOrReplace(item: T): DataContainer<T> {
        const list = this.map.has(item.id)
            ? this.list
                .filter(element => element.id !== item.id)
                .toList()
                .push(item)
            : this.list
                .push(item);

        const map = this.map.set(item.id, item);

        return this.clone(list, map);
    }

    /**
     * Immutable operation.
     * Removes element from container. O(n)
     * @param key
     */
    removeByKey(key: string): DataContainer<T> {
        if (!this.has(key)) {
            return this.clone(List(this.list), Map(this.map));
        }

        const list = this.list
            .filter(element => element.id !== key)
            .toList();

        const map = this.map.delete(key);

        return this.clone(list, map);
    }

    /**
     * Immutable operation.
     * Removes element from container. O(n)
     * @param index
     */
    removeByIndex(index: number): DataContainer<T> {
        if (index < 0 || index > this.size) {
            return this.clone(List(this.list), Map(this.map));
        }

        const element = this.list.get(index);
        const list = this.list.delete(index);
        const map = this.map.delete(element.id);

        return this.clone(list, map);
    }

    /**
     * Immutable operation.
     * Sorts content of container. Uses either provided comparator
     * or compare method of BaseModel. Complexity: O(nlog(n))
     * @param comparator
     */
    sort(comparator?: (a, b) => number): DataContainer<T> {
        const defaultComparator = (a, b) => a.compare(b);

        const list = this.list
            .sort(comparator || defaultComparator).toList();

        return new DataContainer<T>(list);
    }

    /**
     * Immutable operation.
     * Substracts elements from current container.
     * Complexity: O(n)
     * @param items
     */
    substractSet(items: List<T> | Set<T> | DataContainer<T>): DataContainer<T> {
        const set = List<T>(items).map(item => item.id).toSet();
        const map = this.map.asMutable();
        const list = this.list
            .filterNot(item => {
                if (set.has(item.id)) {
                    map.delete(item.id);
                }

                return set.has(item.id);
            })
            .toList();

        return this.clone(list, map.asImmutable());
    }

    /**
     * Immutable operation.
     * Returns itersection of provided set of items and container items.
     * Complexity: O(n)
     */
    intersect(items: List<any> | Set<any>, key = 'id', containerKey = 'id'): DataContainer<T> {
        if (!items || items.size === 0) {
            return this.clone(List(this.list), Map(this.map));
        }

        const anyElement: any = List<any>(items).first();
        let set: Set<string>;

        if (typeof anyElement === 'string') {
            set = Set(items);
        } else {
            set = List(items).map(item => item[key]).toSet();
        }

        const map = Map<string, T>().asMutable();
        const list = this.list
            .filter(item => {
                if (set.has(item[containerKey])) {
                    map.set(item.id, item);
                }

                return set.has(item[containerKey]);
            })
            .toList();

        return this.clone(list, map.asImmutable());
    }

    /**
     * Immutable operation.
     * Filters content of container base filter function output.
     * Complexity: O(n)
     *
    */
    filter(fn: (value?: T, key?: number) => boolean): DataContainer<T> {
        const map = this.map.asMutable();
        const list = this.list
            .filter((item, index) => {
                const res = fn(item, index);
                if (!res) {
                    map.delete(item.id);
                }
                return res;
            })
            .toList();

        return this.clone(list, map.asImmutable());
    }

    /**
     * Returns set of specific keys from the container
     * Complexity: O(n)
     */
    toKeysSet(key = 'id'): Set<any> {
        return this.list.map(item => item[key]).toSet();
    }

    /**
     * Find index of element that matches comparison function.
     * Complexity: O(n)
     */
    findIndex(fn: (value?: T, index?: number) => boolean): number {
        return this.list.findIndex(fn);
    }

    /**
     * Immutable operation.
     * Swap elements in the container
     */
    swapItems(index1: number, index2: number): DataContainer<T> {
        const item = this.list.get(index1);
        const list = this.list
            .set(index1, this.list.get(index2))
            .set(index2, item);

        return this.clone(list, Map(this.map));
    }

    private clone(list: List<T>, hashMap: Map<string, T>): DataContainer<T> {
        const clonned = new DataContainer<T>();

        clonned['list'] = list;
        clonned['map'] = hashMap;

        return clonned;
    }

}
