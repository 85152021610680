import { List } from 'immutable';
import { MenuSection } from '../constants/MenuSection';
import { User, Client, Unit, Application, Hint, Modal, Notification, Role, IUserAuthorizations, IDistributedConfig } from '@wildflowerhealth/console-shared';
import * as uuid from 'uuid';

export interface AuthorizationState {
    isAuthorized: boolean;
    isAuthorizationFetching: boolean;
    redirectUrl: string;
}

export const initialAuthorizationState: AuthorizationState = {
    isAuthorized: false,
    isAuthorizationFetching: false,
    redirectUrl: null
};

export interface DataState {
    isInitialized: boolean;
    isInitializationFetching: boolean;
    activeUser: User;
    activeUserAuthorizations: IUserAuthorizations;
    clients: List<Client>;
    users: List<User>;
    roles: List<Role>;
    units: List<Unit>;
    applications: List<Application>;
    menu: List<MenuSection>;
    isSideMenuOpened: boolean;
    notification: Notification;
    hint: Hint;
    modal: Modal;
    distributedConfig: IDistributedConfig;

    selectedClient: Client;
    clientToSave: Client;
    isClientSaving: boolean;
    isClientDeletion: boolean;

    selectedApplication: Application;
    applicationToSave: Application;
    isApplicationSaving: boolean;
    isApplicationDeletion: boolean;

    selectedUnit: Unit;
    unitToSave: Unit;
    isUnitSaving: boolean;
    isUnitDeletion: boolean;
    isUnitsLoading: boolean;

    userIdsToAssign: List<string>;
    isUserUnitAssignment: false;

    userToSave: User;
    isUserSaving: boolean;
    isUserDeletion: boolean;
    isActivation: boolean;

    isAvatarChangingInProgress: boolean;
    isPasswordChangingInProgress: boolean;

    selectedRole: Role;
    roleToSave: Role;
    isRolesLoading: boolean;
    isRoleSaving: boolean;
    isRoleDeletion: boolean;

    sessionId: string;
    serverVersion: string;
}

export const initialDataState: DataState = {
    isInitialized: false,
    isInitializationFetching: false,
    activeUser: new User(),
    activeUserAuthorizations: {},
    clients: List([]),
    users: List([]),
    roles: List([]),
    units: List([]),
    applications: List([]),
    menu: null,
    isSideMenuOpened: false,
    notification: null,
    hint: null,
    modal: null,
    distributedConfig: { app: { activeEnvironments: [] } },

    selectedClient: null,
    clientToSave: null,
    isClientSaving: false,
    isClientDeletion: false,

    selectedApplication: null,
    applicationToSave: null,
    isApplicationSaving: false,
    isApplicationDeletion: false,

    selectedUnit: null,
    unitToSave: null,
    isUnitSaving: false,
    isUnitDeletion: false,
    isUnitsLoading: false,

    userIdsToAssign: List([]),
    isUserUnitAssignment: false,

    userToSave: null,
    isUserSaving: false,
    isUserDeletion: false,
    isActivation: false,

    isAvatarChangingInProgress: false,
    isPasswordChangingInProgress: false,

    selectedRole: null,
    roleToSave: null,
    isRolesLoading: false,
    isRoleSaving: false,
    isRoleDeletion: false,

    sessionId: uuid.v4(),
    serverVersion: 'unknown',
};


export interface CoreState {
    authorizationState: AuthorizationState;
    data: DataState;
}
