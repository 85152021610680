// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { version } from '../../package.json';

export const API_CONFIG = {
    API: '/console',
    SOCKET_URL: '',
    ELIGIBILITY_ENDPOINT_PREFIX: 'https://api-staging.wildflowerhealth.net/',
    ELIGIBILITY_ENDPOINT_POSTFIX: '/v1/eligibility-by-unit',
    MEDIA: '/console/media',
};

export const CMS_IMPORT_API_CONFIG = {
    API: '/api/cms-import',
};

export const BACKGOUND_URI = 'background-image.jpg';
export const TEXTURE_URI = 'background.jpg';

export const environment = {
    version,
    production: false,
    envName: 'staging',
    bannerLabel: 'TESTING',
    bannerStyle: {
        'font-size': '2em',
        'font-weight': 'bolder',
        'color': '#ffff00',
    },
};
