import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NgControl } from '@angular/forms';
import { AuthorizationAction } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-permission-selector',
    templateUrl: './permission-selector.component.html',
    styleUrls: ['./permission-selector.component.scss'],
    providers: [DefaultValueAccessor],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionSelectorComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() isEdit = true;

    private value = AuthorizationAction.None;
    private onChangeCallback = _changes => { };
    private onTouchCallback = () => { };

    constructor(@Optional() ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchCallback = fn;
    }

    update(changes: AuthorizationAction): void {
        this.value = changes;
        this.onTouchCallback();
        this.onChangeCallback(changes);
    }

    selectNone(): void {
        if (this.isEdit) {
            this.update(AuthorizationAction.None);
        }
    }

    selectViewOnly(): void {
        if (this.isEdit) {
            this.update(AuthorizationAction.Read);
        }
    }

    selectEdit(): void {
        if (this.isEdit) {
            this.update(AuthorizationAction.ReadWrite);
        }
    }

    isNoneSelected(): boolean {
        return this.value === AuthorizationAction.None;
    }

    isViewSelected(): boolean {
        return this.value === AuthorizationAction.Read;
    }

    isEditSelected(): boolean {
        return this.value === AuthorizationAction.ReadWrite;
    }
}
