<div class="app-container" [ngClass]="elementClass">
    <wf-app-header *ngIf="store|select: 'core.data.isInitialized'|async"></wf-app-header>
    <wf-side-menu *ngIf="store|select:'core.data.isInitialized'|async"
        [ngClass]="{ 'side-menu-opened': store| select: 'core.data.isSideMenuOpened' | async }"></wf-side-menu>
    <div class="router" [ngClass]="{'notification-shown' : store|select: 'core.data.notification'|async}">
        <router-outlet></router-outlet>
        <wf-hint [ngClass]="{'show': store | select:'core.data.hint' | async}"
            [style.width]="(store | select:'core.data.hint' | async) ? (store | select:'core.data.hint' | async).size+'em' : '0em'">
        </wf-hint>
    </div>
    <wf-notification></wf-notification>
</div>
<div class="initialization-container" *ngIf="store| select: 'core.data.isInitializationFetching' | async">
    <wf-spinner [label]="'Initializing Application...'"></wf-spinner>
</div>
<wf-modal [modal]="store | select:'core.data.modal' | async"></wf-modal>
