import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { ToggleSideMenu } from '../../store/core.actions';
import { Subscription, fromEvent } from 'rxjs';
import '../../../global/helpers/rxjsExtension';
import { whenTrue } from '../../../global/helpers/rxjsExtension';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'wf-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements OnInit {

    documentSubscription: Subscription;

    constructor(public store: Store<GlobalState>) {
    }

    ngOnInit() {
        this.store
            .select(store => store.core.data.isSideMenuOpened)
            .pipe(whenTrue())
            .subscribe(() => {
                this.documentSubscription = fromEvent(document, 'click')
                    .pipe(skip(1))
                    .subscribe(() => {
                        this.store.dispatch(new ToggleSideMenu());
                        this.documentSubscription.unsubscribe();
                    });
            });
    }
}
