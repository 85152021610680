import { Component, Input } from '@angular/core';

@Component({
    selector: 'wf-input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent {
    @Input() error: string;

    readonly errorMessage = {
        required: 'This field is required',
        invalidEmail: 'Should be a valid email address',
        empty: 'Should not be empty',
        notEqual: 'Should be the same as password',
        tooShort: 'Length should be at least 8 characters',
        noDigit: 'Should contain at least one digit',
        noLetter: 'Should contain at least one letter',
        platformBuildFormat: 'Platform build should be formatted as XXX.YYY.ZZZ',
        nonCapitals: 'Should contain only CAPITAL letters',
        startsFromCapital: 'Should start with a Capital letter',
        noSpaces: 'Should not contain spaces',
        numbersOnly: 'Should contain positive numbers only',
        phoneNumber: 'Should be a valid phone number or replacement token, eg. @@HOSP_PHONE@@',
        pattern: 'Please provide a valid value',
        appDnsName: 'Value should be a valid DNS name',
        hostName: 'Value should be a valid hostname',
        unique: 'This name is already taken',
    };
}
