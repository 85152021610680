<div class="stub" *ngIf="users && users.size === 0">
    <span>There are no users for selected client</span>
</div>

<div class="users-list" *ngIf="users && users.size !== 0">
    <div class="user horizontal-group" *ngFor="let user of users" (click)="select.next(user)" [@flyIn]="'in'"
        [ngClass]="{ 'not-active': !user.active }">
        <wf-image-icon [image]="user.avatar" icon="account" [size]="4" wfRound></wf-image-icon>
        <div class="info">
            <div class="name">{{user.getFullName()}}</div>
            <div class="email">{{user.email}}</div>
        </div>
        <div class="not-active" *ngIf="!user.active">
            NOT ACTIVE
        </div>
    </div>
</div>
