/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../image-uploader/image-uploader.component.ngfactory";
import * as i4 from "../image-uploader/image-uploader.component";
import * as i5 from "@angular/forms";
import * as i6 from "./image-input.component";
var styles_ImageInputComponent = [i0.styles];
var RenderType_ImageInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageInputComponent, data: {} });
export { RenderType_ImageInputComponent as RenderType_ImageInputComponent };
function View_ImageInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [], [[8, "src", 4], [4, "font-size", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "round": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.isRound); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; var currVal_1 = (_co.size + "em"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "stub"]], [[4, "font-size", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "round": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload Image"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "stub"; var currVal_2 = _ck(_v, 3, 0, _co.isRound); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size + "em"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "image-input"]], [[4, "width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUploader($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageInputComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageInputComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-warning"]], [[4, "font-size", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.preventDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Change"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "wf-image-uploader", [], null, [[null, "imageChanged"], [null, "uploaderClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageChanged" === en)) {
        var pd_0 = (_co.handleImageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("uploaderClosed" === en)) {
        var pd_1 = ((_co.isUploaderShown = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_ImageUploaderComponent_0, i3.RenderType_ImageUploaderComponent)), i1.ɵdid(9, 114688, null, 0, i4.ImageUploaderComponent, [], { image: [0, "image"], isUploaderShown: [1, "isUploaderShown"], isRound: [2, "isRound"] }, { imageChanged: "imageChanged", uploaderClosed: "uploaderClosed" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.image; _ck(_v, 2, 0, currVal_1); var currVal_2 = (!_co.image && _co.displayImagePlaceholder); _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.image; var currVal_5 = _co.isUploaderShown; var currVal_6 = _co.isRound; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size + "em"); _ck(_v, 0, 0, currVal_0); var currVal_3 = ((_co.size / 10) + "em"); _ck(_v, 6, 0, currVal_3); }); }
export function View_ImageInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "wf-image-input", [], null, null, null, View_ImageInputComponent_0, RenderType_ImageInputComponent)), i1.ɵprd(4608, null, i5.DefaultValueAccessor, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]]), i1.ɵdid(2, 114688, null, 0, i6.ImageInputComponent, [[2, i5.NgControl]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ImageInputComponentNgFactory = i1.ɵccf("wf-image-input", i6.ImageInputComponent, View_ImageInputComponent_Host_0, { size: "size", isRound: "isRound", displayImagePlaceholder: "displayImagePlaceholder" }, {}, []);
export { ImageInputComponentNgFactory as ImageInputComponentNgFactory };
