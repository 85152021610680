import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalState } from '../../global/global.state';
import { Store } from '@ngrx/store';
import { StoreRedirectUrl } from '../store/core.actions';
import { AuthService } from './auth.service';
import { HTTP_FORBIDDEN } from '@wildflowerhealth/console-shared';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private store: Store<GlobalState>, private router: Router, private authService: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isTokenExpired()) {
            this.store.dispatch(new StoreRedirectUrl({ redirectUrl: state.url }));
            this.authService.signOut();
            return false;
        }
        if (!this.authService.meetsRequirements(route.data?.requirements)) {
            console.log(`active user does not meet requirements`, route.data?.requirements);
            this.router.navigate([`/error/${HTTP_FORBIDDEN}`]);
            return false;
        }
        return true;
    }
}
