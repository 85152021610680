<div class="input-group" (click)="update($event)">
    <input #wfInput [ngClass]="{
                'ng-touched': parentNgControl.touched,
                'ng-untouched': !parentNgControl.touched,
                'ng-valid': parentNgControl.valid,
                'ng-invalid': !parentNgControl.valid,
                'ng-pristine': parentNgControl.pristine,
                'ng-dirty': !parentNgControl.pristine
            }" type="checkbox" [(ngModel)]="displayedValue" [disabled]="wfDisabled" (blur)="onTouchCallback()">
    <label>{{label}}</label>
    <div *ngIf="displayRequiredMessage" class="errors">
        <div *ngIf="error === 'required'">
            This field is required
        </div>
    </div>
</div>
