import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../global/global.state';
import { Router } from '@angular/router';
import { SelectClient } from '../../store/core.actions';
import { DataState } from '../../store/core.states';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, Client, Authorization, AuthorizationAction, AuthorizationResource } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-users-list-page',
    templateUrl: './users-list-page.component.html',
    styleUrls: ['./users-list-page.component.scss']
})
export class UsersListPageComponent implements OnInit {

    public users: Observable<List<User>>;
    public isEditMode: Observable<boolean>;

    constructor(public store: Store<GlobalState>, private router: Router) {
    }

    ngOnInit() {
        this.isEditMode = this.store
            .select(state => state.core.data.activeUserAuthorizations)
            .pipe(map(auths => Authorization.isAuthorized(auths, AuthorizationAction.ReadWrite, AuthorizationResource.ConsoleUsers)));

        this.users = this.store
            .select(store => store.core.data)
            .pipe(map((data: DataState) => data.selectedClient
                ? data.users
                    .filter(user => user.clientId === data.selectedClient.id)
                    .toList()
                : List([])
            ));
    }

    create() {
        this.router.navigate(['/configuration/users/form']);
    }

    selectUser(user: User) {
        this.router.navigate(['/configuration/users/details', { id: user.id }]);
    }

    selectClient(selectedClient: Client) {
        this.store.dispatch(new SelectClient({ selectedClient }));
    }

}
