import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHelper } from '../../global/helpers/HttpHelper';
import { EnvironmentService } from './environment.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalState } from '../../global/global.state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ResetAuthorizationState, ResetDataState, ISignInResult } from '../store/core.actions';
import { LocalStorageService } from './local-storage.service';
import { WindowRefService } from './window-ref.service';
import { Authorization, AuthorizationResource, AuthorizationAction, IAuthorizationRequirement } from '@wildflowerhealth/console-shared';

@Injectable()
export class AuthService {
    API = this.environmentService.getAPIUrl();

    constructor(
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
        private environmentService: EnvironmentService,
        private store: Store<GlobalState>,
        private localStorageService: LocalStorageService,
        private router: Router,
        private win: WindowRefService,
    ) {
    }

    singIn(email: string, password: string): Observable<ISignInResult> {
        const body = JSON.stringify({ email: email.toLocaleLowerCase(), password });

        return this.http.post<ISignInResult>(this.API + '/sign-in', body, HttpHelper.getOptions());
    }

    activate(password: string, token?: string, userId?: string) {
        const body = JSON.stringify({ token, userId, password });

        return userId == null
            ? this.http.post(this.API + '/activate', body, HttpHelper.getOptions())
            : this.http.post(`${this.API}/users/${userId}/activate`, body, HttpHelper.getOptions());
    }

    isTokenExpired() {
        try {
            return this.jwtHelper.isTokenExpired(this.getToken());
        } catch (err) {
            return true;
        }
    }

    isAuthorized(action: AuthorizationAction, ...resources: AuthorizationResource[]) {
        return Authorization.isAuthorized(this.getAuths(), action, ...resources);
    }

    meetsRequirements(requirements?: IAuthorizationRequirement | IAuthorizationRequirement[]) {
        return Authorization.meetsRequirements(this.getAuths(), requirements);
    }

    signOut() {
        this.reset();
        return this.router.navigate(['/login']);
    }

    reset() {
        this.store.dispatch(new ResetAuthorizationState());
        this.store.dispatch(new ResetDataState());
        this.localStorageService.clearAuth();
    }

    refresh() {
        this.signOut().then(() => {
            this.win.reload(true);
        });
    }

    getToken() {
        return this.localStorageService.getToken();
    }

    getAuths() {
        return this.localStorageService.getAuthorizations();
    }
}

export function jwtOptionsFactory(authSvc: AuthService) {
    return {
        tokenGetter: () => authSvc.getToken(),
    };
}
