<div class="tile">
    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
        <wf-tile-header [icon]="'account-add'" [label]="title" [showCloseButton]="true" (close)="close()">
        </wf-tile-header>

        <div class="horizontal-group">
            <div class="main-inputs">
                <wf-image-icon [image]="user ? user.avatar : null" icon="account" [size]="6" wfRound></wf-image-icon>
                <wf-select label="Client" [options]="clientOptions" formControlName="clientId"></wf-select>
                <wf-input label="First Name" formControlName="firstName"></wf-input>
                <wf-input label="Last Name" formControlName="lastName"></wf-input>
                <wf-input label="Email" formControlName="email"></wf-input>
            </div>

            <div class="vertical-divider"></div>

            <div class="secion-list">
                <section>
                    <header>Roles</header>
                    <div class="selectors" formArrayName="roles">
                        <wf-checkbox
                            *ngFor="let role of roles; let i = index"
                            [label]="role.name"
                            [formControlName]="i"></wf-checkbox>
                    </div>
                </section>
            </div>
        </div>
        <div class="controls">
            <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Save</button>
        </div>
    </form>
</div>
<wf-spinner *ngIf="store | select: 'core.data.isUserSaving' | async"></wf-spinner>
