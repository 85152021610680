import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { slideFromRightListAnimation } from '../../core.animations';
import { List } from 'immutable';
import { Application } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-applications-list',
    templateUrl: './applications-list.component.html',
    styleUrls: ['./applications-list.component.scss'],
    animations: slideFromRightListAnimation,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationsListComponent {

    @Input() applications: List<Application>;
    @Input() selectedApplication: Application;
    @Input() viewSelectMode = false;
    @Output() select: EventEmitter<Application> = new EventEmitter<Application>();
    @Output() edit: EventEmitter<Application> = new EventEmitter<Application>();
    @Output() users: EventEmitter<Application> = new EventEmitter<Application>();
    @Output() delete: EventEmitter<Application> = new EventEmitter<Application>();


    isApplicationSelected(application: Application): boolean {
        return this.selectedApplication && this.selectedApplication.id === application.id;
    }

    editClick(application: Application): void {
        this.selectedApplication && this.selectedApplication.id === application.id
            ? this.edit.next(application)
            : this.select.next(application);
    }

    usersClick(application: Application): void {
        this.selectedApplication && this.selectedApplication.id === application.id
            ? this.users.next(application)
            : this.select.next(application);
    }

    deleteClick(application: Application): void {
        this.selectedApplication && this.selectedApplication.id === application.id
            ? this.delete.next(application)
            : this.select.next(application);
    }
}
