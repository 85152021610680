<div class="login-container tile">
    <div class="logo"></div>
    <form [formGroup]="form" (ngSubmit)="signIn()">
        <wf-input [label]="'Email'" formControlName="email"></wf-input>
        <wf-input [label]="'Password'" [type]="'password'" formControlName="password"></wf-input>
        <div class="controls">
            <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Sign In</button>
        </div>
    </form>
</div>
<wf-spinner *ngIf="store | select: 'core.authorizationState.isAuthorizationFetching' | async"></wf-spinner>
