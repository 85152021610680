import { MENU_CONFIGURATION } from '../constants/SideMenuConfiguration';
import { List } from 'immutable';
import { MenuSection } from '../constants/MenuSection';
import { IUserAuthorizations, Authorization } from '@wildflowerhealth/console-shared';

export class MenuHelper {
    static prepareMenu(auths: IUserAuthorizations): List<MenuSection> {
        return MENU_CONFIGURATION
            .map(menuSection => Object.assign(
                {},
                menuSection,
                {
                    menuItems: menuSection
                        .menuItems
                        .filter(menuItem => Authorization.meetsRequirements(auths, menuItem.authorizations))
                        .toList()
                })
            )
            .filter(menuSection => menuSection.menuItems.size > 0)
            .toList();
    }
}
