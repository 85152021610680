import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { SaveRole } from '../../store/core.actions';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Role, AuthorizationAction, AuthorizationResource, Authorization } from '@wildflowerhealth/console-shared';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
    selector: 'wf-role-form-page',
    templateUrl: './role-form-page.component.html',
    styleUrls: ['./role-form-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleFormPageComponent implements OnInit {
    public role: Role;
    public title: string;
    public form: FormGroup;

    public readonly isEdit = this.isAuthorizedWrite(AuthorizationResource.ConsoleRoles);

    public readonly AuthorizationAction = AuthorizationAction;
    public readonly AuthorizationResource = AuthorizationResource;

    constructor(private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private localStore: LocalStorageService,
        public store: Store<GlobalState>,
        private router: Router) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'] as string)),
            this.store.select(store => store.core.data.roles),
        ]).pipe(
            map(([id, roles]) => roles.find(role => role.id === id)),
        ).subscribe(role => {
            this.role = role || null;
            this.title = role ? 'Edit Role' : 'Add new Role';
            const formData = {
                name: [role ? role.name : '', Validators.required],
            };
            for (const resource of Authorization.AllResources) {
                const resourceName = AuthorizationResource[resource];
                if (role == null) {
                    formData[resourceName] = [AuthorizationAction.None];
                    continue;
                }
                formData[resourceName] = [role.authorizations.find(a => a.resource === resource)?.action ?? AuthorizationAction.None];
            }
            this.form = this.formBuilder.group(formData);
        });
    }

    save() {
        if (this.role == null) {
            this.role = new Role();
        }
        this.role.name = this.form.value.name;
        this.role.authorizations = Authorization.AllResources.map(r => {
            const name = AuthorizationResource[r];
            const value = this.form.value[name];
            if (value == null || value === AuthorizationAction.None) {
                return null;
            }
            return { action: value, resource: r };
        }).filter(a => a != null);
        this.store.dispatch(new SaveRole({ role: this.role }));
    }

    close() {
        this.router.navigate(['/configuration/roles']);
    }

    isAuthorized(action: AuthorizationAction, ...resources: AuthorizationResource[]) {
        return Authorization.isAuthorized(this.localStore.getAuthorizations(), action, ...resources);
    }

    isAuthorizedRead(...resources: AuthorizationResource[]) {
        return Authorization.isAuthorized(this.localStore.getAuthorizations(), AuthorizationAction.Read, ...resources);
    }

    isAuthorizedWrite(...resources: AuthorizationResource[]) {
        return Authorization.isAuthorized(this.localStore.getAuthorizations(), AuthorizationAction.ReadWrite, ...resources);
    }
}
