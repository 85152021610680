/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roles-list-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../ui-components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../ui-components/spinner/spinner.component";
import * as i4 from "../../pipes/animate-list.pipe";
import * as i5 from "../../pipes/select.pipe";
import * as i6 from "../../../ui-components/tile-header/tile-header.component.ngfactory";
import * as i7 from "../../../ui-components/tile-header/tile-header.component";
import * as i8 from "../roles-list/roles-list.component.ngfactory";
import * as i9 from "../roles-list/roles-list.component";
import * as i10 from "@angular/common";
import * as i11 from "./roles-list-page.component";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/router";
import * as i14 from "../../services/modal.service";
var styles_RolesListPageComponent = [i0.styles];
var RenderType_RolesListPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RolesListPageComponent, data: {} });
export { RenderType_RolesListPageComponent as RenderType_RolesListPageComponent };
function View_RolesListPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "controls controls-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-primary btn-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "zmdi zmdi-plus"]], null, null, null, null, null))], null, null); }
function View_RolesListPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RolesListPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.AnimateListPipe, []), i1.ɵpid(0, i5.SelectPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "wf-tile-header", [], null, null, null, i6.View_TileHeaderComponent_0, i6.RenderType_TileHeaderComponent)), i1.ɵdid(4, 49152, null, 0, i7.TileHeaderComponent, [], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "wf-roles-list", [], null, [[null, "select"], [null, "edit"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.select($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.edit($event) !== false);
        ad = (pd_1 && ad);
    } if (("delete" === en)) {
        var pd_2 = (_co.delete($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_RolesListComponent_0, i8.RenderType_RolesListComponent)), i1.ɵdid(6, 49152, null, 0, i9.RolesListComponent, [], { roles: [0, "roles"], selectedRole: [1, "selectedRole"] }, { select: "select", edit: "edit", delete: "delete" }), i1.ɵppd(7, 2), i1.ɵppd(8, 1), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(10, 2), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RolesListPageComponent_1)), i1.ɵdid(13, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_RolesListPageComponent_2)), i1.ɵdid(16, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵppd(17, 2), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Roles"; var currVal_1 = "city"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 9).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 1), _co.store, "core.data.roles")))))); var currVal_3 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 11).transform(i1.ɵunv(_v, 6, 1, _ck(_v, 10, 0, i1.ɵnov(_v, 1), _co.store, "core.data.selectedRole")))); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.isEditMode)); _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 18).transform(i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 1), _co.store, "core.data.isRoleDeletion")))); _ck(_v, 16, 0, currVal_5); }, null); }
export function View_RolesListPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-roles-list-page", [], null, null, null, View_RolesListPageComponent_0, RenderType_RolesListPageComponent)), i1.ɵdid(1, 114688, null, 0, i11.RolesListPageComponent, [i12.Store, i13.Router, i14.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RolesListPageComponentNgFactory = i1.ɵccf("wf-roles-list-page", i11.RolesListPageComponent, View_RolesListPageComponent_Host_0, {}, {}, []);
export { RolesListPageComponentNgFactory as RolesListPageComponentNgFactory };
