import { Injectable } from '@angular/core';

function _window(): Window {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRefService {
    public get nativeWindow() {
        return _window();
    }

    public reload(force = false) {
        // https://github.com/Microsoft/TypeScript/issues/28898
        // tslint:disable-next-line: deprecation
        _window().location.reload(force);
    }
}
