<div class="tile">
    <wf-tile-header [label]="title" [icon]="'accounts-alt'" [showCloseButton]="true" (close)="close()"></wf-tile-header>

    <div class="horizontal-group">
        <img *ngIf="logo" [src]="logo" style="width:100%;max-width:6em;display:block;">
        <div class="inputs">
            <wf-select
                [label]="'Application Environment'"
                [(ngModel)]="phoneEnv"
                [showDisabledFirstOption]="false"
                [options]="phoneEnvOptions"></wf-select>
            <wf-input [label]="'User Email'" [(ngModel)]="email"></wf-input>
        </div>
    </div>

    <div class="controls">
        <button type="button" class="btn btn-primary" [disabled]="busy || email === ''" (click)="test()">Test</button>
        <button type="button" class="btn" [disabled]="busy || email === ''" (click)="delete()">Delete</button>
    </div>

    <div>
        <div *ngFor="let response of responses">
            <span *ngIf="response.err != null">💣 {{ response.err.message }} </span>
            <span *ngIf="response.err == null">
                <span *ngIf="!response.success">🚫 unable to find</span>
                <span *ngIf="response.success">
                    <span *ngIf="response.mode === 'test'">✅ found</span>
                    <span *ngIf="response.mode === 'delete'">❌ deleted</span>
                </span>
                user {{ response.email }} for app {{ application.bundleId }} in environment {{ response.env.toUpperCase() }}
            </span>
        </div>
    </div>

    <wf-spinner *ngIf="busy"></wf-spinner>
</div>
