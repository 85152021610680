import { Observable } from 'rxjs';
import { List } from 'immutable';
import { take, skip, filter } from 'rxjs/operators';
import { DataContainer } from '../../data-containers/DataContainer';

export function takeSecond<T>() {
    return (src: Observable<T>): Observable<T> => src.pipe(skip(1), take(1));
}

export function whenTrue<T>() {
    return (src: Observable<T>): Observable<T> => src.pipe(filter(value => Boolean(value)));
}

export function whenFalse<T>() {
    return (src: Observable<T>): Observable<T> => src.pipe(filter(value => !Boolean(value)));
}

export function whenListIsNotEmpty<T>() {
    return (src: Observable<T>): Observable<T> => src.pipe(filter((value: T) => {
        if (!List.isList(value) && !(value instanceof DataContainer)) {
            throw Error(`Value ${value} should be List`);
        }

        return (value as any).size > 0;
    }));
}
