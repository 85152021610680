import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';

@Component({
    selector: 'wf-image-uploader',
    templateUrl: './image-uploader.component.html',
    styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
    @ViewChild('cropper', null) cropper: ImageCropperComponent;

    @Input() image;
    @Input() isUploaderShown = false;
    @Input() isRound = false;

    @Output() imageChanged = new EventEmitter();
    @Output() uploaderClosed = new EventEmitter();

    public imageData: any = {};
    public cropperSettings: CropperSettings;


    constructor() {
    }

    ngOnInit() {
        this.setCropperSetting();
    }

    setCropperSetting() {
        this.cropperSettings = new CropperSettings({
            width: 200,
            height: 200,
            noFileInput: true,
            croppedWidth: 200,
            croppedHeight: 200,
            canvasWidth: 400,
            canvasHeight: 400,
            cropperDrawSettings: {
                strokeWidth: 1,
                strokeColor: '#7f7f7f',
                dragIconStrokeWidth: 1,
                dragIconStrokeColor: 'transparent',
                dragIconFillColor: '#ffffff',
                lineDash: false,
            }
        });
    }

    closeUploader(event): void {
        event.stopPropagation();
        event.preventDefault();
        this.isUploaderShown = false;
        this.uploaderClosed.emit();
    }

    fileChangeListener($event) {
        const image: any = new Image();
        const myReader: FileReader = new FileReader();
        const self = this;

        myReader.onloadend = function (loadEvent: any) {
            image.src = loadEvent.target.result;
            self.cropper.setImage(image);
        };

        myReader.readAsDataURL($event.target.files[0]);
    }

    select(): void {
        this.image = this.imageData.image || this.image;
        this.isUploaderShown = false;
        this.imageChanged.emit(this.image);
    }

}
