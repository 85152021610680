import { Routes } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ClientsListPageComponent } from './components/clients-list-page/clients-list-page.component';
import { ClientFormPageComponent } from './components/client-form-page/client-form-page.component';
import { ApplicationsListPageComponent } from './components/applications-list-page/applications-list-page.component';
import { ApplicationFormPageComponent } from './components/application-form-page/application-form-page.component';
import { UnitsListPageComponent } from './components/units-list-page/units-list-page.component';
import { UnitFormPageComponent } from './components/unit-form-page/unit-form-page.component';
import { UnitUserAssignmentPageComponent } from './components/unit-user-assignment-page/unit-user-assignment-page.component';
import { UsersListPageComponent } from './components/users-list-page/users-list-page.component';
import { UserFormPageComponent } from './components/user-form-page/user-form-page.component';
import { UserDetailsPageComponent } from './components/user-details-page/user-details-page.component';
import { ActivatePageComponent } from './components/activate-page/activate-page.component';
import { ApplicationUsersPageComponent } from './components/application-users-page/application-users-page.component';
import { AuthorizationAction, AuthorizationResource } from '@wildflowerhealth/console-shared';
import { RolesListPageComponent } from './components/roles-list-page/roles-list-page.component';
import { RoleFormPageComponent } from './components/role-form-page/role-form-page.component';
import { LogoutPageComponent } from './components/logout-page/logout-page.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
const ɵ0 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.Clients } }, ɵ1 = { requirements: { action: AuthorizationAction.ReadWrite, resource: AuthorizationResource.Clients } }, ɵ2 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.Applications } }, ɵ3 = { requirements: { action: AuthorizationAction.ReadWrite, resource: AuthorizationResource.Applications } }, ɵ4 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.AppUsers } }, ɵ5 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.Applications } }, ɵ6 = { requirements: { action: AuthorizationAction.ReadWrite, resource: AuthorizationResource.Applications } }, ɵ7 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.Applications } }, ɵ8 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.ConsoleUsers } }, ɵ9 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.ConsoleUsers } }, ɵ10 = { requirements: { action: AuthorizationAction.ReadWrite, resource: AuthorizationResource.ConsoleUsers } }, ɵ11 = { requirements: { action: AuthorizationAction.Read, resource: AuthorizationResource.ConsoleRoles } }, ɵ12 = { requirements: { action: AuthorizationAction.ReadWrite, resource: AuthorizationResource.ConsoleRoles } };
const routes = [
    {
        path: 'login',
        component: LoginPageComponent,
    },
    {
        path: 'logout',
        component: LogoutPageComponent,
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'activation/:token',
        component: ActivatePageComponent
    },
    {
        path: 'configuration/clients',
        component: ClientsListPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ0,
    },
    {
        path: 'configuration/clients/form',
        component: ClientFormPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ1,
    },
    {
        path: 'configuration/applications',
        component: ApplicationsListPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ2,
    },
    {
        path: 'configuration/applications/form',
        component: ApplicationFormPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ3,
    },
    {
        path: 'configuration/applications/users',
        component: ApplicationUsersPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ4,
    },
    {
        path: 'configuration/units',
        component: UnitsListPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ5,
    },
    {
        path: 'configuration/units/form',
        component: UnitFormPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ6,
    },
    {
        path: 'configuration/units/user-assignment',
        component: UnitUserAssignmentPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ7,
    },
    {
        path: 'configuration/users',
        component: UsersListPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ8,
    },
    {
        path: 'configuration/users/details',
        component: UserDetailsPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ9,
    },
    {
        path: 'configuration/users/form',
        component: UserFormPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ10,
    },
    {
        path: 'configuration/roles',
        component: RolesListPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ11,
    },
    {
        path: 'configuration/roles/form',
        component: RoleFormPageComponent,
        canActivate: [AuthGuardService],
        data: ɵ12,
    },
    {
        path: 'profile',
        component: ProfilePageComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'error/:status',
        component: ErrorPageComponent,
    },
    { path: '**', component: ErrorPageComponent }
];
export class CoreRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
