import { List } from 'immutable';
import { IOption } from '@wildflowerhealth/console-shared';
import { Segment } from './Segment';


export const SegmentOptions: List<IOption> = List([
    { name: 'Commercial', value: String(Segment.COMMERCIAL) },
    { name: 'Employers', value: String(Segment.EMPLOYERS) },
    { name: 'Health Systems', value: String(Segment.HEALTH_SYSTEMS) },
    { name: 'Medicaid', value: String(Segment.MEDICAID) },
    { name: 'Partners', value: String(Segment.PARTNERS) },
]);
