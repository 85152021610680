/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../ui-components/tile-header/tile-header.component.ngfactory";
import * as i3 from "../../../ui-components/tile-header/tile-header.component";
import * as i4 from "../../../ui-components/highlight-on-hover.directive";
import * as i5 from "@angular/router";
import * as i6 from "./error-page.component";
import * as i7 from "../../services/auth.service";
var styles_ErrorPageComponent = [i0.styles];
var RenderType_ErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPageComponent, data: {} });
export { RenderType_ErrorPageComponent as RenderType_ErrorPageComponent };
export function View_ErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "wf-tile-header", [], null, null, null, i2.View_TileHeaderComponent_0, i2.RenderType_TileHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.TileHeaderComponent, [], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "link"], ["routerLink", "/home"], ["wfHighlightOnHover", ""]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.HighlightOnHoverDirective, [i1.ElementRef], null, null), i1.ɵdid(6, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Back to Home"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "link"], ["wfHighlightOnHover", ""]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.logout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i4.HighlightOnHoverDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var currVal_0 = "Error"; var currVal_1 = "alert-octagon"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = "/home"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.imgSrc; _ck(_v, 3, 0, currVal_2); }); }
export function View_ErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-error-page", [], null, null, null, View_ErrorPageComponent_0, RenderType_ErrorPageComponent)), i1.ɵdid(1, 114688, null, 0, i6.ErrorPageComponent, [i5.ActivatedRoute, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPageComponentNgFactory = i1.ɵccf("wf-error-page", i6.ErrorPageComponent, View_ErrorPageComponent_Host_0, {}, {}, []);
export { ErrorPageComponentNgFactory as ErrorPageComponentNgFactory };
