import { Injectable } from '@angular/core';
import { GlobalState } from '../../global/global.state';
import { Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { ClearModal, ShowModal } from '../store/core.actions';
import { Modal, ModalType, ButtonColor } from '@wildflowerhealth/console-shared';

@Injectable()
export class ModalService {

    private subject: Subject<any>;

    constructor(private store: Store<GlobalState>) {
    }

    showDeleteConfirmation(message: string, deleteButtonText = 'delete'): Observable<any> {
        this.subject = new Subject<any>();
        this.store.dispatch(new ShowModal({ modal: new Modal(ModalType.QUESTION, message, deleteButtonText, ButtonColor.DANGER) }));

        return this.subject;
    }

    showActionConfirmation(message: string, buttonName: string): Observable<any> {
        this.subject = new Subject<any>();
        this.store.dispatch(new ShowModal({ modal: new Modal(ModalType.QUESTION, message, buttonName, ButtonColor.PRIMARY) }));

        return this.subject;
    }

    showNotification(message: string): Observable<any> {
        this.subject = new Subject<any>();
        this.store.dispatch(new ShowModal({ modal: new Modal(ModalType.CONFIRMATION, message, 'OK', ButtonColor.DANGER) }));

        return this.subject;
    }

    showPasswordReset(message: string): Observable<string> {
        this.subject = new Subject<string>();
        this.store.dispatch(new ShowModal({ modal: new Modal(ModalType.PASSWORD, message, 'Update', ButtonColor.DANGER, 'Cancel', ButtonColor.NONE, false) }));

        return this.subject;
    }

    confirmModal(value: any = null): void {
        this.subject.next(value);
        this.subject.complete();
        this.store.dispatch(new ClearModal());
    }

    cancelModal(): void {
        this.subject.complete();
        this.store.dispatch(new ClearModal());
    }

}
