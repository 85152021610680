import { Component, Input, Optional } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'wf-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() wfDisabled = false;
    @Input() displayRequiredMessage = true;

    public value: boolean;
    public displayedValue: boolean; // FIXME: Workaround for Safari Angular 2.0-beta bug
    private onChangeCallback: any;
    public onTouchCallback: any;
    public parentNgControl: NgControl;

    constructor(@Optional() ngControl: NgControl) {
        ngControl.valueAccessor = this;
        this.parentNgControl = ngControl;
    }

    writeValue(obj: any): void {
        this.value = obj;
        this.displayedValue = this.value;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchCallback = fn;
    }

    update(event: Event) {
        event.stopPropagation();

        if (this.wfDisabled) {
            return;
        }

        this.value = !this.value;
        this.displayedValue = this.value;
        (this.onChangeCallback || (() => {
        }))(this.value);
    }

    get error(): string {
        return Object
            .keys(this.parentNgControl.errors || {})
            .reverse()
            .reduce((prev, current) => current, '');
    }

}

/**
 * Helper function for allowing only one selected checkbox in the form
 * @param control
 */
export const turnOffCheckBox = (control: AbstractControl) => (value: boolean) => {
    if (value) {
        control.setValue(false);
    }
};
