/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./users-list-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/animate-list.pipe";
import * as i3 from "../../pipes/select.pipe";
import * as i4 from "../../../ui-components/tile-header/tile-header.component.ngfactory";
import * as i5 from "../../../ui-components/tile-header/tile-header.component";
import * as i6 from "../clients-list/clients-list.component.ngfactory";
import * as i7 from "../clients-list/clients-list.component";
import * as i8 from "@angular/common";
import * as i9 from "../users-list/users-list.component.ngfactory";
import * as i10 from "../users-list/users-list.component";
import * as i11 from "./users-list-page.component";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/router";
var styles_UsersListPageComponent = [i0.styles];
var RenderType_UsersListPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UsersListPageComponent, data: {} });
export { RenderType_UsersListPageComponent as RenderType_UsersListPageComponent };
function View_UsersListPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "controls controls-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-primary btn-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "zmdi zmdi-plus"]], null, null, null, null, null))], null, null); }
export function View_UsersListPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.AnimateListPipe, []), i1.ɵpid(0, i3.SelectPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "wf-tile-header", [], null, null, null, i4.View_TileHeaderComponent_0, i4.RenderType_TileHeaderComponent)), i1.ɵdid(4, 49152, null, 0, i5.TileHeaderComponent, [], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 15, "div", [["class", "horizontal-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "wf-clients-list", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.selectClient($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ClientsListComponent_0, i6.RenderType_ClientsListComponent)), i1.ɵdid(7, 49152, null, 0, i7.ClientsListComponent, [], { clients: [0, "clients"], selectedClient: [1, "selectedClient"], viewSelectMode: [2, "viewSelectMode"] }, { select: "select" }), i1.ɵppd(8, 2), i1.ɵppd(9, 1), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(11, 2), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "vertical-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "users-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "wf-users-list", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.selectUser($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_UsersListComponent_0, i9.RenderType_UsersListComponent)), i1.ɵdid(16, 49152, null, 0, i10.UsersListComponent, [], { users: [0, "users"] }, { select: "select" }), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UsersListPageComponent_1)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "User Management & Permission Settings"; var currVal_1 = "account-circle"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 1), _co.store, "core.data.clients")))))); var currVal_3 = i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 12).transform(i1.ɵunv(_v, 7, 1, _ck(_v, 11, 0, i1.ɵnov(_v, 1), _co.store, "core.data.selectedClient")))); var currVal_4 = true; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.users)); _ck(_v, 16, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform(_co.isEditMode)); _ck(_v, 19, 0, currVal_6); }, null); }
export function View_UsersListPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-users-list-page", [], null, null, null, View_UsersListPageComponent_0, RenderType_UsersListPageComponent)), i1.ɵdid(1, 114688, null, 0, i11.UsersListPageComponent, [i12.Store, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UsersListPageComponentNgFactory = i1.ɵccf("wf-users-list-page", i11.UsersListPageComponent, View_UsersListPageComponent_Host_0, {}, {}, []);
export { UsersListPageComponentNgFactory as UsersListPageComponentNgFactory };
