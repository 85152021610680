import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { List } from 'immutable';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Application } from '@wildflowerhealth/console-shared';

@Injectable()
export class ApplicationService {

    private API_URL = this.environmentService.getAPIUrl() + '/applications';

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    fetch() {
        return this.http
            .get<any[]>(this.API_URL)
            .pipe(map(data => List(data).map(item => new Application(item)).toList()));
    }

    deleteApplication(application: Application) {
        return this.http.delete(this.API_URL + `/${application.id}`);
    }

    createNewApplication(application: Application) {
        return this.http.post(this.API_URL, application.toJSON());
    }

    updateApplication(application: Application) {
        return this.http.put(this.API_URL, application.toJSON());
    }

}
