import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

export class HttpHelper {
    static readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

    static getOptions() {
        return {
            headers: HttpHelper.headers
        };
    }

    static getErrorMessage(err: HttpErrorResponse): string {
        if (err.status === 0) {
            return 'Service unavailable';
        }

        if ((err as any).stack) {
            console.log((err as any).stack);
        }

        if (err.error instanceof ErrorEvent) {
            return (err.error as ErrorEvent).message;
        } else if (err?.error?.message) {
            return err.error.message;
        } else if (err.message != null) {
            return err.message;
        } else {
            return err.error;
        }
    }
}
