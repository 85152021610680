import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { List } from 'immutable';
import { slideFromRightListAnimation } from '../../core.animations';
import { Unit, Client, Application } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-units-list',
    templateUrl: './units-list.component.html',
    styleUrls: ['./units-list.component.scss'],
    animations: slideFromRightListAnimation,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitsListComponent {

    @Input() units: List<Unit>;
    @Input() clients: List<Client>;
    @Input() applications: List<Application>;
    @Input() selectedUnit: Unit;
    @Input() selectedApplication: Application;
    @Output() selectUnit = new EventEmitter<Unit>();
    @Output() selectApplication = new EventEmitter<Application>();
    @Output() addUnit = new EventEmitter<any>();
    @Output() editUnit = new EventEmitter<any>();
    @Output() deleteUnit = new EventEmitter<any>();
    @Output() manageUsers = new EventEmitter<any>();

    constructor() {
    }

    getUnits(application: Application): List<Unit> {
        return this.units
            .filter(unit => unit.applicationId === application.id)
            .toList();
    }

    getClientName(unit: Unit): string {
        return this.clients.find(client => client.id === unit.clientId).name;
    }

    addUnitClick(application: Application): void {
        this.selectedApplication && this.selectedApplication.id === application.id
            ? this.addUnit.next(application)
            : this.selectApplication.next(application);
    }

    deleteUnitClick(unit: Unit): void {
        this.selectedUnit && this.selectedUnit.id === unit.id
            ? this.deleteUnit.next(unit)
            : this.selectUnit.next(unit);
    }

    editUnitClick(unit: Unit): void {
        this.selectedUnit && this.selectedUnit.id === unit.id
            ? this.editUnit.next(unit)
            : this.selectUnit.next(unit);
    }

    manageUsersClick(unit: Unit): void {
        this.selectedUnit && this.selectedUnit.id === unit.id
            ? this.manageUsers.next(unit)
            : this.selectUnit.next(unit);
    }
}
