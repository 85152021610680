import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../global/global.state';
import { HttpHeaders } from '@wildflowerhealth/console-shared';
import { withLatestFrom, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SetServerVersion } from '../store/core.actions';

@Injectable()
export class VersionInterceptorService implements HttpInterceptor {
    constructor(private store: Store<GlobalState>) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                [HttpHeaders.CONSOLE_UI_VERSION]: environment.version,
            },
        });

        return next.handle(req)
            .pipe(
                withLatestFrom(this.store.select(s => s.core.data.serverVersion)),
                map(([ response, serverVersion ]) => {
                    if (response instanceof HttpResponse) {
                        const responseServerVersion = response.headers.get(HttpHeaders.CONSOLE_SERVER_VERSION);
                        if (responseServerVersion != null && responseServerVersion !== serverVersion) {
                            this.store.dispatch(new SetServerVersion({ version: responseServerVersion }));
                        }
                    }
                    return response;
                }),
            );
    }
}
