import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../global/global.state';
import { Router } from '@angular/router';
import { DeleteUnit, SelectApplication, SelectUnit } from '../../store/core.actions';
import { ModalService } from '../../services/modal.service';
import { first } from 'rxjs/operators';
import { Application, Unit } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-units-list-page',
    templateUrl: './units-list-page.component.html',
    styleUrls: ['./units-list-page.component.scss']
})
export class UnitsListPageComponent {

    constructor(public store: Store<GlobalState>, private router: Router, private modalService: ModalService) {
    }

    selectApplication(application: Application) {
        this.store.dispatch(new SelectApplication({ selectedApplication: application }));
    }

    selectUnit(unit: Unit) {
        this.store.dispatch(new SelectUnit({ selectedUnit: unit }));
    }

    addUnit() {
        this.store
            .select(store => store.core.data.selectedApplication.id)
            .pipe(first())
            .subscribe((selectedApplicationId: string) => this.router.navigate(['/configuration/units/form', { selectedApplicationId }]));
    }

    editUnit(unit: Unit) {
        this.router.navigate(['/configuration/units/form', { id: unit.id }]);
    }

    deleteUnit(unit: Unit) {
        this.modalService
            .showDeleteConfirmation(`Are you sure want to delete unti "${unit.name}" ?`)
            .subscribe(() => this.store.dispatch(new DeleteUnit({ unit })));
    }

    manageUsers(unit: Unit) {
        this.router.navigate(['configuration/units/user-assignment', { id: unit.id }]);
    }

}
