/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-uploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ngx-img-cropper/src/imageCropperComponent.ngfactory";
import * as i4 from "ngx-img-cropper/src/imageCropperComponent";
import * as i5 from "./image-uploader.component";
var styles_ImageUploaderComponent = [i0.styles];
var RenderType_ImageUploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageUploaderComponent, data: {} });
export { RenderType_ImageUploaderComponent as RenderType_ImageUploaderComponent };
function View_ImageUploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "img", [], [[8, "src", 4], [8, "width", 0], [8, "height", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "round": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, _co.isRound); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageData.image; var currVal_1 = _co.cropperSettings.croppedWidth; var currVal_2 = _co.cropperSettings.croppedHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ImageUploaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "img-stub"]], [[4, "width", null], [4, "height", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "round": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = "img-stub"; var currVal_3 = _ck(_v, 3, 0, _co.isRound); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cropperSettings.croppedWidth + "px"); var currVal_1 = (_co.cropperSettings.croppedHeight + "px"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ImageUploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cropper: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "fixed-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeUploader($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { hidden: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-flat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeUploader($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "zmdi zmdi-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "image-cropper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "span", [["class", "upload-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-warning"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        i1.ɵnov(_v, 12).click();
        $event.preventDefault();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Select Image to Upload "])), (_l()(), i1.ɵeld(12, 0, [["fileInput", 1]], null, 0, "input", [["id", "custom-input"], ["type", "file"]], null, [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChangeListener($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "img-cropper", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ImageCropperComponent_0, i3.RenderType_ImageCropperComponent)), i1.ɵdid(14, 4898816, [[1, 4], ["cropper", 4]], 0, i4.ImageCropperComponent, [i1.Renderer2], { settings: [0, "settings"], image: [1, "image"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "zmdi zmdi-long-arrow-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploaderComponent_1)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploaderComponent_2)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["DONE"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fixed-container"; var currVal_1 = _ck(_v, 4, 0, !_co.isUploaderShown); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.cropperSettings; var currVal_3 = _co.imageData; _ck(_v, 14, 0, currVal_2, currVal_3); var currVal_4 = _co.imageData.image; _ck(_v, 17, 0, currVal_4); var currVal_5 = !_co.imageData.image; _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = !_co.imageData.image; _ck(_v, 20, 0, currVal_6); }); }
export function View_ImageUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-image-uploader", [], null, null, null, View_ImageUploaderComponent_0, RenderType_ImageUploaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.ImageUploaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageUploaderComponentNgFactory = i1.ɵccf("wf-image-uploader", i5.ImageUploaderComponent, View_ImageUploaderComponent_Host_0, { image: "image", isUploaderShown: "isUploaderShown", isRound: "isRound" }, { imageChanged: "imageChanged", uploaderClosed: "uploaderClosed" }, []);
export { ImageUploaderComponentNgFactory as ImageUploaderComponentNgFactory };
