import { List } from 'immutable';
import { Product } from './Product';
import { IOption } from '@wildflowerhealth/console-shared';

export const ProductOptions: List<IOption> = List([
    { name: 'Due Date Plus', value: String(Product.DDP) },
    { name: 'Grow', value: String(Product.GROW) },
    { name: 'Unified DDP', value: String(Product.UDDP) },
    { name: 'Unified Grow', value: String(Product.UGROW) },
    { name: 'Hybrid', value: String(Product.HYBRID) },
    { name: 'VBC Platform', value: String(Product.VBC_PLATFORM  ) },
]);
