import { List } from 'immutable';
import { IOption, Application, Client, Unit } from '@wildflowerhealth/console-shared';

export class CoreOptionsHelper {

    static getApplications(applicationsList: List<Application>): List<IOption> {
        return applicationsList
            .map(application => ({
                value: application.id,
                name: application.name
            }))
            .toList();
    }

    static getClients(clientsList: List<Client>): List<IOption> {
        return clientsList
            .map(client => ({
                value: client.id,
                name: client.name
            }))
            .toList();
    }

    static getUnits(units: List<Unit>): List<IOption> {
        return units
            .map(unit => ({
                value: unit.id,
                name: unit.name
            }))
            .toList();
    }

}
