import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WfValidators } from '../../validators/WfValidator';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../global/global.state';
import { SignInAction } from '../../store/core.actions';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';

@Component({
    selector: 'wf-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {

    form: FormGroup;
    componentDestroyed = new Subject<boolean>();

    constructor(private formBuilder: FormBuilder, public store: Store<GlobalState>, private router: Router) {
    }

    ngOnInit() {
        this.store.select(state => state.core.authorizationState.isAuthorized).pipe(
            filter(isLoggedIn => isLoggedIn),
            takeUntil(this.componentDestroyed),
            take(1),
        ).subscribe(() => this.router.navigate(['/home']));

        this.form = this.formBuilder.group({
            email: ['', [Validators.required, WfValidators.email]],
            password: ['', Validators.required]
        });
    }

    signIn() {
        this.store.dispatch(new SignInAction(this.form.value));
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next(true);
        this.componentDestroyed.complete();
    }

}
