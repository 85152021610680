import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { HTTP_NOT_FOUND } from '@wildflowerhealth/console-shared';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'wf-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
    public imgSrc = 'https://http.cat/404';

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly authSvc: AuthService,
    ) { }

    ngOnInit() {
        this.activatedRoute.params
            .pipe(map(params => params['status']))
            .subscribe(status => {
                if (status == null) {
                    status = HTTP_NOT_FOUND;
                }
                this.imgSrc = `https://http.cat/${status}`;
            });
    }

    logout() {
        this.authSvc.signOut();
    }
}
