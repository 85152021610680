import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { HTTP_NOT_FOUND } from '@wildflowerhealth/console-shared';
import { ErrorInterceptorService } from './error-interceptor.service';

type HttpVerb = (uri: string, options: {}) => Observable<HttpResponse<string>>;

@Injectable()
export class ApplicationUserService {

    private readonly baseUrl = this.environmentService.getAPIUrl() + '/app-users';
    private readonly verbHead = ((uri: string, options: {}) => this.http.head(uri, options)) as HttpVerb;
    private readonly verbDelete = ((uri: string, options: {}) => this.http.delete(uri, options)) as HttpVerb;

    constructor(private readonly http: HttpClient, private readonly environmentService: EnvironmentService) { }

    private applicationUserRequest(
        verb: HttpVerb,
        phoneEnvironment: string,
        bundleId: string,
        email: string,
    ) {
        return verb(`${this.baseUrl}?environment=${encodeURIComponent(phoneEnvironment)}&bundleId=${encodeURIComponent(bundleId)}&email=${encodeURIComponent(email)}`, {
            headers: ErrorInterceptorService.BuildAcceptAllResponseStatusHeaders(),
            observe: 'response',
            responseType: 'text',
        }).pipe(
            map(res => res.ok),
            catchError((err: HttpErrorResponse) => {
                if (err.status === HTTP_NOT_FOUND) return of(false);
                throw err;
            }),
        );
    }

    public testApplicationUser(phoneEnvironment: string, bundleId: string, email: string) {
        return this.applicationUserRequest(this.verbHead, phoneEnvironment, bundleId, email);
    }

    public deleteApplicationUser(phoneEnvironment: string, bundleId: string, email: string) {
        return this.applicationUserRequest(this.verbDelete, phoneEnvironment, bundleId, email);
    }

}
