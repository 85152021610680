import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { List } from 'immutable';
import { fadeListAnimation } from '../../core.animations';
import { User } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    animations: fadeListAnimation,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersListComponent {

    @Input() users: List<User>;
    @Output() select: EventEmitter<User> = new EventEmitter<User>();

}
