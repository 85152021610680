<div class="tile">
    <wf-tile-header [icon]="'city'" [label]="'Roles'"></wf-tile-header>
    <wf-roles-list [roles]="store | select:'core.data.roles' | animateList | async"
        [selectedRole]="store | select:'core.data.selectedRole' | async" (select)="select($event)"
        (edit)="edit($event)" (delete)="delete($event)"></wf-roles-list>

    <div class="controls controls-right" *ngIf="isEditMode | async">
        <button class="btn btn-primary btn-action" (click)="create()">
            <i class="zmdi zmdi-plus"></i>
        </button>
    </div>

    <wf-spinner *ngIf="store | select: 'core.data.isRoleDeletion' | async"></wf-spinner>
</div>
