import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },

    {
        path: 'reporting',
        loadChildren: './reporting/reporting.module#ReportingModule'
    },

    {
        path: 'vcs',
        loadChildren: './versions-control/versions-control.module#VersionsControlModule'
    },

    {
        path: 'eligibility',
        loadChildren: './eligibility/eligibility.module#EligibilityModule'
    },

    {
        path: 'cms',
        loadChildren: './cms/cms.module#CmsModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
