import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WfValidators } from '../../core/validators/WfValidator';
import { ChangeUserPassword } from '../../core/store/core.actions';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../global/global.state';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../cms/components/BaseComponent';

@Component({
    selector: 'wf-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

    form: FormGroup;
    isPasswordChangingInProgress = false;

    constructor(public formBuilder: FormBuilder,
                public store: Store<GlobalState>) {
        super();
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            oldPassword: [null, Validators.required],
            newPassword: ['', Validators.compose([Validators.required, WfValidators.numberPresent, WfValidators.letterPresent])],
            confirmPassword: ['', Validators.compose([Validators.required, this.validateEquality])]
        });

        this.store.select(state => state.core.data.isPasswordChangingInProgress).pipe(
            takeUntil(this.componentDestroy$)).subscribe(status => this.isPasswordChangingInProgress = status);
    }

    public readonly validateEquality = () => {
        const result = this.form ? this.form.get('confirmPassword').value !== this.form.get('newPassword').value : false;
        return result ? { notEqual: result } : null;
    }

    submit(form: FormGroup) {
        if (form.invalid) {
            return;
        }
        this.store.dispatch(new ChangeUserPassword({
            ...this.form.value
        }));
    }

}
