import { Pipe, PipeTransform } from '@angular/core';
import { Observable, zip, from, interval } from 'rxjs';
import { List } from 'immutable';
import { switchMap, map, scan } from 'rxjs/operators';

@Pipe({
    name: 'animateList'
})
export class AnimateListPipe implements PipeTransform {

    transform(valueStream: Observable<List<any>>, args?: any): Observable<List<any>> {
        return valueStream.pipe(
            switchMap(value => zip(
                from(value ? value.toJS() : []),
                interval(60),
            ).pipe(
                map((item: [any, number]) => item[0]),
                scan((acc, next) => acc.push(next), List([])),
            ))
        );
    }
}
