<div class="horizontal-group">
    <label>{{label}}</label>
    <div class="permissions-group">
        <div class="option" wfHighlightOnHover (click)="selectNone()" [ngClass]="{'selected': isNoneSelected()}">
            <i class="zmdi zmdi-eye-off"></i>
        </div>

        <div class="option" wfHighlightOnHover (click)="selectViewOnly()" [ngClass]="{'selected': isViewSelected()}">
            <i class="zmdi zmdi-eye"></i>
        </div>

        <div class="option" wfHighlightOnHover (click)="selectEdit()" [ngClass]="{'selected': isEditSelected() }">
            <i class="zmdi zmdi-edit"></i>
        </div>
    </div>
</div>
