import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[wfRound]'
})
export class RoundDirective implements OnInit {

    @Input() radius = '50%';

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.el.nativeElement.style.borderRadius = this.radius;
    }

}
