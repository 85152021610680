import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { TextLanguage, LocalizedText } from '@wildflowerhealth/console-shared';
import { List } from 'immutable';

export class BaseComponent implements OnDestroy {

    private _componentDestroy$: Subject<void> = null;
    get componentDestroy$() {
        if (this._componentDestroy$ == null) {
            this._componentDestroy$ = new Subject<void>();
        }
        return this._componentDestroy$;
    }

    ngOnDestroy(): void {
        if (this._componentDestroy$ != null) {
            this._componentDestroy$.next();
            this._componentDestroy$.complete();
        }
    }

    defaultTextValue(txt: List<LocalizedText>): string {
        if (txt == null || txt.size <= 0) {
            return '';
        }
        const en = txt.find(t => t.language === TextLanguage.EN);
        if (en != null) {
            return en.value || '';
        }
        const something = txt.find(t => t.value != null);
        if (something != null) {
            return something.value || '';
        }
        return '';
    }
}
