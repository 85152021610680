import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../global/global.state';
import { HttpHeaders } from '@wildflowerhealth/console-shared';

@Injectable()
export class SessionInterceptorService implements HttpInterceptor {
    private sessionId: string;
    constructor(store: Store<GlobalState>) {
        store
            .select(s => s.core.data.sessionId)
            .subscribe(sessionId => { this.sessionId = sessionId; });
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                [HttpHeaders.SESSION_ID]: this.sessionId,
            },
        });

        return next.handle(req);
    }
}
