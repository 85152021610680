import { Action } from '@ngrx/store';
import { List } from 'immutable';
import { MenuSection } from '../constants/MenuSection';
import { ServerEventType, Client, Application, User, Unit, Notification, NotificationType, Hint, Modal, IUserAuthorizations, Role, IDistributedConfig } from '@wildflowerhealth/console-shared';

export enum CoreActionTypes {
    SIGNIN = 'SIGNIN',
    SIGNIN_SUCCESS = 'SIGNIN_SUCCESS',
    SIGNIN_FAILURE = 'SIGNIN_FAILURE',
    APP_INITIALIZE = 'APP_INITIALIZE',
    STORE_REDIRECT_URL = 'STORE_REDIRECT_URL',
    CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION',
    CHECK_AUTHORIZATION_SUCCESS = 'CHECK_AUTHORIZATION_SUCCESS',
    CHECK_AUTHORIZATION_FAILURE = 'CHECK_AUTHORIZATION_FAILURE',
    RESET_AUTHORIZATION_STATE = 'RESET_AUTHORIZATION_STATE',
    NAVIGATE_AFTER_SIGNIN = 'NAVIGATE_AFTER_SIGNIN',
    NAVIGATE_AFTER_SIGNIN_DONE = 'NAVIGATE_AFTER_SIGNIN_DONE',

    APP_INITIALIZE_SUCCESS = 'APP_INITIALIZE_SUCCESS',
    APP_INITIALIZE_FAILURE = 'APP_INITIALIZE_FAILURE',
    MENU_INITIALIZE = 'MENU_INITIALIZE',
    MENU_INITIALIZATION_SUCCESS = 'MENU_INITIALIZATION_SUCCESS',
    SET_ACTIVE_USER = 'SET_ACTIVE_USER',
    TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU',
    RESET_DATA_STATE = 'RESET_DATA_STATE',

    SELECT_CLIENT = 'SELECT_CLIENT',

    SAVE_CLIENT = 'SAVE_CLIENT',
    SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS',
    SAVE_CLIENT_FAILURE = 'SAVE_CLIENT_FAILURE',

    DELETE_CLIENT = 'DELETE_CLIENT',
    DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
    DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE',

    SELECT_APPLICATION = 'SELECT_APPLICATION',

    SAVE_APPLICATION = 'SAVE_APPLICATION',
    SAVE_APPLICATION_SUCCESS = 'SAVE_APPLICATION_SUCCESS',
    SAVE_APPLICATION_FAILURE = 'SAVE_APPLICATION_FAILURE',

    DELETE_APPLICATION = 'DELETE_APPLICATION',
    DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS',
    DELETE_APPLICATION_FAILURE = 'DELETE_APPLICATION_FAILURE',

    SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
    CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION',

    SHOW_HINT = 'SHOW_HINT',
    CLEAR_HINT = 'CLEAR_HINT',

    SELECT_UNIT = 'SELECT_UNIT',

    SAVE_UNIT = 'SAVE_UNIT',
    SAVE_UNIT_SUCCESS = 'SAVE_UNIT_SUCCESS',
    SAVE_UNIT_FAILURE = 'SAVE_UNIT_FAILURE',

    DELETE_UNIT = 'DELETE_UNIT',
    DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS',
    DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE',

    ASSIGN_USER = 'ASSIGN_USER',
    ASSIGN_USER_SUCCESS = 'ASSIGN_USER_SUCCESS',
    ASSIGN_USER_FAILURE = 'ASSIGN_USER_FAILURE',

    CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
    CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS',
    CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE',

    CHANGE_USER_AVATAR = 'CHANGE_USER_AVATAR',
    CHANGE_USER_AVATAR_SUCCESS = 'CHANGE_USER_AVATAR_SUCCESS',
    CHANGE_USER_AVATAR_FAILURE = 'CHANGE_USER_AVATAR_FAILURE',

    SAVE_USER = 'SAVE_USER',
    SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS',
    SAVE_USER_FAILURE = 'SAVE_USER_FAILURE',

    DELETE_USER = 'DELETE_USER',
    DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',

    LOAD_ROLES = 'LOAD_ROLES',
    LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS',
    LOAD_ROLES_FAILURE = 'LOAD_ROLES_FAILIRE',

    SAVE_ROLE = 'SAVE_ROLE',
    SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS',
    SAVE_ROLE_FAILURE = 'SAVE_ROLE_FAILURE',

    DELETE_ROLE = 'DELETE_ROLE',
    DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE',

    SELECT_ROLE= 'SELECT_ROLE',

    LOAD_UNITS = 'LOAD_UNITS',
    LOAD_UNITS_SUCCESS = 'LOAD_UNITS_SUCCESS',
    LOAD_UNITS_FAILURE = 'LOAD_UNITS_FAILIRE',

    SHOW_MODAL = 'SHOW_MODAL',
    CLEAR_MODAL = 'CLEAR_MODAL',

    ACTIVATE = 'ACTIVATE',
    ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS',
    ACTIVATE_FAILURE = 'ACTIVATE_FAILURE',

    DISPATCH_MESSAGE = 'DISPATCH_MESSAGE',
    DISPATCH_MESSAGE_FAILURE = 'DISPATCH_MESSAGE_FAILURE',
    KEY_PRESSED = 'KEY_PRESSED',

    SET_SERVER_VERSION = 'SET_SERVER_VERSION',
}

export class SignInAction implements Action {
    type = CoreActionTypes.SIGNIN;

    constructor(public payload: { email: string, password: string }) {
    }
}

export interface ISignInResult {
    permissionsConst: number;
    token: string;
    userId: string;
    authorizations: IUserAuthorizations;
}

export class SignInSuccessAction implements Action {
    type = CoreActionTypes.SIGNIN_SUCCESS;

    constructor(public payload: ISignInResult) {
    }
}

export class SignInFailureAction implements Action {
    type = CoreActionTypes.SIGNIN_FAILURE;
}

export class InitializeApplicationAction implements Action {
    type = CoreActionTypes.APP_INITIALIZE;

    constructor() {
    }
}

export class InitializeApplicationSuccessAction implements Action {
    type = CoreActionTypes.APP_INITIALIZE_SUCCESS;

    constructor(public payload: {
        clients: List<Client>,
        users: List<User>,
        roles: List<Role>,
        applications: List<Application>,
        units: List<Unit>,
        distCfg: IDistributedConfig,
    }) {
    }
}

export class InitializeApplicationFailureAction implements Action {
    type = CoreActionTypes.APP_INITIALIZE_FAILURE;
}

export class InitializeMenuAction implements Action {
    type = CoreActionTypes.MENU_INITIALIZE;

    constructor() {
    }
}

export class InitializationMenuSuccessAction implements Action {
    type = CoreActionTypes.MENU_INITIALIZATION_SUCCESS;

    constructor(public payload: { menu: List<MenuSection> }) {
    }
}

export class SetActiveUser implements Action {
    type = CoreActionTypes.SET_ACTIVE_USER;

    constructor(public payload: { activeUser: User, activeUserAuthorizations: IUserAuthorizations }) {
    }
}

export class CheckAuthorization implements Action {
    type = CoreActionTypes.CHECK_AUTHORIZATION;
}

export class CheckAuthorizationSuccess implements Action {
    type = CoreActionTypes.CHECK_AUTHORIZATION_SUCCESS;
}

export class CheckAuthorizationFailure implements Action {
    type = CoreActionTypes.CHECK_AUTHORIZATION_FAILURE;
}

export class ToggleSideMenu implements Action {
    type = CoreActionTypes.TOGGLE_SIDE_MENU;
}

export class StoreRedirectUrl implements Action {
    type = CoreActionTypes.STORE_REDIRECT_URL;

    constructor(public payload: { redirectUrl: string }) {
    }
}

export class ResetAuthorizationState implements Action {
    type = CoreActionTypes.RESET_AUTHORIZATION_STATE;
}

export class ResetDataState implements Action {
    type = CoreActionTypes.RESET_DATA_STATE;
}

export class NavigateAfterSignIn implements Action {
    type = CoreActionTypes.NAVIGATE_AFTER_SIGNIN;
}

export class NavigateAfterSignInDone implements Action {
    type = CoreActionTypes.NAVIGATE_AFTER_SIGNIN_DONE;
}

export class SelectClient implements Action {
    type = CoreActionTypes.SELECT_CLIENT;

    constructor(public payload: { selectedClient: Client }) {
    }
}

export class SaveClient implements Action {
    type = CoreActionTypes.SAVE_CLIENT;

    constructor(public payload: { client: Client }) {
    }
}

export class SaveClientSuccess implements Action {
    type = CoreActionTypes.SAVE_CLIENT_SUCCESS;

    constructor(public payload: { client: Client }) {
    }
}

export class SaveClientFailure implements Action {
    type = CoreActionTypes.SAVE_CLIENT_FAILURE;
}

export class DeleteClient implements Action {
    type = CoreActionTypes.DELETE_CLIENT;

    constructor(public payload: { client: Client }) {
    }
}

export class DeleteClientSuccess implements Action {
    type = CoreActionTypes.DELETE_CLIENT_SUCCESS;

    constructor(public payload: { client: Client }) {
    }
}

export class DeleteClientFailure implements Action {
    type = CoreActionTypes.DELETE_CLIENT_FAILURE;
}

export class SelectApplication implements Action {
    type = CoreActionTypes.SELECT_APPLICATION;

    constructor(public payload: { selectedApplication: Application }) {
    }
}

export class SaveApplication implements Action {
    type = CoreActionTypes.SAVE_APPLICATION;

    constructor(public payload: { application: Application }) {
    }
}

export class SaveApplicationSuccess implements Action {
    type = CoreActionTypes.SAVE_APPLICATION_SUCCESS;

    constructor(public payload: { application: Application }) {
    }
}

export class SaveApplicationFailure implements Action {
    type = CoreActionTypes.SAVE_APPLICATION_FAILURE;
}

export class DeleteApplication implements Action {
    type = CoreActionTypes.DELETE_APPLICATION;

    constructor(public payload: { application: Application }) {
    }
}

export class DeleteApplicationSuccess implements Action {
    type = CoreActionTypes.DELETE_APPLICATION_SUCCESS;

    constructor(public payload: { application: Application }) {
    }
}

export class DeleteApplicationFailure implements Action {
    type = CoreActionTypes.DELETE_APPLICATION_FAILURE;
}

export class ShowNotification implements Action {
    type = CoreActionTypes.SHOW_NOTIFICATION;

    constructor(public payload: { notification: Notification }) {
    }
}

export class ShowSuccessNotification implements Action {
    type = CoreActionTypes.SHOW_NOTIFICATION;
    payload: { notification: Notification };

    constructor(message: string) {
        this.payload = { notification: new Notification(NotificationType.SUCCESS, message) };
    }
}

export class ShowSuccessCreatedUpdatedNotification implements Action {
    type = CoreActionTypes.SHOW_NOTIFICATION;
    payload: { notification: Notification };

    constructor(isNew: boolean, topic: string) {
        const message = `${topic} was ${isNew ? 'created' : 'updated'}`;
        this.payload = { notification: new Notification(NotificationType.SUCCESS, message) };
    }
}

export class ShowWarningNotification implements Action {
    type = CoreActionTypes.SHOW_NOTIFICATION;
    payload: { notification: Notification };

    constructor(message: string) {
        this.payload = { notification: new Notification(NotificationType.WARNING, message) };
    }
}

export class ShowErrorNotification implements Action {
    type = CoreActionTypes.SHOW_NOTIFICATION;
    payload: { notification: Notification };

    constructor(message: string) {
        this.payload = { notification: new Notification(NotificationType.FAIL, message) };
    }
}

export class ClearNotification implements Action {
    type = CoreActionTypes.CLEAR_NOTIFICATION;
}

export class ShowHint implements Action {
    type = CoreActionTypes.SHOW_HINT;

    constructor(public payload: { hint: Hint }) {
    }
}

export class ClearHint implements Action {
    type = CoreActionTypes.CLEAR_HINT;
}

export class SelectUnit implements Action {
    type = CoreActionTypes.SELECT_UNIT;

    constructor(public payload: { selectedUnit: Unit }) {
    }
}

export class SaveUnit implements Action {
    type = CoreActionTypes.SAVE_UNIT;

    constructor(public payload: { unit: Unit }) {
    }
}

export class SaveUnitSuccess implements Action {
    type = CoreActionTypes.SAVE_UNIT_SUCCESS;

    constructor(public payload: { unit: Unit }) {
    }
}

export class SaveUnitFailure implements Action {
    type = CoreActionTypes.SAVE_UNIT_FAILURE;
}

export class DeleteUnit implements Action {
    type = CoreActionTypes.DELETE_UNIT;

    constructor(public payload: { unit: Unit }) {
    }
}

export class DeleteUnitSuccess implements Action {
    type = CoreActionTypes.DELETE_UNIT_SUCCESS;

    constructor(public payload: { unit: Unit }) {
    }
}

export class DeleteUnitFailure implements Action {
    type = CoreActionTypes.DELETE_UNIT_FAILURE;
}


export class AssignUsersToUnit implements Action {
    type = CoreActionTypes.ASSIGN_USER;

    constructor(public payload: { unit: Unit, userIds: List<string> }) {
    }
}

export class AssignUsersToUnitSuccess implements Action {
    type = CoreActionTypes.ASSIGN_USER_SUCCESS;

    constructor(public payload: { unit: Unit, userIds: List<string> }) {
    }
}

export class AssignUsersToUnitFailure implements Action {
    type = CoreActionTypes.ASSIGN_USER_FAILURE;
}

export class ChangeUserAvatar implements Action {
    type = CoreActionTypes.CHANGE_USER_AVATAR;

    constructor(public payload: { avatar: string; }) {
    }
}

export class ChangeUserAvatarSuccess implements Action {
    type = CoreActionTypes.CHANGE_USER_AVATAR_SUCCESS;

    constructor(public payload: { avatar: string; }) {
    }
}

export class ChangeUserAvatarFailure implements Action {
    type = CoreActionTypes.CHANGE_USER_AVATAR_FAILURE;

    constructor() {
    }
}

export class ChangeUserPassword implements Action {
    type = CoreActionTypes.CHANGE_USER_PASSWORD;

    constructor(public payload: { oldPassword: string, newPassword: string }) {
    }
}

export class ChangeUserPasswordSuccess implements Action {
    type = CoreActionTypes.CHANGE_USER_PASSWORD_SUCCESS;

    constructor() {
    }
}

export class ChangeUserPasswordFailure implements Action {
    type = CoreActionTypes.CHANGE_USER_PASSWORD_FAILURE;

    constructor() {
    }
}

export class SaveUser implements Action {
    type = CoreActionTypes.SAVE_USER;

    constructor(public payload: { user: User }) {
    }
}

export class SaveUserSuccess implements Action {
    type = CoreActionTypes.SAVE_USER_SUCCESS;

    constructor(public payload: { user: User }) {
    }
}

export class SaveUserFailure implements Action {
    type = CoreActionTypes.SAVE_USER_FAILURE;
}

export class DeleteUser implements Action {
    type = CoreActionTypes.DELETE_USER;

    constructor(public payload: { user: User }) {
    }
}

export class DeleteUserSuccess implements Action {
    type = CoreActionTypes.DELETE_USER_SUCCESS;

    constructor(public payload: { user: User }) {
    }
}

export class DeleteUserFailure implements Action {
    type = CoreActionTypes.DELETE_USER_FAILURE;
}

export class LoadRoles implements Action {
    type = CoreActionTypes.LOAD_ROLES;

    constructor() {
    }
}

export class LoadRolesSuccess implements Action {
    type = CoreActionTypes.LOAD_ROLES_SUCCESS;

    constructor(public payload: { roles: List<Role> }) {
    }
}

export class LoadRolesFailure implements Action {
    type = CoreActionTypes.LOAD_ROLES_FAILURE;

    constructor() {
    }
}

export class SaveRole implements Action {
    type = CoreActionTypes.SAVE_ROLE;

    constructor(public payload: { role: Role }) {
    }
}

export class SaveRoleSuccess implements Action {
    type = CoreActionTypes.SAVE_ROLE_SUCCESS;

    constructor(public payload: { role: Role }) {
    }
}

export class SaveRoleFailure implements Action {
    type = CoreActionTypes.SAVE_ROLE_FAILURE;
}

export class DeleteRole implements Action {
    type = CoreActionTypes.DELETE_ROLE;

    constructor(public payload: { role: Role }) {
    }
}

export class DeleteRoleSuccess implements Action {
    type = CoreActionTypes.DELETE_ROLE_SUCCESS;

    constructor(public payload: { role: Role }) {
    }
}

export class DeleteRoleFailure implements Action {
    type = CoreActionTypes.DELETE_ROLE_FAILURE;
}

export class SelectRole implements Action {
    type = CoreActionTypes.SELECT_ROLE;

    constructor(public payload: { selectedRole: Role }) {
    }
}

export class ShowModal implements Action {
    type = CoreActionTypes.SHOW_MODAL;

    constructor(public payload: { modal: Modal }) {
    }
}

export class ClearModal implements Action {
    type = CoreActionTypes.CLEAR_MODAL;
}

export class ActivateAction implements Action {
    type = CoreActionTypes.ACTIVATE;

    constructor(public payload: { token?: string, userId?: string, password: string }) {
    }
}

export class ActivateSuccessAction implements Action {
    type = CoreActionTypes.ACTIVATE_SUCCESS;

    constructor(public payload: { userId?: string } = {}) {
    }
}

export class ActivateFailureAction implements Action {
    type = CoreActionTypes.ACTIVATE_FAILURE;
}

export class DispatchMessage implements Action {
    type = CoreActionTypes.DISPATCH_MESSAGE;

    constructor(public serverEventType: ServerEventType, public payload: any) {

    }
}

export class DispatchMessageFailure implements Action {
    type = CoreActionTypes.DISPATCH_MESSAGE_FAILURE;

    constructor() {

    }
}

export class LoadUnits implements Action {
    type = CoreActionTypes.LOAD_UNITS;

    constructor() {
    }
}

export class LoadUnitsSuccess implements Action {
    type = CoreActionTypes.LOAD_UNITS_SUCCESS;

    constructor(public payload: { units: List<Unit> }) {
    }
}

export class LoadUnitsFailure implements Action {
    type = CoreActionTypes.LOAD_UNITS_FAILURE;

    constructor() {
    }
}

export class KeyPressed implements Action {
    type = CoreActionTypes.KEY_PRESSED;

    constructor(public payload: { event: KeyboardEvent }) {
    }
}

export class SetServerVersion implements Action {
    type = CoreActionTypes.SET_SERVER_VERSION;

    constructor(public payload: { version: string }) {
    }
}






