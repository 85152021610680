import { Action, ActionReducer, ActionReducerMap, combineReducers, compose } from '@ngrx/store';
import { CoreActionTypes, KeyPressed, LoadUnitsSuccess, SetServerVersion, LoadRolesSuccess, InitializeApplicationSuccessAction } from './core.actions';
import {
    AuthorizationState,
    CoreState,
    DataState,
    initialAuthorizationState,
    initialDataState
} from './core.states';
import { List } from 'immutable';
import { Client, Application, Unit, User, Role } from '@wildflowerhealth/console-shared';

export const authorizationReducer: ActionReducer<AuthorizationState> = (state: AuthorizationState = initialAuthorizationState, action: Action) => {
    switch (action.type) {
        case CoreActionTypes.SIGNIN: {
            return Object.assign({}, state, {
                isAuthorizationFetching: true
            });
        }

        case CoreActionTypes.SIGNIN_SUCCESS: {
            return Object.assign({}, state, {
                isAuthorized: true,
                isAuthorizationFetching: true
            });
        }

        case CoreActionTypes.CHECK_AUTHORIZATION_SUCCESS: {
            return Object.assign({}, state, {
                isAuthorized: true,
                isAuthorizationFetching: true
            });
        }

        case CoreActionTypes.SIGNIN_FAILURE: {
            return Object.assign({}, state, {
                isAuthorized: false,
                isAuthorizationFetching: false
            });
        }

        case CoreActionTypes.STORE_REDIRECT_URL: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.RESET_AUTHORIZATION_STATE: {
            return Object.assign({}, initialAuthorizationState, { redirectUrl: state.redirectUrl });
        }

        case CoreActionTypes.NAVIGATE_AFTER_SIGNIN_DONE: {
            return Object.assign({}, state, {
                redirectUrl: null
            });
        }
    }

    return state;
};

export const dataReducer: ActionReducer<DataState> = (state: DataState = initialDataState, action: Action) => {
    const sort = (collection: List<any>) => collection.sort((a, b) => a.compare(b));

    switch (action.type) {
        case CoreActionTypes.APP_INITIALIZE: {
            return Object.assign({}, state, { isInitializationFetching: true });
        }
        case CoreActionTypes.APP_INITIALIZE_SUCCESS: {
            const payload = (action as InitializeApplicationSuccessAction).payload;
            return Object.assign({}, state, { isInitialized: true, isInitializationFetching: false }, {
                clients: sort(payload.clients),
                applications: sort(payload.applications),
                units: sort(payload.units),
                users: sort(payload.users),
                roles: sort(payload.roles),
                distributedConfig: payload.distCfg,
            });
        }
        case CoreActionTypes.MENU_INITIALIZATION_SUCCESS: {
            return Object.assign({}, state, action['payload']);
        }
        case CoreActionTypes.SET_ACTIVE_USER: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.TOGGLE_SIDE_MENU: {
            return Object.assign({}, state, { isSideMenuOpened: !state.isSideMenuOpened });
        }

        case CoreActionTypes.RESET_DATA_STATE: {
            return initialDataState;
        }

        case CoreActionTypes.SELECT_CLIENT: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.SAVE_CLIENT: {
            return Object.assign({}, state, { isClientSaving: true });
        }

        case CoreActionTypes.SAVE_CLIENT_SUCCESS: {
            const savedClient: Client = action['payload']['client'];

            return Object.assign({}, state, {
                isClientSaving: false,
                clients: sort(state.clients
                    .filterNot(client => client.id === savedClient.id)
                    .toList()
                    .push(savedClient.markAsSaved()))
            });
        }

        case CoreActionTypes.SAVE_CLIENT_FAILURE: {
            return Object.assign({}, state, { isClientSaving: false });
        }

        case CoreActionTypes.DELETE_CLIENT: {
            return Object.assign({}, state, { isClientDeletion: true });
        }

        case CoreActionTypes.DELETE_CLIENT_SUCCESS: {
            const deletedClient: Client = action['payload']['client'];

            return Object.assign({}, state, {
                isClientDeletion: false,
                clients: sort(state.clients
                    .filterNot(client => client.id === deletedClient.id)
                    .toList()
                ),
                selectedClient: state.selectedClient && state.selectedClient.id === deletedClient.id ? null : state.selectedClient,
                units: state.units
                    .filterNot(unit => unit.clientId === deletedClient.id)
            });
        }

        case CoreActionTypes.DELETE_CLIENT_FAILURE: {
            return Object.assign({}, state, { isClientDeletion: false });
        }

        case CoreActionTypes.SELECT_APPLICATION: {
            return Object.assign({}, state, action['payload'], {
                selectedUnit: null
            });
        }

        case CoreActionTypes.SAVE_APPLICATION: {
            return Object.assign({}, state, { isApplicationSaving: true });
        }

        case CoreActionTypes.SAVE_APPLICATION_SUCCESS: {
            const savedApplication: Application = action['payload']['application'];

            return Object.assign({}, state, {
                isApplicationSaving: false,
                applications: sort(state.applications
                    .filterNot(application => application.id === savedApplication.id)
                    .toList()
                    .push(savedApplication.markAsSaved()))
            });
        }

        case CoreActionTypes.SAVE_APPLICATION_FAILURE: {
            return Object.assign({}, state, { isApplicationSaving: false });
        }

        case CoreActionTypes.DELETE_APPLICATION: {
            return Object.assign({}, state, { isApplicationDeletion: true });
        }

        case CoreActionTypes.DELETE_APPLICATION_SUCCESS: {
            const deletedApplication: Application = action['payload']['application'];

            return Object.assign({}, state, {
                isApplicationDeletion: false,
                applications: sort(state.applications
                    .filterNot(application => application.id === deletedApplication.id)
                    .toList()
                )
            });
        }

        case CoreActionTypes.DELETE_APPLICATION_FAILURE: {
            return Object.assign({}, state, { isApplicationDeletion: false });
        }

        case CoreActionTypes.LOAD_UNITS: {
            return Object.assign({}, state, { isUnitsLoading: true });
        }

        case CoreActionTypes.LOAD_UNITS_SUCCESS: {
            const units: List<Unit> = (action as LoadUnitsSuccess).payload.units;

            return Object.assign({}, state, {
                isUnitsLoading: false,
                units: sort(units)
            });
        }

        case CoreActionTypes.LOAD_UNITS_FAILURE: {
            return Object.assign({}, state, { isUnitsLoading: false });
        }

        case CoreActionTypes.SHOW_NOTIFICATION: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.CLEAR_NOTIFICATION: {
            return Object.assign({}, state, { notification: null });
        }

        case CoreActionTypes.SHOW_HINT: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.CLEAR_HINT: {
            return Object.assign({}, state, { hint: null });
        }


        case CoreActionTypes.SELECT_UNIT: {
            return Object.assign({}, state, action['payload'], {
                selectedApplication: null
            });
        }

        case CoreActionTypes.SAVE_UNIT: {
            return Object.assign({}, state, { isUnitSaving: true });
        }

        case CoreActionTypes.SAVE_UNIT_SUCCESS: {
            const savedUnit: Unit = action['payload']['unit'];

            return Object.assign({}, state, {
                isUnitSaving: false,
                units: sort(state.units
                    .filterNot(unit => unit.id === savedUnit.id)
                    .toList()
                    .push(savedUnit.markAsSaved()))
            });
        }

        case CoreActionTypes.SAVE_UNIT_FAILURE: {
            return Object.assign({}, state, { isUnitSaving: false });
        }

        case CoreActionTypes.DELETE_UNIT: {
            return Object.assign({}, state, { isUnitDeletion: true });
        }

        case CoreActionTypes.DELETE_UNIT_SUCCESS: {
            const deletedUnit: Unit = action['payload']['unit'];

            return Object.assign({}, state, {
                isUnitDeletion: false,
                units: sort(state.units
                    .filterNot(unit => unit.id === deletedUnit.id)
                    .toList()
                )
            });
        }

        case CoreActionTypes.DELETE_UNIT_FAILURE: {
            return Object.assign({}, state, { isUnitDeletion: false });
        }

        case CoreActionTypes.ASSIGN_USER: {
            return Object.assign({}, state, { isUserUnitAssignment: true });
        }

        case CoreActionTypes.ASSIGN_USER_SUCCESS: {
            const unitToAssign: Unit = action['payload']['unit'];

            unitToAssign.userIds = action['payload']['userIds'];

            return Object.assign({}, state, {
                isUserUnitAssignment: false,
                units: sort(state.units
                    .filterNot(unit => unit.id === unitToAssign.id)
                    .toList()
                    .push(unitToAssign)
                )
            });
        }

        case CoreActionTypes.ASSIGN_USER_FAILURE: {
            return Object.assign({}, state, { isUserUnitAssignment: false });
        }

        case CoreActionTypes.CHANGE_USER_AVATAR: {
            state.activeUser.avatar = action['payload'].avatar;
            return Object.assign({}, state, {
                isAvatarChangingInProgress: true,
                activeUser: new User({
                    ...state.activeUser,
                    avatar: action['payload'].avatar
                })
            });
        }

        case CoreActionTypes.CHANGE_USER_AVATAR_SUCCESS: {
            return Object.assign({}, state, { isAvatarChangingInProgress: false });

        }

        case CoreActionTypes.CHANGE_USER_AVATAR_FAILURE: {
            return Object.assign({}, state, { isAvatarChangingInProgress: false });
        }

        case CoreActionTypes.CHANGE_USER_PASSWORD: {
            return Object.assign({}, state, { isPasswordChangingInProgress: true });
        }

        case CoreActionTypes.CHANGE_USER_PASSWORD_SUCCESS: {
            return Object.assign({}, state, { isPasswordChangingInProgress: false });

        }

        case CoreActionTypes.CHANGE_USER_PASSWORD_FAILURE: {
            return Object.assign({}, state, { isPasswordChangingInProgress: false });
        }

        case CoreActionTypes.SAVE_USER: {
            return Object.assign({}, state, { isUserSaving: true });
        }

        case CoreActionTypes.SAVE_USER_SUCCESS: {
            const savedUser: User = action['payload']['user'];

            return Object.assign({}, state, {
                isUserSaving: false,
                users: sort(state.users
                    .filterNot(user => user.id === savedUser.id)
                    .toList()
                    .push(savedUser.markAsSaved()))
            });
        }

        case CoreActionTypes.SAVE_USER_FAILURE: {
            return Object.assign({}, state, { isUserSaving: false });
        }

        case CoreActionTypes.DELETE_USER: {
            return Object.assign({}, state, { isUserDeletion: true });
        }

        case CoreActionTypes.DELETE_USER_SUCCESS: {
            const deletedUser: User = action['payload']['user'];

            return Object.assign({}, state, {
                isUserDeletion: false,
                users: state.users
                    .map(user => {
                        if (user.id === deletedUser.id) {
                            user.active = false;
                        }
                        return user;
                    })
                    .toList(),
            });
        }

        case CoreActionTypes.DELETE_USER_FAILURE: {
            return Object.assign({}, state, { isUserDeletion: false });
        }

        case CoreActionTypes.LOAD_ROLES: {
            return Object.assign({}, state, { isRolesLoading: true });
        }

        case CoreActionTypes.LOAD_ROLES_SUCCESS: {
            const roles: List<Role> = (action as LoadRolesSuccess).payload.roles;
            return Object.assign({}, state, {
                isRolesLoading: false,
                roles: sort(roles)
            });
        }

        case CoreActionTypes.LOAD_ROLES_FAILURE: {
            return Object.assign({}, state, { isRolesLoading: false });
        }

        case CoreActionTypes.SAVE_ROLE: {
            return Object.assign({}, state, { isRoleSaving: true });
        }

        case CoreActionTypes.SAVE_ROLE_SUCCESS: {
            const savedRole: Role = action['payload']['role'];

            return Object.assign({}, state, {
                isRoleSaving: false,
                roles: sort(state.roles
                    .filterNot(role => role.id === savedRole.id)
                    .toList()
                    .push(savedRole.markAsSaved()))
            });
        }

        case CoreActionTypes.SAVE_ROLE_FAILURE: {
            return Object.assign({}, state, { isRoleSaving: false });
        }

        case CoreActionTypes.DELETE_ROLE: {
            return Object.assign({}, state, { isRoleDeletion: true });
        }

        case CoreActionTypes.DELETE_ROLE_SUCCESS: {
            const deletedRole: Role = action['payload']['role'];

            return Object.assign({}, state, {
                isRoleDeletion: false,
                roles: state.roles
                    .filter(role => role.id !== deletedRole.id)
                    .toList(),
            });
        }

        case CoreActionTypes.DELETE_ROLE_FAILURE: {
            return Object.assign({}, state, { isRoleDeletion: false });
        }

        case CoreActionTypes.SELECT_ROLE: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.SHOW_MODAL: {
            return Object.assign({}, state, action['payload']);
        }

        case CoreActionTypes.CLEAR_MODAL: {
            return Object.assign({}, state, { modal: null });
        }

        case CoreActionTypes.ACTIVATE: {
            return Object.assign({}, state, { isActivation: true });
        }

        case CoreActionTypes.ACTIVATE_SUCCESS: {
            const updates: Partial<DataState> = { isActivation: false };
            const userId = action['payload'].userId;
            if (userId != null) {
                updates.users = state.users
                    .map(user => {
                        if (user.id === userId) {
                            user.active = true;
                        }
                        return user;
                    })
                    .toList();
            }
            return Object.assign({}, state, updates);
        }

        case CoreActionTypes.SET_SERVER_VERSION: {
            return { ...state, serverVersion: (action as SetServerVersion).payload.version };
        }
    }

    return state;
};


export const reducers: ActionReducerMap<CoreState> = {
    authorizationState: authorizationReducer,
    data: dataReducer
};

export const combinedReducers = compose(combineReducers)(reducers);

export function reducer(state, action) {
    return combinedReducers(state, action);
}
