import { HttpHeaders } from '@angular/common/http';
export class HttpHelper {
    static getOptions() {
        return {
            headers: HttpHelper.headers
        };
    }
    static getErrorMessage(err) {
        var _a, _b;
        if (err.status === 0) {
            return 'Service unavailable';
        }
        if (err.stack) {
            console.log(err.stack);
        }
        if (err.error instanceof ErrorEvent) {
            return err.error.message;
        }
        else if ((_b = (_a = err) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) {
            return err.error.message;
        }
        else if (err.message != null) {
            return err.message;
        }
        else {
            return err.error;
        }
    }
}
HttpHelper.headers = new HttpHeaders().set('Content-Type', 'application/json');
