/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../ui-components/highlight-on-hover.directive";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../pipes/select.pipe";
import * as i6 from "./side-menu.component";
import * as i7 from "@ngrx/store";
var styles_SideMenuComponent = [i0.styles];
var RenderType_SideMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideMenuComponent, data: {} });
export { RenderType_SideMenuComponent as RenderType_SideMenuComponent };
function View_SideMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "item"], ["wfHighlightOnHover", ""]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.HighlightOnHoverDirective, [i1.ElementRef], null, null), i1.ɵdid(2, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.uri, ""); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_1); }); }
function View_SideMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "section-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "i", [["class", "zmdi"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_2)), i1.ɵdid(10, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "zmdi"; var currVal_1 = ("zmdi-" + _v.context.$implicit.icon); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.menuItems; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.label; _ck(_v, 7, 0, currVal_2); }); }
export function View_SideMenuComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.SelectPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "side-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_SideMenuComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(5, 2), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform(i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.store, "core.data.menu")))); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SideMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-side-menu", [], null, null, null, View_SideMenuComponent_0, RenderType_SideMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.SideMenuComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideMenuComponentNgFactory = i1.ɵccf("wf-side-menu", i6.SideMenuComponent, View_SideMenuComponent_Host_0, {}, {}, []);
export { SideMenuComponentNgFactory as SideMenuComponentNgFactory };
