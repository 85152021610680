import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { ModalService } from './modal.service';
import { io, Socket } from 'socket.io-client';
import { GlobalState } from '../../global/global.state';
import { Store } from '@ngrx/store';
import { DispatchMessage, ShowErrorNotification } from '../store/core.actions';
import { SocketMessage } from '../interfaces/SocketMessage';
import { Observable, Subject } from 'rxjs';
import { ServerEventType } from '@wildflowerhealth/console-shared';

@Injectable()
export class SocketService {

    private SOCKET_URL = this.environmentService.getSocketUrl();
    private socket: Socket;
    private cmsImportSocket: Socket;
    private sessionId: string;
    private readonly _events$ = new Subject<SocketMessage>();

    public get events$(): Observable<SocketMessage> {
        return this._events$;
    }

    constructor(private environmentService: EnvironmentService,
        private modalService: ModalService,
        private store: Store<GlobalState>,
    ) {
        store
            .select(s => s.core.data.sessionId)
            .subscribe(sessionId => { this.sessionId = sessionId; });
    }

    initSocket(token: string): Observable<boolean> {
        this.socket = io(this.SOCKET_URL, {
            query: { token, sessionId: this.sessionId },
        });

        this.socket.on('error', error => this.store.dispatch(new ShowErrorNotification(error)));

        this.socket.on('message', (message: SocketMessage) => {
            // FIXME: why no log love for prod?
            if (!this.environmentService.isProduction()) {
                console.log(message);
            }

            this._events$.next(message);

            if (message.type === ServerEventType.ERROR) {
                return this.modalService
                    .showNotification(message.payload.message)
                    .subscribe(() => {
                    });
            }

            this.store.dispatch(new DispatchMessage(
                message.type,
                message.payload
            ));
        });

        return new Observable(observer => {
            observer.next(true);
            observer.complete();
        });
    }

    initCmsImportSocket(token: string): Socket {
        this.cmsImportSocket = io(this.SOCKET_URL, {
            path: '/socket/cms-import',
            transports: ['websocket'],
            autoConnect: false,
            query: { token },
        });

        this.cmsImportSocket.on('error', error => this.store.dispatch(new ShowErrorNotification(error)));

        return this.cmsImportSocket;
    }

    send(...msg: any[]) {
        this.socket.send(...msg);
    }

    terminate() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}
