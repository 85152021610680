/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../input-error/input-error.component.ngfactory";
import * as i4 from "../input-error/input-error.component";
import * as i5 from "@angular/forms";
import * as i6 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "input", [["class", "ng-touched ng-valid disabled"], ["disabled", ""]], [[8, "value", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "wf-empty": 0, "wf-filled": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "ng-touched ng-valid disabled"; var currVal_2 = _ck(_v, 3, 0, (_co.textStub === ""), (_co.textStub !== "")); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textStub; _ck(_v, 0, 0, currVal_0); }); }
function View_InputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["wfInput", 1]], null, 3, "input", [["autocomplete", "new-password"]], [[8, "type", 0], [8, "value", 0], [8, "disabled", 0]], [[null, "blur"], [null, "keyup"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.touch() !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.update(i1.ɵnov(_v, 0).value) !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (_co.focus.next() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "ng-touched": 0, "ng-untouched": 1, "ng-valid": 2, "ng-invalid": 3, "ng-pristine": 4, "ng-dirty": 5, "wf-empty": 6, "wf-filled": 7, "wf-active": 8 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, _co.parentNgControl.touched, !_co.parentNgControl.touched, _co.parentNgControl.valid, !_co.parentNgControl.valid, _co.parentNgControl.pristine, !_co.parentNgControl.pristine, (_co.value === ""), ((_co.value !== "") && (_co.value !== null)), _co.alwaysActivated); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.type, ""); var currVal_1 = _co.value; var currVal_2 = _co.wfDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_InputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "wf-input-error", [], null, null, null, i3.View_InputErrorComponent_0, i3.RenderType_InputErrorComponent)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(11, { "always-show-errors": 0 }), i1.ɵdid(12, 49152, null, 0, i4.InputErrorComponent, [], { error: [0, "error"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textStub; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.textStub; _ck(_v, 4, 0, currVal_1); var currVal_3 = _ck(_v, 11, 0, _co.displayErrors); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.error; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 7, 0, currVal_2); }); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "wf-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵprd(4608, null, i5.DefaultValueAccessor, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]]), i1.ɵdid(2, 49152, null, 0, i6.InputComponent, [[2, i5.NgControl]], null, null)], null, null); }
var InputComponentNgFactory = i1.ɵccf("wf-input", i6.InputComponent, View_InputComponent_Host_0, { label: "label", type: "type", textStub: "textStub", wfDisabled: "wfDisabled", alwaysActivated: "alwaysActivated", displayErrors: "displayErrors" }, { focus: "focus", blur: "blur" }, []);
export { InputComponentNgFactory as InputComponentNgFactory };
