import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[wfHighlightOnHover]'
})
export class HighlightOnHoverDirective {

    constructor(private el: ElementRef) {
        this.el.nativeElement.style.cursor = 'pointer';
        this.el.nativeElement.style.transitionDuration = '0.25s';
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.highlight('#20afe5');
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.highlight(null);
    }

    private highlight(color: string) {
        const queue: Array<ElementRef> = [this.el];

        while (queue.length > 0) {
            const element: ElementRef = queue.shift();

            element.nativeElement.style.color = color;
            element.nativeElement.style.outline = 'none';

            // queue = queue.concat(Array.from(element.nativeEle));
        }
    }
}
