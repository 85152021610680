import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { AuthService } from './services/auth.service';
import { EnvironmentService } from './services/environment.service';
import { LocalStorageService } from './services/local-storage.service';
import { ClientService } from './services/client.service';
import { ApplicationService } from './services/application.service';
import { ApplicationUserService } from './services/application-user.service';
import { UnitService } from './services/unit.service';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './store/core.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/core.reducers';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { MenuStickerComponent } from './components/menu-sticker/menu-sticker.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AuthGuardService } from './services/auth-guard.service';
import { NavigationService } from './services/navigation.service';
import { ClientFormPageComponent } from './components/client-form-page/client-form-page.component';
import { ClientsListPageComponent } from './components/clients-list-page/clients-list-page.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';
import { SelectPipe } from './pipes/select.pipe';
import { AnimateListPipe } from './pipes/animate-list.pipe';
import { ApplicationsListPageComponent } from './components/applications-list-page/applications-list-page.component';
import { ApplicationsListComponent } from './components/applications-list/applications-list.component';
import { ApplicationFormPageComponent } from './components/application-form-page/application-form-page.component';
import { NotificationComponent } from './components/notification/notification.component';
import { UnitsListPageComponent } from './components/units-list-page/units-list-page.component';
import { UnitsListComponent } from './components/units-list/units-list.component';
import { UnitFormPageComponent } from './components/unit-form-page/unit-form-page.component';
import { UnitUserAssignmentPageComponent } from './components/unit-user-assignment-page/unit-user-assignment-page.component';
import { UsersListPageComponent } from './components/users-list-page/users-list-page.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserFormPageComponent } from './components/user-form-page/user-form-page.component';
import { UserDetailsPageComponent } from './components/user-details-page/user-details-page.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './services/modal.service';
import { ActivatePageComponent } from './components/activate-page/activate-page.component';
import { ApplicationsListSmallComponent } from './components/applications-list-small/applications-list-small.component';
import { SocketService } from './services/socket.service';
import { SmallUnitItemComponent } from './components/small-unit-item/small-unit-item.component';
import { BooleanPipe } from './pipes/boolean.pipe';
import { SmallUnitsListComponent } from './components/small-units-list/small-units-list.component';
import { HintComponent } from './components/hint/hint.component';
import { SmallApplicationItemComponent } from './components/small-application-item/small-application-item.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { AuthPipe } from './pipes/auth.pipe';
import { ImageInputSimpleComponent } from './components/image-input-simple/image-input-simple.component';
import { SwitchCasesDirective } from './helpers/switch-cases.directive';
import { WindowRefService } from './services/window-ref.service';
import { RawHtmlPipe } from './pipes/raw-html.pipe';
import { ApplicationUsersPageComponent } from './components/application-users-page/application-users-page.component';
import { RoleFormPageComponent } from './components/role-form-page/role-form-page.component';
import { RolesListPageComponent } from './components/roles-list-page/roles-list-page.component';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { LogoutPageComponent } from './components/logout-page/logout-page.component';
import { DistributedConfigService } from './services/distributed-config.service';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';

@NgModule({
    imports: [
        UiComponentsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreRoutingModule,
        StoreModule.forFeature('core', reducer),
        EffectsModule.forFeature([CoreEffects])
    ],
    declarations: [
        LoginPageComponent,
        LogoutPageComponent,
        AppHeaderComponent,
        UserAvatarComponent,
        HomePageComponent,
        MenuStickerComponent,
        SideMenuComponent,
        ErrorPageComponent,
        ClientFormPageComponent,
        ClientsListPageComponent,
        ClientsListComponent,
        RoleFormPageComponent,
        RolesListPageComponent,
        RolesListComponent,
        SelectPipe,
        AnimateListPipe,
        BooleanPipe,
        FileSizePipe,
        ApplicationsListPageComponent,
        ApplicationsListComponent,
        ApplicationFormPageComponent,
        ApplicationUsersPageComponent,
        NotificationComponent,
        UnitsListPageComponent,
        UnitsListComponent,
        UnitFormPageComponent,
        UnitUserAssignmentPageComponent,
        UsersListPageComponent,
        UsersListComponent,
        UserFormPageComponent,
        UserDetailsPageComponent,
        ModalComponent,
        ActivatePageComponent,
        ApplicationsListSmallComponent,
        SmallUnitItemComponent,
        SmallUnitsListComponent,
        HintComponent,
        SmallApplicationItemComponent,
        AuthPipe,
        ImageInputSimpleComponent,
        SwitchCasesDirective,
        RawHtmlPipe,
        ProfilePageComponent,
    ],
    exports: [AppHeaderComponent, SideMenuComponent, ErrorPageComponent, NotificationComponent, SelectPipe, FileSizePipe,
        AnimateListPipe, BooleanPipe, ModalComponent, ClientsListComponent, ApplicationsListSmallComponent, SmallUnitItemComponent,
        SmallUnitsListComponent, HintComponent, AuthPipe, ImageInputSimpleComponent, SwitchCasesDirective, RawHtmlPipe]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [
                ApplicationUserService, AuthService, EnvironmentService, LocalStorageService, ClientService,
                ApplicationService, UnitService, UserService, AuthGuardService, NavigationService, ModalService,
                SocketService, WindowRefService, RoleService, DistributedConfigService,
            ]
        };
    }
}
