import * as i0 from "@angular/core";
function _window() {
    // return the global native browser window object
    return window;
}
export class WindowRefService {
    get nativeWindow() {
        return _window();
    }
    reload(force = false) {
        // https://github.com/Microsoft/TypeScript/issues/28898
        // tslint:disable-next-line: deprecation
        _window().location.reload(force);
    }
}
WindowRefService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WindowRefService_Factory() { return new WindowRefService(); }, token: WindowRefService, providedIn: "root" });
