/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hint.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hint.component";
import * as i4 from "@ngrx/store";
var styles_HintComponent = [i0.styles];
var RenderType_HintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HintComponent, data: {} });
export { RenderType_HintComponent as RenderType_HintComponent };
function View_HintComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint.header; _ck(_v, 1, 0, currVal_0); }); }
function View_HintComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "zmdi zmdi-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_HintComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HintComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(2, null, ["\n", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HintComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.hint; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.hint ? _co.hint.message : ""); _ck(_v, 2, 0, currVal_1); }); }
export function View_HintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-hint", [], null, null, null, View_HintComponent_0, RenderType_HintComponent)), i1.ɵdid(1, 114688, null, 0, i3.HintComponent, [i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HintComponentNgFactory = i1.ɵccf("wf-hint", i3.HintComponent, View_HintComponent_Host_0, {}, {}, []);
export { HintComponentNgFactory as HintComponentNgFactory };
