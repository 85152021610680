import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Modal } from '@wildflowerhealth/console-shared';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { WfValidators } from '../../validators/WfValidator';

@Component({
    selector: 'wf-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit {

    @Input() modal: Modal;
    public form: FormGroup;

    constructor(
        public modalService: ModalService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.compose([Validators.required, WfValidators.minEightChars, WfValidators.numberPresent, WfValidators.letterPresent])],
            passwordConfirm: ['', Validators.compose([Validators.required, this.validateEquality])]
        });
    }

    public readonly validateEquality = (control: AbstractControl) => {
        const result = this.form ? control.value !== this.form.controls['password'].value : false;
        return result ? { notEqual: result } : null;
    }

    confirm() {
        this.modalService.confirmModal(this.modal.isPassword() ? this.form.controls['password'].value : null);
    }

}
