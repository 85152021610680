import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { map } from 'rxjs/operators';
import { Unit } from '@wildflowerhealth/console-shared';

@Injectable()
export class UnitService {

    private API_URL = this.environmentService.getAPIUrl() + '/units';

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    fetch(): Observable<List<Unit>> {
        return this.http
            .get<any[]>(this.API_URL)
            .pipe(map(res => List(res)
                .map(item => new Unit(item))
                .toList()
            ));
    }

    deleteUnit(unit: Unit) {
        return this.http.delete(this.API_URL + `/${unit.id}`);
    }

    createNewUnit(unit: Unit) {
        return this.http.post(this.API_URL, unit.toJSON());
    }

    updateUnit(unit: Unit) {
        return this.http.put(this.API_URL, unit.toJSON());
    }

    setUsers(unit: Unit, userIds: List<string>) {
        return this.http.post(`${this.API_URL}/set-users/${unit.id}`, { userIds: userIds.toJS() });
    }

    setEligibilityConfiguration(unit: Unit, eligibilityConfigurationId: string) {
        return this.http
            .put(`${this.API_URL}/set-eligibility-configuration/${unit.id}/${eligibilityConfigurationId}`, {});
    }

    removeEligibilityConfiguration(unit: Unit) {
        return this.http
            .delete(`${this.API_URL}/remove-eligibility-configuration/${unit.id}`, {});
    }

    setContentBundle(unit: Unit, contentBundleId: string) {
        return this.http
            .put(`${this.API_URL}/set-content-bundle/${unit.id}/${contentBundleId}`, {});
    }

    removeContentBundle(unit: Unit) {
        return this.http
            .delete(`${this.API_URL}/remove-content-bundle/${unit.id}`, {});
    }

}
