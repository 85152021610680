<div class="applications-list" *ngIf="applications">
    <div class="application" *ngFor="let application of applications" [@flyIn]="'in'"
        [ngClass]="{'selected': isApplicationSelected(application) }"
        (click)="isApplicationSelected(application) ? null : select.next(application)">

        <wf-image-icon [image]="application.logo" icon="smartphone-iphone" [size]="4" wfRound radius="0.5em">
        </wf-image-icon>

        <div class="info">
            <div class="short-name">{{application.name}}</div>
            <div class="full-name"><i class="zmdi zmdi-label"></i>{{application.fullName}}</div>
            <div class="bundle-id"><i class="zmdi zmdi-wrench"></i>{{application.bundleId}}</div>
        </div>

        <div class="inline-button edit-button" (click)="editClick(application);$event.stopPropagation();">
            <i class="zmdi zmdi-edit"></i>
        </div>
        <div class="inline-button users-button" (click)="usersClick(application)">
            <i class="zmdi zmdi-accounts-alt"></i>
        </div>
        <div class="inline-button delete-button" (click)="deleteClick(application);$event.stopPropagation();">
            <i class="zmdi zmdi-delete"></i>
        </div>
    </div>
</div>
