import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../global/global.state';
import { ClearHint } from '../../store/core.actions';
import { Hint } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit {

    public hint: Hint;

    constructor(private store: Store<GlobalState>) {
    }

    ngOnInit() {
        this.store
            .select(store => store.core.data.hint)
            .subscribe((hint: Hint) => this.hint = hint);
    }

    close() {
        this.store.dispatch(new ClearHint());
    }

}
