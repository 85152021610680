import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'wf-tile-header',
    templateUrl: './tile-header.component.html',
    styleUrls: ['./tile-header.component.scss']
})
export class TileHeaderComponent {
    @Input() label: string = null;
    @Input() icon: string = null;
    @Input() showCloseButton = false;
    @Output() close = new EventEmitter<any>();
}
