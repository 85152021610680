/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./users-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../ui-components/image-icon/image-icon.component.ngfactory";
import * as i4 from "../../../ui-components/image-icon/image-icon.component";
import * as i5 from "../../../ui-components/round.directive";
import * as i6 from "./users-list.component";
var styles_UsersListComponent = [i0.styles];
var RenderType_UsersListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UsersListComponent, data: { "animation": [{ type: 7, name: "flyIn", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, offset: 1 }, offset: null }] }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_UsersListComponent as RenderType_UsersListComponent };
function View_UsersListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "stub"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no users for selected client"]))], null, null); }
function View_UsersListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "not-active"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" NOT ACTIVE "]))], null, null); }
function View_UsersListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "user horizontal-group"]], [[24, "@flyIn", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select.next(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "not-active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "wf-image-icon", [["icon", "account"], ["wfRound", ""]], null, null, null, i3.View_ImageIconComponent_0, i3.RenderType_ImageIconComponent)), i1.ɵdid(5, 114688, null, 0, i4.ImageIconComponent, [], { image: [0, "image"], size: [1, "size"], icon: [2, "icon"] }, null), i1.ɵdid(6, 81920, null, 0, i5.RoundDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "email"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersListComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = "user horizontal-group"; var currVal_2 = _ck(_v, 3, 0, !_v.context.$implicit.active); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.avatar; var currVal_4 = 4; var currVal_5 = "account"; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); _ck(_v, 6, 0); var currVal_8 = !_v.context.$implicit.active; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); var currVal_6 = _v.context.$implicit.getFullName(); _ck(_v, 9, 0, currVal_6); var currVal_7 = _v.context.$implicit.email; _ck(_v, 11, 0, currVal_7); }); }
function View_UsersListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "users-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersListComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UsersListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersListComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UsersListComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.users && (_co.users.size === 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.users && (_co.users.size !== 0)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UsersListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-users-list", [], null, null, null, View_UsersListComponent_0, RenderType_UsersListComponent)), i1.ɵdid(1, 49152, null, 0, i6.UsersListComponent, [], null, null)], null, null); }
var UsersListComponentNgFactory = i1.ɵccf("wf-users-list", i6.UsersListComponent, View_UsersListComponent_Host_0, { users: "users" }, { select: "select" }, []);
export { UsersListComponentNgFactory as UsersListComponentNgFactory };
