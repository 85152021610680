import { Actions, ofType } from '@ngrx/effects';
import { of, OperatorFunction, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class BaseEffects {
    constructor(protected actions: Actions) {}

    /**
     * prevent effects dying after a failure
     * https://medium.com/city-pantry/handling-errors-in-ngrx-effects-a95d918490d9
     */
    protected catchEffect<TRet extends Action, TErr extends Action>(
        actionType: string | string[],
        effect: OperatorFunction<Action, TRet>,
        handler: (err: any, caught: Observable<TRet>) => Observable<TErr>,
    ): Observable<TRet | TErr> {
        if (typeof actionType === 'string') {
            actionType = [actionType];
        }
        return this.actions.pipe(
            ofType(...actionType),
            mergeMap<Action, Observable<TRet | TErr>>(action => of(action).pipe(
                effect,
                catchError(handler),
            )),
        );
    }
}
