<div class="dropdown" (click)="toggleMenu()">
    <ul class="menu tile" *ngIf="isMenuShown">
        <li class="item"
            [ngClass]="{separated: item.isSeparated}"
            *ngFor="let item of items;"
            (click)="item.callback()">
            <i *ngIf="item.icon" class="zmdi zmdi-{{item.icon}}"></i>
            {{item.label}}
        </li>
    </ul>
</div>
