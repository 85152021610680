<div class="tile">
    <wf-tile-header [label]="title" [icon]="'account-add'" [showCloseButton]="true" (close)="close()"></wf-tile-header>

    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
        <div class="horizontal-group">
            <wf-image-input [size]="6" formControlName="logo"></wf-image-input>
            <div class="inputs">
                <wf-input [label]="'Full Name*'" formControlName="fullName"></wf-input>
                <wf-input [label]="'Short Name*'" formControlName="name"></wf-input>
                <wf-input [label]="'Bundle ID*'" formControlName="bundleId"></wf-input>
                <wf-select [label]="'Product*'" [options]="productOptions" formControlName="productConst"></wf-select>
                <wf-input [label]="'iTunes ID'" formControlName="iTunesID"></wf-input>
                <wf-input [label]="'App Host Name'" formControlName="appHostName"></wf-input>
                <div formArrayName="appDnsNames" class="dns-names-list">
                    <div class="dns-name" *ngFor="let dnsName of form.get('appDnsNames').controls; let i = index"
                         [formGroupName]="i">

                        <wf-input [displayErrors]="true" formControlName="appDnsName" [label]="'App DNS Name'"></wf-input>

                        <wf-select label="WFH Env" formControlName="wfhEnv" [options]="wfhEnvOptions">
                        </wf-select>

                        <div class="buttons-container">
                            <button type="button" class="reset-style-button delete-button"  aria-label="Remove/clear"
                                    (click)="removeOrClearEmail(i)" >
                                -
                            </button>

                            <button type="button"
                                    class="reset-style-button add-button"
                                    (click)="addAppDnsNameFormGroup()">
                                +
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="controls">
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
        </div>
    </form>

    <wf-spinner *ngIf="store | select: 'core.data.isApplicationSaving' | async"></wf-spinner>
</div>
