import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { DeleteUser, ActivateAction } from '../../store/core.actions';
import { List } from 'immutable';
import { ModalService } from '../../services/modal.service';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DataState } from '../../store/core.states';
import { User, Client, AuthorizationResource, AuthorizationAction } from '@wildflowerhealth/console-shared';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
    selector: 'wf-user-details-page',
    templateUrl: './user-details-page.component.html',
    styleUrls: ['./user-details-page.component.scss']
})
export class UserDetailsPageComponent implements OnInit {

    public user: User;
    public client: Client;
    public permissionsList: List<{ sectionName: string, permissions: List<{ label: string, data: string }> }>;
    private activeUser: User;
    private activeClient: Client;

    isSuperClient = false;
    isUserAuthorized = this.localStore.hasAuthorization(AuthorizationAction.ReadWrite, AuthorizationResource.ConsoleUsers);


    constructor(private activatedRoute: ActivatedRoute,
        public store: Store<GlobalState>,
        private router: Router,
        private modalService: ModalService,
        private localStore: LocalStorageService) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'])),
            this.store.select(s => s.core.data),
        ]).subscribe((data: [string, DataState]) => {
            this.user = data[1].users.find(user => user.id === data[0]);
            this.client = this.user
                ? data[1].clients.find(client => client.id === this.user.clientId)
                : null;

            this.activeUser = data[1].activeUser;
            this.activeClient = data[1].clients.find(c => c.id === this.activeUser.clientId);
            this.isSuperClient = this.activeClient.isSuper;
        });
    }

    edit() {
        this.router.navigate(['/configuration/users/form', { id: this.user.id }]);
    }

    delete() {
        this.modalService
            .showDeleteConfirmation(`Are you sure want to disable user "${this.user.getFullName()}" ?`, 'Disable')
            .subscribe(() => this.store.dispatch(new DeleteUser({ user: this.user })));
    }

    enable() {
        this.modalService
            .showPasswordReset(`Provide a new password for user "${this.user.getFullName()}"`)
            .subscribe(password => this.store.dispatch(new ActivateAction({ userId: this.user.id, password })));
    }

    close() {
        this.router.navigate(['/configuration/users']);
    }

    canEditUsers(): boolean {
        return this.activeClient.isSuper && this.localStore.hasAuthorization(AuthorizationAction.ReadWrite, AuthorizationResource.ConsoleUsers);
    }
}
