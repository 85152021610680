import { Component, OnInit } from '@angular/core';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DeleteRole, SelectRole } from '../../store/core.actions';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { map } from 'rxjs/operators';
import { Role, Authorization, AuthorizationAction, AuthorizationResource } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-roles-list-page',
    templateUrl: './roles-list-page.component.html',
    styleUrls: ['./roles-list-page.component.scss']
})
export class RolesListPageComponent implements OnInit {

    isEditMode: Observable<boolean>;

    constructor(public store: Store<GlobalState>, private router: Router, private modalService: ModalService) {
    }

    ngOnInit() {
        this.isEditMode = this.store
            .select(state => state.core.data.activeUserAuthorizations)
            .pipe(map(auths => Authorization.isAuthorized(auths, AuthorizationAction.ReadWrite, AuthorizationResource.ConsoleRoles)));
    }

    create() {
        this.router.navigate(['/configuration/roles/form']);
    }

    select(role: Role) {
        this.store.dispatch(new SelectRole({ selectedRole: role }));
    }

    edit(role: Role) {
        this.router.navigate(['/configuration/roles/form', { id: role.id }]);
    }

    delete(role: Role) {
        this.modalService
            .showDeleteConfirmation(`Are you sure want to delete role "${role.name}" ? All related units and users will be deleted.`)
            .subscribe(() => this.store.dispatch(new DeleteRole({ role })));
    }
}
