import { List } from 'immutable';
import { MenuSection } from './MenuSection';
import { AuthorizationAction, AuthorizationResource } from '@wildflowerhealth/console-shared';

export const MENU_CONFIGURATION: List<MenuSection> = List([
    {
        label: 'Eligibility',
        icon: 'lock-outline',
        menuItems: List([
            {
                label: 'Access Codes',
                uri: '/eligibility/access-codes',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Eligibility }],
            },
            {
                label: 'History',
                uri: '/eligibility/history',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.EligibilityHistoryPHI }],
            },
            {
                label: 'Eligibility Updates',
                uri: '/eligibility/updates',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Eligibility }],
            },
            {
                label: 'Configuration',
                uri: '/eligibility/units-to-eligibility-configuration-list',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.EligibilityConfig }],
            },
        ])
    },
    {
        label: 'Version Control',
        icon: 'layers',
        menuItems: List([
            {
                label: 'Promotion Requests',
                uri: '/vcs/promotion-requests',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.AppVersionsPrivate }],
            },

            {
                label: 'CDN Servers State',
                uri: '/vcs/cdn-configs-list',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.AppVersionsPrivate }],
            }
        ])
    },
    {
        label: 'Reporting',
        icon: 'trending-up',
        menuItems: List([
            {
                label: 'New Users Registrations',
                uri: '/reporting/registrations',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Reports }],
            },
            {
                label: 'Member Level Reports',
                uri: '/reporting/member-level-reports',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Reports }],
           },
            {
                label: 'Monthly Reports',
                uri: '/reporting/monthly-reports-landing',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Reports }],
            }
        ])
    },
    {
        label: 'Content Management',
        icon: 'widgets',
        menuItems: List([
            {
                label: 'Content Layers',
                uri: '/cms/content-layers-list',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Content }],
            },
            {
                label: 'Content Tags',
                uri: '/cms/content-tags-list',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Content }],
            },
            {
                label: 'Content Bundles',
                uri: '/cms/content-bundles-list',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Content }],
            },
            {
                label: 'Content Bundles and Units',
                uri: '/cms/units-bundles-configurations',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Content }],
            },
            {
                label: 'Import Content',
                uri: '/cms/import-content',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Content }],
            },
        ])
    },
    {
        label: 'Applications',
        icon: 'smartphone-iphone',
        menuItems: List([
            {
                label: 'Clients',
                uri: '/configuration/clients',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Clients }],
            },
            {
                label: 'Applications',
                uri: '/configuration/applications',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Applications }],
            },
            {
                label: 'Units',
                uri: '/configuration/units',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.Applications }],
            },
        ])
    },
    {
        label: 'Console Configuration',
        icon: 'settings',
        menuItems: List([
            {
                label: 'Users',
                uri: '/configuration/users',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.ConsoleUsers }],
            },
            {
                label: 'Roles',
                uri: '/configuration/roles',
                authorizations: [{ action: AuthorizationAction.Read, resource: AuthorizationResource.ConsoleRoles }],
            },
        ])
    },
]);
