<div class="header-top">
    <div class="menu-item" (click)="toggleNavigationMenu()" wfHighlightOnHover>
        <i class="zmdi zmdi-menu"></i>
    </div>
    <div class="header-logo" (click)="goHome()">
        <div class="logo"></div>
    </div>
    <div class="dashboard-version">
        <span *ngIf="banner.enabled" [ngStyle]="banner.style">{{ banner.label }}</span>
        v{{ version }} / v{{ serverVersion$ | async }}
    </div>
    <div class="user">
        <wf-image-icon *ngIf="(activeUser | async).avatar; else userInitials"
                       [image]="(activeUser | async).avatar"
                       [size]="3" wfRound></wf-image-icon>
        <ng-template #userInitials>
            <wf-user-initials [user]="(activeUser | async)"></wf-user-initials>
        </ng-template>
        <div class="name">
            {{(activeUser | async).getFullName()}}
        </div>
        <wf-dropdown [items]="dropDownItems"></wf-dropdown>
    </div>
</div>
