<div class="image-input" (click)="openUploader($event)" [style.width]="size + 'em'">
    <img *ngIf="image" [src]="image" [style.font-size]="size + 'em'" [ngClass]="{'round': isRound }">
    <div class="stub" *ngIf="!image && displayImagePlaceholder" [style.font-size]="size + 'em'" [ngClass]="{'round': isRound }">
        <div class="text">Upload Image</div>
    </div>
    <div class="controls">
        <button class="btn btn-warning" [style.font-size]="size/10 + 'em'"
            (click)="$event.preventDefault()">Change</button>
    </div>
    <wf-image-uploader (imageChanged)="handleImageChanged($event)"
                       (uploaderClosed)="isUploaderShown = false;"
                       [image]="image"
                       [isRound]="isRound"
                       [isUploaderShown]="isUploaderShown"></wf-image-uploader>
</div>
