<div class="page-wrapper">
    <h3>User Details:</h3>
    <div class="user-details tile">
        <div class="user-avatar">
            <button *ngIf="activeUser.avatar"
                    (click)="handleDeleteAvatar()"
                    class="reset-style-button delete-avatar-button">
                <i class="zmdi zmdi-close-circle-o"></i>
            </button>
            <wf-image-icon *ngIf="activeUser.avatar; else userInitials"
                           [image]="activeUser.avatar"
                           [size]="5" wfRound></wf-image-icon>
            <ng-template #userInitials>
                <wf-user-initials [user]="activeUser"></wf-user-initials>
            </ng-template>

            <wf-image-uploader (imageChanged)="handleImageChanged($event)"
                               (uploaderClosed)="isUploaderShown = false;"
                               [image]="activeUser?.avatar"
                               [isRound]="true"
                               [isUploaderShown]="isUploaderShown">
            </wf-image-uploader>
        </div>


        <div class="user-details-text">
            <p>Full Name: <span>{{activeUser?.firstName}} {{activeUser?.lastName}}</span></p>
            <p>Client: <span>{{activeClient?.name}}</span></p>
            <p>Email: <span>{{activeUser?.email}}</span></p>
        </div>

        <button class="change-avatar-button reset-style-button" (click)="isUploaderShown = true;">
            Change Avatar
        </button>
    </div>

    <wf-change-password></wf-change-password>

</div>

<wf-spinner [hidden]="!isPasswordChangingInProgress" [label]="'Changing in progress...'"></wf-spinner>

