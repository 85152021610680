import { JwtInterceptor } from '@auth0/angular-jwt';
import { HttpRequest } from '@angular/common/http';
import * as URL from 'url';

// https://github.com/auth0/angular2-jwt/issues/481
if (JwtInterceptor.prototype['_isWhitelistedDomain'] == null) {
    JwtInterceptor.prototype['_isWhitelistedDomain'] = JwtInterceptor.prototype.isWhitelistedDomain;

    // send tokens to null host (the server that originated the app)
    JwtInterceptor.prototype.isWhitelistedDomain = function isWhitelistedDomain(request: HttpRequest<any>): boolean {
        const requestUrl = URL.parse(request.url, false, true);
        if (requestUrl.host === null) {
            return true;
        }
        return this['_isWhitelistedDomain'](request);
    };
}
