<div class="tile">
    <wf-tile-header [icon]="'account-circle'" [label]="'User Management & Permission Settings'"></wf-tile-header>
    <div class="horizontal-group">

        <wf-clients-list [clients]="store | select:'core.data.clients' | animateList | async"
            [selectedClient]="store | select:'core.data.selectedClient' | async" [viewSelectMode]="true"
            (select)="selectClient($event)">
        </wf-clients-list>

        <div class="vertical-divider"></div>

        <div class="users-list-container">
            <wf-users-list [users]="users | async" (select)="selectUser($event)"></wf-users-list>
            <div class="controls controls-right" *ngIf="isEditMode | async">
                <button class="btn btn-primary btn-action" (click)="create()">
                    <i class="zmdi zmdi-plus"></i>
                </button>
            </div>
        </div>

    </div>
</div>
