<div class="tile">
    <wf-tile-header [icon]="'city'" [label]="'Clients'"></wf-tile-header>
    <wf-clients-list [clients]="store | select:'core.data.clients' | animateList | async"
        [selectedClient]="store | select:'core.data.selectedClient' | async" (select)="select($event)"
        (edit)="edit($event)" (delete)="delete($event)"></wf-clients-list>

    <div class="controls controls-right" *ngIf="isEditMode | async">
        <button class="btn btn-primary btn-action" (click)="create()">
            <i class="zmdi zmdi-plus"></i>
        </button>
    </div>

    <wf-spinner *ngIf="store | select: 'core.data.isClientDeletion' | async"></wf-spinner>
</div>
