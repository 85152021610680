import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { GlobalState } from '../../../global/global.state';
import { MenuSection } from '../../constants/MenuSection';

@Component({
    selector: 'wf-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    menu: Observable<List<MenuSection>>;

    constructor(private store: Store<GlobalState>) {
    }

    ngOnInit() {
        this.menu = this.store.select(s => s.core.data.menu);
    }

}
