import { Component, OnInit } from '@angular/core';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DeleteClient, SelectClient } from '../../store/core.actions';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { map } from 'rxjs/operators';
import { Client, Authorization, AuthorizationAction, AuthorizationResource } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-clients-list-page',
    templateUrl: './clients-list-page.component.html',
    styleUrls: ['./clients-list-page.component.scss']
})
export class ClientsListPageComponent implements OnInit {

    isEditMode: Observable<boolean>;

    constructor(public store: Store<GlobalState>, private router: Router, private modalService: ModalService) {
    }

    ngOnInit() {
        this.isEditMode = this.store
            .select(state => state.core.data.activeUserAuthorizations)
            .pipe(map(auths => Authorization.isAuthorized(auths, AuthorizationAction.ReadWrite, AuthorizationResource.Clients)));
    }

    create() {
        this.router.navigate(['/configuration/clients/form']);
    }

    select(client: Client) {
        this.store.dispatch(new SelectClient({ selectedClient: client }));
    }

    edit(client: Client) {
        this.router.navigate(['/configuration/clients/form', { id: client.id }]);
    }

    delete(client: Client) {
        this.modalService
            .showDeleteConfirmation(`Are you sure want to delete client "${client.name}" ? All related units and users will be deleted.`)
            .subscribe(() => this.store.dispatch(new DeleteClient({ client })));
    }
}
