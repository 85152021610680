import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { IOption, Unit, Application, Client } from '@wildflowerhealth/console-shared';
import { CoreOptionsHelper } from '../../helpers/OptionsHelper';
import { SegmentOptions } from '../../constants/SegmentOptions';
import { Product } from '../../constants/Product';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SaveUnit } from '../../store/core.actions';
import { take } from 'rxjs/operators';

@Component({
    selector: 'wf-unit-form-page',
    templateUrl: './unit-form-page.component.html',
    styleUrls: ['./unit-form-page.component.scss']
})
export class UnitFormPageComponent implements OnInit {

    private unit: Unit;
    public title: string;
    public form: FormGroup;
    private clientOptions: List<IOption>;
    private applicationOptions: List<IOption>;
    private segmentOptions = SegmentOptions;
    private fullName: BehaviorSubject<string>;

    constructor(private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        public store: Store<GlobalState>,
        private router: Router) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params,
            this.store.select(store => store.core.data.units),
            this.store.select(store => store.core.data.applications),
            this.store.select(store => store.core.data.clients)
        ])
            .subscribe((data: [Params, List<Unit>, List<Application>, List<Client>]) => {
                const [params, units, applications, clients] = data;

                this.unit = units.find(unit => unit.id === params['id']) || null;
                this.clientOptions = CoreOptionsHelper.getClients(clients);
                this.applicationOptions = CoreOptionsHelper.getApplications(applications);
                this.title = this.unit ? 'Edit Unit' : 'Add new Unit';

                this.form = this.formBuilder.group({
                    name: [this.unit ? this.unit.name : '', Validators.required],
                    segmentConst: [this.unit ? this.unit.segmentConst : '', Validators.required],
                    applicationId: [this.unit ? this.unit.applicationId : params['selectedApplicationId'] || '', Validators.required],
                    clientId: [this.unit ? this.unit.clientId : '', Validators.required],
                });

                this.fullName = new BehaviorSubject(this.getFullName(this.form.value, clients, applications));

                this.form.valueChanges
                    .subscribe((formValue: any) => this.fullName.next(this.getFullName(formValue, clients, applications)));
            });
    }

    getFullName(formValue: any, clients: List<Client>, applications: List<Application>): string {
        const selectedApplication = applications.find(application => application.id === formValue['applicationId']);
        const selectedClient = clients.find(client => client.id === formValue['clientId']);

        const productName = selectedApplication ? Product[selectedApplication.productConst as number] : '<product>';
        const clientName = selectedClient ? selectedClient.name.replace(/\s/g, '') : '<client>';
        const postfix = formValue['name'] || '<postfix>';

        return `${productName}.${clientName}.${postfix}`;
    }

    save() {
        this.fullName
            .pipe(take(1))
            .subscribe((fullName: string) => {
                this.store.dispatch(new SaveUnit({
                    unit: this.unit
                        ? this.unit.fillFromForm(this.form.value)
                        : new Unit().fillFromForm(this.form.value).setName(fullName)
                }));
            });
    }


    close() {
        this.router.navigate(['/configuration/units']);
    }

}
