import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { List } from 'immutable';
import { slideFromRightListAnimation } from '../../core.animations';
import { Client } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-clients-list',
    templateUrl: './clients-list.component.html',
    animations: slideFromRightListAnimation,
    styleUrls: ['./clients-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsListComponent {

    @Input() clients: List<Client>;
    @Input() selectedClient: Client;
    @Input() viewSelectMode = false;
    @Output() select: EventEmitter<Client> = new EventEmitter<Client>();
    @Output() edit: EventEmitter<Client> = new EventEmitter<Client>();
    @Output() delete: EventEmitter<Client> = new EventEmitter<Client>();

    clientStyles(client: Client): string {
        return List([this.selectedClient && this.selectedClient.id === client.id ? 'selected' : ''])
            .push(client.isSuper ? 'super' : '')
            .join(' ');
    }

    isClientSelected(client: Client): boolean {
        return this.selectedClient && this.selectedClient.id === client.id;
    }

    editClick(client: Client): void {
        this.selectedClient && this.selectedClient.id === client.id
            ? this.edit.next(client)
            : this.select.next(client);
    }

    deleteClick(client: Client): void {
        this.selectedClient && this.selectedClient.id === client.id
            ? this.delete.next(client)
            : this.select.next(client);
    }
}
