/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./units-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../ui-components/image-icon/image-icon.component.ngfactory";
import * as i4 from "../../../ui-components/image-icon/image-icon.component";
import * as i5 from "../../../ui-components/round.directive";
import * as i6 from "./units-list.component";
var styles_UnitsListComponent = [i0.styles];
var RenderType_UnitsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnitsListComponent, data: { "animation": [{ type: 7, name: "flyIn", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateX(50%) translateZ(0)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateX(-15px) translateZ(0)", offset: 0.7 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateX(0) translateZ(0)", offset: 1 }, offset: null }] }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_UnitsListComponent as RenderType_UnitsListComponent };
function View_UnitsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "unit horizontal-group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selectUnit.next(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "connection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "unit-content horizontal-group"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "selected": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "zmdi zmdi-collection-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "client"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "zmdi zmdi-city"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "segment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "zmdi zmdi-chart-donut"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "inline-button edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editUnitClick(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "zmdi zmdi-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "inline-button delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.deleteUnitClick(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "zmdi zmdi-delete"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "inline-button assign"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.manageUsersClick(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "zmdi zmdi-accounts"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "unit-content horizontal-group"; var currVal_1 = _ck(_v, 5, 0, (_co.selectedUnit && (_v.context.$implicit.id === _co.selectedUnit.id))); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.getClientName(_v.context.$implicit); _ck(_v, 13, 0, currVal_3); var currVal_4 = _v.context.$implicit.getSegmentName(); _ck(_v, 16, 0, currVal_4); }); }
function View_UnitsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "units"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitsListComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getUnits(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_UnitsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "application-unit"]], [[24, "@flyIn", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectApplication.next(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "application horizontal-group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectApplication.next(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "wf-image-icon", [["icon", "smartphone-iphone"], ["radius", "0.5em"], ["wfRound", ""]], null, null, null, i3.View_ImageIconComponent_0, i3.RenderType_ImageIconComponent)), i1.ɵdid(6, 114688, null, 0, i4.ImageIconComponent, [], { image: [0, "image"], size: [1, "size"], icon: [2, "icon"] }, null), i1.ɵdid(7, 81920, null, 0, i5.RoundDirective, [i1.ElementRef], { radius: [0, "radius"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "bundle-id"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "inline-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.addUnitClick(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "zmdi zmdi-plus-circle-o"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitsListComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "application horizontal-group"; var currVal_2 = _ck(_v, 4, 0, (_co.selectedApplication && (_v.context.$implicit.id === _co.selectedApplication.id))); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.logo; var currVal_4 = 4; var currVal_5 = "smartphone-iphone"; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "0.5em"; _ck(_v, 7, 0, currVal_6); var currVal_9 = (_co.getUnits(_v.context.$implicit).size > 0); _ck(_v, 16, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); var currVal_7 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_7); var currVal_8 = _v.context.$implicit.bundleId; _ck(_v, 12, 0, currVal_8); }); }
function View_UnitsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "application-unit-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitsListComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applications; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UnitsListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnitsListComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.clients && _co.applications); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UnitsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-units-list", [], null, null, null, View_UnitsListComponent_0, RenderType_UnitsListComponent)), i1.ɵdid(1, 49152, null, 0, i6.UnitsListComponent, [], null, null)], null, null); }
var UnitsListComponentNgFactory = i1.ɵccf("wf-units-list", i6.UnitsListComponent, View_UnitsListComponent_Host_0, { units: "units", clients: "clients", applications: "applications", selectedUnit: "selectedUnit", selectedApplication: "selectedApplication" }, { selectUnit: "selectUnit", selectApplication: "selectApplication", addUnit: "addUnit", editUnit: "editUnit", deleteUnit: "deleteUnit", manageUsers: "manageUsers" }, []);
export { UnitsListComponentNgFactory as UnitsListComponentNgFactory };
