import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import {
    ActivateAction,
    ActivateFailureAction,
    ActivateSuccessAction,
    AssignUsersToUnit,
    AssignUsersToUnitFailure,
    AssignUsersToUnitSuccess,
    CheckAuthorizationFailure,
    CheckAuthorizationSuccess,
    CoreActionTypes,
    DeleteApplication,
    DeleteApplicationFailure,
    DeleteApplicationSuccess,
    DeleteClient,
    DeleteClientFailure,
    DeleteClientSuccess,
    DeleteUnit,
    DeleteUnitFailure,
    DeleteUnitSuccess,
    DeleteUser,
    DeleteUserFailure,
    DeleteUserSuccess,
    InitializationMenuSuccessAction,
    InitializeApplicationAction,
    InitializeApplicationFailureAction,
    InitializeApplicationSuccessAction,
    InitializeMenuAction,
    LoadUnitsFailure,
    LoadUnitsSuccess,
    NavigateAfterSignIn,
    NavigateAfterSignInDone,
    SaveApplication,
    SaveApplicationFailure,
    SaveApplicationSuccess,
    SaveClient,
    SaveClientFailure,
    SaveClientSuccess,
    SaveUnit,
    SaveUnitFailure,
    SaveUnitSuccess,
    SaveUser,
    SaveUserFailure,
    SaveUserSuccess,
    SetActiveUser,
    ShowErrorNotification,
    ShowSuccessNotification,
    SignInAction,
    SignInFailureAction,
    SignInSuccessAction,
    ShowSuccessCreatedUpdatedNotification,
    SaveRole,
    SaveRoleSuccess,
    SaveRoleFailure,
    DeleteRole,
    DeleteRoleSuccess,
    DeleteRoleFailure,
    ChangeUserPassword,
    ChangeUserPasswordFailure,
    ChangeUserPasswordSuccess,
    ChangeUserAvatar,
    ChangeUserAvatarSuccess, ChangeUserAvatarFailure,
} from './core.actions';
import { of, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { ClientService } from '../services/client.service';
import { UserService } from '../services/user.service';
import { UnitService } from '../services/unit.service';
import { ApplicationService } from '../services/application.service';
import { List } from 'immutable';
import { HttpHelper } from '../../global/helpers/HttpHelper';
import { MenuHelper } from '../helpers/MenuHelper';
import { GlobalState } from '../../global/global.state';
import { Store, Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { SocketService } from '../services/socket.service';
import { HttpErrorResponse } from '@angular/common/http';
import { switchMap, map, tap, flatMap, first } from 'rxjs/operators';
import { Client, User, Application, Unit, Authorization, Role, IDistributedConfig } from '@wildflowerhealth/console-shared';
import { BaseEffects } from './base.effects';
import { RoleService } from '../services/role.service';
import { DistributedConfigService } from '../services/distributed-config.service';

@Injectable()
export class CoreEffects extends BaseEffects {
    constructor(
        actions: Actions,
        private readonly authService: AuthService,
        private readonly localStorageService: LocalStorageService,
        private readonly clientService: ClientService,
        private readonly userService: UserService,
        private readonly roleService: RoleService,
        private readonly unitService: UnitService,
        private readonly applicationService: ApplicationService,
        private readonly store: Store<GlobalState>,
        private readonly router: Router,
        private readonly socketService: SocketService,
        private readonly distCfgSvc: DistributedConfigService,
    ) {
        super(actions);
    }

    @Effect() signIn = this.catchEffect(
        CoreActionTypes.SIGNIN,
        actions => actions.pipe(
            switchMap((action: SignInAction) => this.authService
                .singIn(action.payload.email, action.payload.password)),
            map(res => new SignInSuccessAction(res)),
        ),
        (err: HttpErrorResponse) => of(
            new SignInFailureAction(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() signInSuccess = this.actions.pipe(
        ofType(CoreActionTypes.SIGNIN_SUCCESS),
        tap((action: SignInSuccessAction) => {
            this.localStorageService.setToken(action.payload.token);
            this.localStorageService.setUserId(action.payload.userId);
            this.localStorageService.setAuthorizations(action.payload.authorizations);
            this.router.navigate(['/home']);
        }),
        map(() => new InitializeApplicationAction()),
    );


    @Effect() initializeApplication = this.catchEffect(
        CoreActionTypes.APP_INITIALIZE,
        actions => actions.pipe(
            switchMap(() => zip<[List<Client>, List<User>, List<Role>, List<Application>, List<Unit>, IDistributedConfig]>(
                this.clientService.fetch(),
                this.userService.fetch(),
                this.roleService.fetch(),
                this.applicationService.fetch(),
                this.unitService.fetch(),
                this.distCfgSvc.fetch(),
                this.socketService.initSocket(this.localStorageService.getToken()),
            )),
            flatMap(data => {
                const [clients, users, roles, applications, units, distCfg] = data;
                return [
                    new InitializeApplicationSuccessAction({ clients, users, roles, applications, units, distCfg }),
                    new InitializeMenuAction(),
                    new NavigateAfterSignIn()
                ];
            }),
        ),
        (err: HttpErrorResponse) => of(
            new InitializeApplicationFailureAction(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() initializeMenu = this.actions.pipe(
        ofType(CoreActionTypes.MENU_INITIALIZE),
        map(() => new InitializationMenuSuccessAction({ menu: MenuHelper.prepareMenu(this.localStorageService.getAuthorizations()) })),
    );

    @Effect() setActiveUser = this.actions.pipe(
        ofType(CoreActionTypes.APP_INITIALIZE_SUCCESS),
        map((action: InitializeApplicationSuccessAction) => {
            const activeUser = action.payload.users.find((user: User) => user.id === this.localStorageService.getUserId());
            return new SetActiveUser({
                activeUser,
                activeUserAuthorizations: Authorization.getUserAuthorizations(activeUser, action.payload.roles.toArray()),
            });
        }),
    );

    @Effect() checkAuthorization = this.actions.pipe(
        ofType(CoreActionTypes.CHECK_AUTHORIZATION),
        map(() => this.authService.isTokenExpired()
            ? new CheckAuthorizationFailure()
            : new CheckAuthorizationSuccess()
        ),
    );

    @Effect() checkAuthorizationSuccess = this.actions.pipe(
        ofType(CoreActionTypes.CHECK_AUTHORIZATION_SUCCESS),
        map(() => new InitializeApplicationAction()),
    );

    @Effect() navigateAfterSignIn = this.actions.pipe(
        ofType(CoreActionTypes.NAVIGATE_AFTER_SIGNIN),
        switchMap(() => this.store.select(store => store.core.authorizationState.redirectUrl)),
        first(),
        tap((redirectUrl: string) => redirectUrl ? this.router.navigate([redirectUrl]) : null),
        map(() => new NavigateAfterSignInDone()),
    );

    @Effect() saveClient = this.catchEffect(
        CoreActionTypes.SAVE_CLIENT,
        actions => actions.pipe(
            map((action: SaveClient) => action.payload.client),
            switchMap((client: Client) => {
                const c = client.isNew
                    ? this.clientService.createNewClient(client)
                    : this.clientService.updateClient(client);
                return c.pipe(
                    tap(() => this.router.navigate(['/configuration/clients'])),
                    flatMap(() => [
                        new SaveClientSuccess({ client }),
                        new ShowSuccessCreatedUpdatedNotification(client.isNew, 'Client')
                    ]),
                );
            }),
        ),
        (err: HttpErrorResponse) => of(
            new SaveClientFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() deleteClient = this.catchEffect(
        CoreActionTypes.DELETE_CLIENT,
        actions => actions.pipe(
            map((action: DeleteClient) => action.payload.client),
            switchMap((client: Client) => this.clientService
                .deleteClient(client)
                .pipe(flatMap(() => [
                    new DeleteClientSuccess({ client }),
                    new ShowSuccessNotification('Client was deleted')
                ]))),
        ),
        (err: HttpErrorResponse) => of(
            new DeleteClientFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() saveApplication = this.catchEffect(
        CoreActionTypes.SAVE_APPLICATION,
        actions => actions.pipe(
            map((action: SaveApplication) => action.payload.application),
            switchMap((application: Application) => {
                const a = application.isNew
                    ? this.applicationService.createNewApplication(application)
                    : this.applicationService.updateApplication(application);
                return a.pipe(
                    tap(() => this.router.navigate(['/configuration/applications'])),
                    flatMap(() => [
                        new SaveApplicationSuccess({ application }),
                        new ShowSuccessCreatedUpdatedNotification(application.isNew, 'Application')
                    ]),
                );
            }),
        ),
        (error: HttpErrorResponse) => of(
            new SaveApplicationFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(error))
        ),
    );

    @Effect() deleteApplication = this.catchEffect(
        CoreActionTypes.DELETE_APPLICATION,
        actions => actions.pipe(
            map((action: DeleteApplication) => action.payload.application),
            switchMap((application: Application) => this.applicationService
                .deleteApplication(application)
                .pipe(flatMap(() => [
                    new DeleteApplicationSuccess({ application }),
                    new ShowSuccessNotification('Application was deleted')
                ]))),
        ),
        (err: HttpErrorResponse) => of(
            new DeleteApplicationFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() saveUnit = this.catchEffect(
        CoreActionTypes.SAVE_UNIT,
        actions => actions.pipe(
            map((action: SaveUnit) => action.payload.unit),
            switchMap((unit: Unit) => {
                const u = unit.isNew
                    ? this.unitService.createNewUnit(unit)
                    : this.unitService.updateUnit(unit);
                return u.pipe(
                    tap(() => this.router.navigate(['/configuration/units'])),
                    flatMap(() => [
                        new SaveUnitSuccess({ unit }),
                        new ShowSuccessCreatedUpdatedNotification(unit.isNew, 'Unit')
                    ]),
                );
            }),
        ),
        (err: HttpErrorResponse) => of(
            new SaveUnitFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() deleteUnit = this.catchEffect(
        CoreActionTypes.DELETE_UNIT,
        actions => actions.pipe(
            map((action: DeleteUnit) => action.payload.unit),
            switchMap((unit: Unit) => this.unitService
                .deleteUnit(unit)
                .pipe(flatMap(() => [
                    new DeleteUnitSuccess({ unit }),
                    new ShowSuccessNotification('Unit was deleted')
                ]))),
        ),
        (err: HttpErrorResponse) => of(
            new DeleteUnitFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() assignUsersToUnit = this.catchEffect(
        CoreActionTypes.ASSIGN_USER,
        actions => actions.pipe(
            switchMap((action: AssignUsersToUnit) => this.unitService
                .setUsers(action.payload.unit, action.payload.userIds)
                .pipe(
                    tap(() => this.router.navigate(['/configuration/units'])),
                    flatMap(() => [
                        new AssignUsersToUnitSuccess(action.payload),
                        new ShowSuccessNotification('Users were assigned')
                    ]),
                )),
        ),
        (err: HttpErrorResponse) => of(
            new AssignUsersToUnitFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() changeUserAvatar = this.catchEffect(
        CoreActionTypes.CHANGE_USER_AVATAR,
        actions => actions.pipe(
            map((action: ChangeUserAvatar) => action.payload),
            switchMap(({ avatar }) => {
                return this.userService.changeAvatar(avatar)
                    .pipe(
                        flatMap(() => [
                            new ChangeUserAvatarSuccess({ avatar }),
                            new ShowSuccessCreatedUpdatedNotification(false, 'Avatar')
                        ]),
                    );
            }),
        ),
        (err: HttpErrorResponse) => of(
            new ChangeUserAvatarFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() changeUserPassword = this.catchEffect(
        CoreActionTypes.CHANGE_USER_PASSWORD,
        actions => actions.pipe(
            map((action: ChangeUserPassword) => action.payload),
            switchMap(({ oldPassword, newPassword }) => {
                return this.userService.changePassword(oldPassword, newPassword)
                    .pipe(
                        flatMap(() => [
                            new ChangeUserPasswordSuccess(),
                            new ShowSuccessCreatedUpdatedNotification(false, 'Password')
                        ]),
                );
            }),
        ),
        (err: HttpErrorResponse) => of(
            new ChangeUserPasswordFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() saveUser = this.catchEffect(
        CoreActionTypes.SAVE_USER,
        actions => actions.pipe(
            map((action: SaveUser) => action.payload.user),
            switchMap((user: User) => {
                const u = user.isNew
                    ? this.userService.createNewUser(user)
                    : this.userService.updateUser(user);
                return u.pipe(
                    tap(() => this.router.navigate(['/configuration/users'])),
                    flatMap(updatedUser => [
                        new SaveUserSuccess({ user: new User(updatedUser) }),
                        new ShowSuccessCreatedUpdatedNotification(user.isNew, 'User')
                    ]),
                );
            }),
        ),
        (err: HttpErrorResponse) => of(
            new SaveUserFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() deleteUser = this.catchEffect(
        CoreActionTypes.DELETE_USER,
        actions => actions.pipe(
            map((action: DeleteUser) => action.payload.user),
            switchMap(user => this.userService
                .deleteUser(user)
                .pipe(
                    tap(() => this.router.navigate(['/configuration/users'])),
                    flatMap(() => [
                        new DeleteUserSuccess({ user }),
                        new ShowSuccessNotification('User was deleted')
                    ]),
                )),
        ),
        (err: HttpErrorResponse) => of(
            new DeleteUserFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() activation = this.catchEffect(
        CoreActionTypes.ACTIVATE,
        actions => actions.pipe(
            map((action: ActivateAction) => action.payload),
            switchMap(payload => this.authService
                .activate(payload.password, payload.token, payload.userId)
                .pipe(
                    tap(() => {
                        if (payload.userId != null) {
                            this.router.navigate(['/configuration/users']);
                        }
                    }),
                    flatMap(response => {
                        const ret: Action[] = [new ActivateSuccessAction({ userId: payload.userId })];
                        if (payload.userId == null) {
                            ret.push(new ShowSuccessNotification('User activated, Logging in...'));
                            ret.push(new SignInAction({ email: response['email'], password: payload.password }));
                        } else {
                            ret.push(new ShowSuccessNotification('User activated'));
                        }
                        return ret;
                    }),
                )),
        ),
        (err: HttpErrorResponse) => of(
            new ActivateFailureAction(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() saveRole = this.catchEffect(
        CoreActionTypes.SAVE_ROLE,
        actions => actions.pipe(
            map((action: SaveRole) => action.payload.role),
            switchMap((role: Role) => {
                const c = role.isNew
                    ? this.roleService.createNewRole(role)
                    : this.roleService.updateRole(role);
                return c.pipe(
                    tap(() => this.router.navigate(['/configuration/roles'])),
                    flatMap(updatedRole => [
                        new SaveRoleSuccess({ role: new Role(updatedRole) }),
                        new ShowSuccessCreatedUpdatedNotification(role.isNew, 'Role')
                    ]),
                );
            }),
        ),
        (err: HttpErrorResponse) => of(
            new SaveRoleFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() deleteRole = this.catchEffect(
        CoreActionTypes.DELETE_ROLE,
        actions => actions.pipe(
            map((action: DeleteRole) => action.payload.role),
            switchMap((role: Role) => this.roleService
                .deleteRole(role)
                .pipe(flatMap(() => [
                    new DeleteRoleSuccess({ role }),
                    new ShowSuccessNotification('Role was deleted')
                ]))),
        ),
        (err: HttpErrorResponse) => of(
            new DeleteRoleFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );

    @Effect() loadUnits = this.catchEffect(
        CoreActionTypes.LOAD_UNITS,
        actions => actions.pipe(
            switchMap(() => this.unitService.fetch()),
            map((units: List<Unit>) => new LoadUnitsSuccess({ units })),
        ),
        (err: HttpErrorResponse) => of(
            new LoadUnitsFailure(),
            new ShowErrorNotification(HttpHelper.getErrorMessage(err))
        ),
    );
}
