import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './global/GlobalErrorHandler';
import { ReactiveFormsModule } from '@angular/forms';
import { SessionInterceptorService } from './core/services/session-interceptor.service';
import { VersionInterceptorService } from './core/services/version-interceptor.service';
import { LocalStorageService, KEYS } from './core/services/local-storage.service';
import { ErrorInterceptorService } from './core/services/error-interceptor.service';
// The import below is needed for Redux Devtools when enabled
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedModule } from './_shared/shared.module';

// https://github.com/amcdnl/ngrx-actions/issues/23#issuecomment-359738290
export function tokenGetter() { return LocalStorageService.get(KEYS.TOKEN); }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        CoreModule.forRoot(),
        UiComponentsModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        // StoreDevtoolsModule.instrument(), // Uncomment to enable the Redux Devtools
        HttpClientModule,
        ReactiveFormsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                whitelistedDomains: ['localhost:3000'],
            }
        })
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: VersionInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
