/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tile-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tile-header.component";
var styles_TileHeaderComponent = [i0.styles];
var RenderType_TileHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TileHeaderComponent, data: {} });
export { RenderType_TileHeaderComponent as RenderType_TileHeaderComponent };
function View_TileHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "zmdi zmdi-", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_TileHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "controls"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "zmdi zmdi-close"]], null, null, null, null, null))], null, null); }
export function View_TileHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "tile-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TileHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TileHeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.icon != null); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.showCloseButton; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.label; _ck(_v, 5, 0, currVal_1); }); }
export function View_TileHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-tile-header", [], null, null, null, View_TileHeaderComponent_0, RenderType_TileHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.TileHeaderComponent, [], null, null)], null, null); }
var TileHeaderComponentNgFactory = i1.ɵccf("wf-tile-header", i3.TileHeaderComponent, View_TileHeaderComponent_Host_0, { label: "label", icon: "icon", showCloseButton: "showCloseButton" }, { close: "close" }, []);
export { TileHeaderComponentNgFactory as TileHeaderComponentNgFactory };
