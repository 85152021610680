import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StoreRedirectUrl } from '../store/core.actions';
import { AuthService } from './auth.service';
import { HTTP_FORBIDDEN } from '@wildflowerhealth/console-shared';
export class AuthGuardService {
    constructor(store, router, authService) {
        this.store = store;
        this.router = router;
        this.authService = authService;
    }
    canActivate(route, state) {
        var _a, _b;
        if (this.authService.isTokenExpired()) {
            this.store.dispatch(new StoreRedirectUrl({ redirectUrl: state.url }));
            this.authService.signOut();
            return false;
        }
        if (!this.authService.meetsRequirements((_a = route.data) === null || _a === void 0 ? void 0 : _a.requirements)) {
            console.log(`active user does not meet requirements`, (_b = route.data) === null || _b === void 0 ? void 0 : _b.requirements);
            this.router.navigate([`/error/${HTTP_FORBIDDEN}`]);
            return false;
        }
        return true;
    }
}
