/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-sticker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../ui-components/highlight-on-hover.directive";
import * as i3 from "@angular/router";
import * as i4 from "../../../ui-components/tile-header/tile-header.component.ngfactory";
import * as i5 from "../../../ui-components/tile-header/tile-header.component";
import * as i6 from "@angular/common";
import * as i7 from "./menu-sticker.component";
var styles_MenuStickerComponent = [i0.styles];
var RenderType_MenuStickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuStickerComponent, data: {} });
export { RenderType_MenuStickerComponent as RenderType_MenuStickerComponent };
function View_MenuStickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["wfHighlightOnHover", ""]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.HighlightOnHoverDirective, [i1.ElementRef], null, null), i1.ɵdid(2, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.uri, ""); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_1); }); }
export function View_MenuStickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "wf-tile-header", [], null, null, null, i4.View_TileHeaderComponent_0, i4.RenderType_TileHeaderComponent)), i1.ɵdid(2, 49152, null, 0, i5.TileHeaderComponent, [], { label: [0, "label"], icon: [1, "icon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuStickerComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuSection.label; var currVal_1 = _co.menuSection.icon; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.menuSection.menuItems; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MenuStickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-menu-sticker", [], null, null, null, View_MenuStickerComponent_0, RenderType_MenuStickerComponent)), i1.ɵdid(1, 114688, null, 0, i7.MenuStickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuStickerComponentNgFactory = i1.ɵccf("wf-menu-sticker", i7.MenuStickerComponent, View_MenuStickerComponent_Host_0, { menuSection: "menuSection" }, {}, []);
export { MenuStickerComponentNgFactory as MenuStickerComponentNgFactory };
