<div class="tile">
    <wf-tile-header [label]="title" [icon]="'account-add'" [showCloseButton]="true" (close)="close()"></wf-tile-header>

    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
        <div>
            <wf-input label="Role Name*" formControlName="name"></wf-input>
        </div>

        <div class="sections-list">
            <section>
                <header>Console</header>
                <div class="selectors">
                    <wf-permission-selector
                        *ngIf="isAuthorizedRead(AuthorizationResource.SystemAdmin)"
                        label="System Administrator (Grants all other privileges!)"
                        [formControlName]="AuthorizationResource[AuthorizationResource.SystemAdmin]"
                        [isEdit]="isAuthorizedWrite(AuthorizationResource.SystemAdmin)"></wf-permission-selector>
                    <wf-permission-selector
                        label="Login"
                        [formControlName]="AuthorizationResource[AuthorizationResource.Login]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Self Service"
                        [formControlName]="AuthorizationResource[AuthorizationResource.SelfService]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Users"
                        [formControlName]="AuthorizationResource[AuthorizationResource.ConsoleUsers]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Roles"
                        [formControlName]="AuthorizationResource[AuthorizationResource.ConsoleRoles]"
                        [isEdit]="isEdit"></wf-permission-selector>
                </div>
            </section>

            <section>
                <header>Eligibility</header>
                <div class="selectors">
                    <wf-permission-selector
                        label="General"
                        [formControlName]="AuthorizationResource[AuthorizationResource.Eligibility]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Configuration"
                        [formControlName]="AuthorizationResource[AuthorizationResource.EligibilityConfig]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Configuration Secrets"
                        [formControlName]="AuthorizationResource[AuthorizationResource.EligibilitySecrets]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="History (Contains PHI)"
                        [formControlName]="AuthorizationResource[AuthorizationResource.EligibilityHistoryPHI]"
                        [isEdit]="isEdit"></wf-permission-selector>
                </div>
            </section>

            <section>
                <header>Version Control</header>
                <div class="selectors">
                    <wf-permission-selector
                        label="Private App Promotion (Staging/IAT)"
                        [formControlName]="AuthorizationResource[AuthorizationResource.AppVersionsPrivate]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Public App Promotion (UAT/Store/Production)"
                        [formControlName]="AuthorizationResource[AuthorizationResource.AppVersionsPublic]"
                        [isEdit]="isEdit"></wf-permission-selector>
                </div>
            </section>

            <section>
                <header>Reporting</header>
                <div class="selectors">
                    <wf-permission-selector
                        label="Reports"
                        [formControlName]="AuthorizationResource[AuthorizationResource.Reports]"
                        [isEdit]="isEdit"></wf-permission-selector>
                </div>
            </section>

            <section>
                <header>Content Management</header>
                <div class="selectors">
                    <wf-permission-selector
                        label="Manage Content"
                        [formControlName]="AuthorizationResource[AuthorizationResource.Content]"
                        [isEdit]="isEdit"></wf-permission-selector>
                </div>
            </section>

            <section>
                <header>Applicatons</header>
                <div class="selectors">
                    <wf-permission-selector
                        label="Manage Clients"
                        [formControlName]="AuthorizationResource[AuthorizationResource.Clients]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Manage Apps"
                        [formControlName]="AuthorizationResource[AuthorizationResource.Applications]"
                        [isEdit]="isEdit"></wf-permission-selector>
                    <wf-permission-selector
                        label="Confirm and Delete App Users"
                        [formControlName]="AuthorizationResource[AuthorizationResource.AppUsers]"
                        [isEdit]="isEdit"></wf-permission-selector>
                </div>
            </section>
        </div>

        <div class="controls">
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
        </div>
    </form>

    <wf-spinner *ngIf="store | select: 'core.data.isRoleSaving' | async"></wf-spinner>
</div>
