<div class="clients-list" *ngIf="clients" [ngClass]="{'select-view-mode': viewSelectMode}">
    <div class="client" *ngFor="let client of clients" [@flyIn]="'in'" [ngClass]="clientStyles(client)"
        (click)="isClientSelected(client) ? null : select.next(client)">

        <wf-image-icon [image]="client.logo" icon="city" [size]="viewSelectMode ? 2 : 4"></wf-image-icon>

        <div class="info">
            <div class="name">{{client.name}}</div>
            <div class="prefix">{{client.prefix}}</div>
        </div>

        <div class="inline-button edit-button" (click)="editClick(client);$event.stopPropagation();">
            <i class="zmdi zmdi-edit"></i>
        </div>
        <div class="inline-button delete-button" (click)="deleteClick(client);$event.stopPropagation();">
            <i class="zmdi zmdi-delete"></i>
        </div>
    </div>
</div>
