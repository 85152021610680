import { Injectable } from '@angular/core';
import { API_CONFIG, BACKGOUND_URI, environment, TEXTURE_URI, CMS_IMPORT_API_CONFIG } from '../../../environments/environment';

@Injectable()
export class EnvironmentService {

    constructor() {
    }

    getAPIUrl(): string {
        return API_CONFIG.API;
    }

    getCmsImportAPIUrl(): string {
        return CMS_IMPORT_API_CONFIG.API;
    }

    getSocketUrl(): string {
        return API_CONFIG.SOCKET_URL;
    }

    getBackgroundImageUri(): string {
        return BACKGOUND_URI;
    }

    getTextureImageUri(): string {
        return TEXTURE_URI;
    }

    isProduction(): boolean {
        return environment.production;
    }

    bannerLabel() {
        return environment.bannerLabel;
    }

    bannerStyle() {
        return environment.bannerStyle;
    }

    getThumbnailUrl(mediaFile: string) {
        if (mediaFile == null) {
            return null;
        }
        return `${API_CONFIG.MEDIA}/thumbnails/${mediaFile.replace(/\.[^\.]+$/, '.png')}`;
    }

}
