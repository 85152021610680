/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-icon.component";
var styles_ImageIconComponent = [i0.styles];
var RenderType_ImageIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageIconComponent, data: {} });
export { RenderType_ImageIconComponent as RenderType_ImageIconComponent };
function View_ImageIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[4, "font-size", null], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size + "em"); var currVal_1 = _co.image; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImageIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "icon-wrapper"]], [[4, "font-size", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "show-background": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "icon-wrapper"; var currVal_2 = _ck(_v, 3, 0, _co.showIconBackground); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.size + "em"); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵinlineInterpolate(1, "zmdi zmdi-", _co.icon, ""); _ck(_v, 4, 0, currVal_3); }); }
export function View_ImageIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageIconComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageIconComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.image; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ImageIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-image-icon", [], null, null, null, View_ImageIconComponent_0, RenderType_ImageIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageIconComponentNgFactory = i1.ɵccf("wf-image-icon", i3.ImageIconComponent, View_ImageIconComponent_Host_0, { image: "image", size: "size", icon: "icon", showIconBackground: "showIconBackground" }, {}, []);
export { ImageIconComponentNgFactory as ImageIconComponentNgFactory };
