import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import { PopupModule } from './popup/popup.module';
import { CircularProgressModule } from './circular-progress/circular-progress.module';

@NgModule({
    imports: [
        CommonModule,
        PopupModule,
        CircularProgressModule
    ],
    declarations: [
    ],
    exports: [
        PopupModule,
        CircularProgressModule
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
