<div class="tile" *ngIf="user">
    <wf-tile-header icon="account" label="User details" [showCloseButton]="true" (close)="close()"></wf-tile-header>
    <div class="horizontal-group">
        <wf-image-icon [image]="user.avatar" icon="account" [size]="5" wfRound></wf-image-icon>
        <div class="info">
            <wf-label-row label="Full Name" [data]="user.getFullName()"></wf-label-row>
            <wf-label-row label="Client" [data]="client ? client.name : ''"></wf-label-row>
            <wf-label-row label="Email" [data]="user.email"></wf-label-row>
        </div>

    </div>
    <div class="controls" *ngIf="isSuperClient && isUserAuthorized">
        <button class="btn btn-warning" (click)="edit()">Edit</button>
        <button *ngIf="user.active" class="btn btn-danger" (click)="delete()">Disable</button>
        <button *ngIf="!user.active" class="btn btn-danger" (click)="enable()">Enable</button>
    </div>
</div>
<wf-spinner *ngIf="store | select: 'core.data.isUserDeletion' | async"></wf-spinner>
