<div class="tile-header">
    <div class="icon">
        <i *ngIf="icon != null" class="zmdi zmdi-{{icon}}"></i>
    </div>
    <div class="text">
        {{label}}
    </div>
    <div *ngIf="showCloseButton" class="controls" (click)="close.emit($event)">
        <i class="zmdi zmdi-close"></i>
    </div>
</div>
