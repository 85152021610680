import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalState } from '../../global/global.state';
import { Store } from '@ngrx/store';

@Pipe({
    name: 'select'
})
export class SelectPipe implements PipeTransform {

    transform(store: Store<GlobalState>, select: string): Observable<any> {
        return select
            .split('.')
            .reduce((reduction: Store<any>, nextSelect) => reduction.select(nextSelect), store);
    }

}
