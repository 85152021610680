import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NgControl } from '@angular/forms';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';

@Component({
    selector: 'wf-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
    providers: [DefaultValueAccessor],
})
export class ImageInputComponent implements OnInit, ControlValueAccessor {

    @Input() size = 1;
    @Input() isRound = false;
    @Input() displayImagePlaceholder = true;

    public image: any = null;
    public isUploaderShown = false;
    public parentNgControl: NgControl;

    onChangeCallback: any = () => {
    }

    onTouchCallback: any = () => {
    }

    constructor(@Optional() private ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
        this.parentNgControl = this.ngControl;
    }

    ngOnInit() {
    }

    writeValue(value: any): void {
        this.image = value;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchCallback = fn;
    }

    openUploader(event): void {
        this.isUploaderShown = true;
        // this.cropper.setImage(new Image());
    }

    handleImageChanged(image) {
        this.image = image;
        this.onTouchCallback();
        this.onChangeCallback(image);
    }
}
