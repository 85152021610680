import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SaveRole } from '../../store/core.actions';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Role, AuthorizationAction, AuthorizationResource, Authorization } from '@wildflowerhealth/console-shared';
import { LocalStorageService } from '../../services/local-storage.service';
export class RoleFormPageComponent {
    constructor(formBuilder, activatedRoute, localStore, store, router) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.localStore = localStore;
        this.store = store;
        this.router = router;
        this.isEdit = this.isAuthorizedWrite(AuthorizationResource.ConsoleRoles);
        this.AuthorizationAction = AuthorizationAction;
        this.AuthorizationResource = AuthorizationResource;
    }
    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'])),
            this.store.select(store => store.core.data.roles),
        ]).pipe(map(([id, roles]) => roles.find(role => role.id === id))).subscribe(role => {
            var _a, _b;
            this.role = role || null;
            this.title = role ? 'Edit Role' : 'Add new Role';
            const formData = {
                name: [role ? role.name : '', Validators.required],
            };
            for (const resource of Authorization.AllResources) {
                const resourceName = AuthorizationResource[resource];
                if (role == null) {
                    formData[resourceName] = [AuthorizationAction.None];
                    continue;
                }
                formData[resourceName] = [(_b = (_a = role.authorizations.find(a => a.resource === resource)) === null || _a === void 0 ? void 0 : _a.action, (_b !== null && _b !== void 0 ? _b : AuthorizationAction.None))];
            }
            this.form = this.formBuilder.group(formData);
        });
    }
    save() {
        if (this.role == null) {
            this.role = new Role();
        }
        this.role.name = this.form.value.name;
        this.role.authorizations = Authorization.AllResources.map(r => {
            const name = AuthorizationResource[r];
            const value = this.form.value[name];
            if (value == null || value === AuthorizationAction.None) {
                return null;
            }
            return { action: value, resource: r };
        }).filter(a => a != null);
        this.store.dispatch(new SaveRole({ role: this.role }));
    }
    close() {
        this.router.navigate(['/configuration/roles']);
    }
    isAuthorized(action, ...resources) {
        return Authorization.isAuthorized(this.localStore.getAuthorizations(), action, ...resources);
    }
    isAuthorizedRead(...resources) {
        return Authorization.isAuthorized(this.localStore.getAuthorizations(), AuthorizationAction.Read, ...resources);
    }
    isAuthorizedWrite(...resources) {
        return Authorization.isAuthorized(this.localStore.getAuthorizations(), AuthorizationAction.ReadWrite, ...resources);
    }
}
