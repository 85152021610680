<div class="tile" *ngIf="!error">
    <header>Welcome to Wildflower Health Console!</header>
    <p>
        To complete you registration process and starting using application, please setup you password.
    </p>
    <form [formGroup]="form" (ngSubmit)="submit()">

        <wf-input [label]="'Enter your password'" formControlName="password" [type]="'password'"></wf-input>
        <wf-input [label]="'Confirm password'" formControlName="passwordConfirm" [type]="'password'"></wf-input>

        <div class="controls">
            <button type="submit" class="btn btn-primary" [disabled]="form.status === 'INVALID'">Set password</button>
        </div>
    </form>
</div>

<div class="tile" *ngIf="error">
    <p>
        {{error}}
    </p>
</div>
