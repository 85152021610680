<div class="tile" *ngIf="form">
    <wf-tile-header [label]="'Users access-list'" [icon]="'accounts'" [showCloseButton]="true" (close)="close()">
    </wf-tile-header>

    <wf-label-row [label]="'Unit name'" [data]="unit ? unit.name : ''"></wf-label-row>
    <wf-label-row [label]="'Client'" [data]="client ? client.name : ''"></wf-label-row>
    <wf-label-row [label]="'Segment'" [data]="unit ? unit.getSegmentName() : ''"></wf-label-row>

    <form (ngSubmit)="save()" [formGroup]="form" *ngIf="relatedUsers.size > 0">
        Assigned users:
        <wf-checkbox class="group-input" [label]="user.getFullName()" [formControlName]="user.id"
            *ngFor="let user of relatedUsers"></wf-checkbox>
        <div class="controls">
            <button type="submit" class="btn btn-primary">Apply Assignment</button>
        </div>
    </form>

    <div class="empty-list" *ngIf="relatedUsers.size === 0">No users to manage for this UNIT</div>

    <wf-spinner *ngIf="store | select: 'core.data.isUserUnitAssignment' | async"></wf-spinner>
</div>
