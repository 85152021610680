<div class="tile">
    <wf-tile-header [label]="title" [icon]="'account-add'" [showCloseButton]="true" (close)="close()"></wf-tile-header>

    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
        <div class="horizontal-group">
            <wf-image-input [size]="6" formControlName="logo"></wf-image-input>
            <div>
                <wf-input label="Client Name*" formControlName="name"></wf-input>
                <wf-input label="Prefix*" formControlName="prefix"></wf-input>
            </div>
        </div>

        <div class="controls">
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
        </div>
    </form>

    <wf-spinner *ngIf="store | select: 'core.data.isClientSaving' | async"></wf-spinner>
</div>
