import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SaveApplication } from '../../store/core.actions';
import { ProductOptions } from '../../constants/ProductOptions';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Application } from '@wildflowerhealth/console-shared';
import { List } from 'immutable';
import { WfValidators } from '../../validators/WfValidator';
export class ApplicationFormPageComponent {
    constructor(formBuilder, activatedRoute, store, router) {
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.store = store;
        this.router = router;
        this.productOptions = ProductOptions;
        this.wfhEnvOptions = List([
            { name: 'dev', value: 'dev' },
            { name: 'stage', value: 'stage' },
            { name: 'iat', value: 'iat' },
            { name: 'uat', value: 'uat' },
            { name: 'prod', value: 'prod' },
        ]);
    }
    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'])),
            this.store.select(store => store.core.data.applications),
        ]).pipe(map(([id, applications]) => applications.find(application => application.id === id))).subscribe(application => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            this.application = application || null;
            this.title = application ? 'Edit Application' : 'Add new Application';
            const appDnsNames = (_b = (_a = application) === null || _a === void 0 ? void 0 : _a.appDnsNames) === null || _b === void 0 ? void 0 : _b.map(dnsConfig => this.createAppDnsNameFormGroup(dnsConfig));
            this.form = this.formBuilder.group({
                fullName: [((_c = application) === null || _c === void 0 ? void 0 : _c.fullName) || '', Validators.required],
                name: [((_d = application) === null || _d === void 0 ? void 0 : _d.name) || '', Validators.required],
                bundleId: [((_e = application) === null || _e === void 0 ? void 0 : _e.bundleId) || '', Validators.required],
                productConst: [((_f = application) === null || _f === void 0 ? void 0 : _f.productConst) || '', Validators.required],
                logo: [((_g = application) === null || _g === void 0 ? void 0 : _g.logo) || ''],
                iTunesID: [((_h = application) === null || _h === void 0 ? void 0 : _h.iTunesID) || ''],
                appHostName: [((_j = application) === null || _j === void 0 ? void 0 : _j.appHostName) || '', WfValidators.hostNamePattern],
                appDnsNames: this.formBuilder.array(((_k = appDnsNames) === null || _k === void 0 ? void 0 : _k.length) ? appDnsNames
                    : [this.createAppDnsNameFormGroup()]),
            });
        });
    }
    addAppDnsNameFormGroup() {
        const appDnsNames = this.form.get('appDnsNames');
        appDnsNames.push(this.createAppDnsNameFormGroup());
    }
    removeOrClearEmail(i) {
        const appDnsNames = this.form.get('appDnsNames');
        if (appDnsNames.length > 1) {
            appDnsNames.removeAt(i);
        }
        else {
            appDnsNames.reset();
        }
    }
    createAppDnsNameFormGroup(dnsConfig) {
        var _a, _b;
        return new FormGroup({
            appDnsName: new FormControl(((_a = dnsConfig) === null || _a === void 0 ? void 0 : _a.appDnsName) || '', WfValidators.dnsNamePattern),
            wfhEnv: new FormControl(((_b = dnsConfig) === null || _b === void 0 ? void 0 : _b.wfhEnv) || 'dev')
        });
    }
    save() {
        if (!this.form.valid) {
            return;
        }
        this.store.dispatch(new SaveApplication({
            application: this.application
                ? this.application.fillFromForm(this.form.value)
                : new Application().fillFromForm(this.form.value)
        }));
    }
    close() {
        this.router.navigate(['/configuration/applications']);
    }
}
