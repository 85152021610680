import { Component, HostListener, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GlobalState } from './global/global.state';
import { Store } from '@ngrx/store';
import { CheckAuthorization, KeyPressed } from './core/store/core.actions';


@Component({
    selector: 'wf-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    elementClass = '';

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.store.dispatch(new KeyPressed({ event }));
    }

    constructor(public store: Store<GlobalState>) {
    }

    ngOnInit(): void {
        document.getElementById('loading-container').remove();
        this.store.dispatch(new CheckAuthorization());

        fromEvent(window, 'load').pipe(
            switchMap(() => this.store.select(s => s.core.authorizationState.isAuthorized)),
        ).subscribe((isAuthorized: boolean) => {
            this.elementClass = isAuthorized ? 'authorized' : 'non-authorized';
        });
    }

}
