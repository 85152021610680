import { FormControl } from '@angular/forms';
import { List } from 'immutable';
import { LocalizedText, Locale } from '@wildflowerhealth/console-shared';
import { getLocaleKey } from '../../cms/interfaces/ILocale';
import { Moment } from 'moment';

const NA_PHONE_NUMBER_REGEXP = /^(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const INTERNATIONAL_PHONE_NUMBER_REGEXP = /^(?:\+[0-9]+[-. ])?(?:[0-9][-. ]?){6,14}[0-9]$/;
const THREE_DIGIT_PHONE_NUMBER_REGEXP = /^\+?[0-9]{3}$/;
const FIVE_DIGIT_PHONE_NUMBER_REGEXP = /^\+?[0-9]{5}$/;
const SWC_TOKEN_REGEXP = /^@@[A-Z0-9_-]+@@$/;
const HOST_NAME_REGEXP = /^[a-z0-9]+(-?[a-z0-9])*$/;
const DNS_NAME_REGEXP = /^[a-z0-9]+(-?[a-z0-9])*(\.[a-z0-9]+(-?[a-z0-9])*)+$/;

const isPhoneNumberValid = phoneNumber => NA_PHONE_NUMBER_REGEXP.test(phoneNumber) ||
    INTERNATIONAL_PHONE_NUMBER_REGEXP.test(phoneNumber) ||
    THREE_DIGIT_PHONE_NUMBER_REGEXP.test(phoneNumber) ||
    FIVE_DIGIT_PHONE_NUMBER_REGEXP.test(phoneNumber) ||
    SWC_TOKEN_REGEXP.test(phoneNumber);

export class WfValidators {
    static dateRequired(control: FormControl): any {
        if (!(control.value && control.value.startDate && control.value.endDate)) {
            return { required: true };
        }

        return null;
    }

    static email(control: FormControl) {
        const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!EMAIL_REGEXP.test(control.value)) {
            return { invalidEmail: true };
        }

        return null;
    }

    static nonEmpty(control: FormControl) {
        if (control.value.trim() === '') {
            return { empty: true };
        }

        return null;
    }

    static capitalsOnly(control: FormControl) {
        if (control.value !== control.value.toUpperCase()) {
            return { nonCapitals: true };
        }

        return null;
    }

    static startsFromCapital(control: FormControl) {
        if (!control.value || control.value.length === 0) {
            return null;
        }

        if (control.value[0] !== control.value[0].toUpperCase()) {
            return { startsFromCapital: true };
        }

        return null;
    }

    static noSpaces(control: FormControl) {
        if (!control.value || control.value.length === 0) {
            return null;
        }

        if (control.value.trim().indexOf(' ') !== -1 || control.value.trim().indexOf(`\t`) !== -1) {
            return { noSpaces: true };
        }

        return null;
    }

    static minEightChars(control: FormControl) {
        if (control.value.length < 8) {
            return { tooShort: true };
        }

        return null;
    }

    static numberPresent(control: FormControl) {
        if (!/\d/.test(control.value)) {
            return { noDigit: true };
        }

        return null;
    }

    static letterPresent(control: FormControl) {
        if (!/\D/.test(control.value)) {
            return { noLetter: true };
        }

        return null;
    }

    static platformBuildFormat(control: FormControl) {
        if (!/^\d+\.\d+\.\d+$/.test(control.value)) {
            return { platformBuildFormat: true };
        }
        return null;
    }

    static buildValidatorForWrongValue(prohibitedValues: List<string>) {
        return (control: FormControl) => {
            if (prohibitedValues.find(value => value === control.value)) {
                return { wrongValue: true };
            }

            return null;
        };
    }

    static numbersOnly(control: FormControl) {
        if (!control.value) {
            return;
        }
        if (!/^\d+$/.test(control.value)) {
            return { numbersOnly: true };
        }

        return null;
    }

    static integerOnly(control: FormControl) {
        if (!/^[-+]?\d+$/.test(control.value)) {
            return { integerOnly: true };
        }
    }

    static localizationRequired(control: FormControl) {
        if (control.value === null || (control.value as List<LocalizedText>).reduce((acc: boolean, item) => acc && item.value === '', true)) {
            return {
                localizationRequired: true,
                message: 'This field is required'
            };
        }

        for (const locale of Locale.ALL_LOCALES.toJS()) {
            const lt = (control.value as List<LocalizedText>).find(item => locale.level === item.level && locale.language === item.language);
            if (!lt || !lt.value) {
                return {
                    localizationRequired: true,
                    message: `This field is required for locale ${getLocaleKey(locale.language, locale.level)}`
                };
            }
        }

        return null;
    }

    static phoneNumber(control: FormControl) {
        if (!isPhoneNumberValid(control.value)) {
            return {
                phoneNumber: true,
                message: `This field should be a valid phone number or replacement token, eg. @@HOSP_PHONE@@`
            };
        }
        return null;
    }

    static localizedPhoneNumber(control: FormControl) {
        for (const locale of Locale.ALL_LOCALES.toJS()) {
            const value = LocalizedText.GetValue(control.value as List<LocalizedText>, locale);
            if (!isPhoneNumberValid(value)) {
                return {
                    localizedPhoneNumber: true,
                    message: `Value for locale ${getLocaleKey(locale.language, locale.level)} should be a valid phone number`
                };
            }
        }

        return null;
    }

    static dateRangeRequired(control: FormControl) {
        const value: { startDate: Moment, endDate: Moment } = control.value;

        if (!value || !value.startDate || !value.endDate) {
            return {
                required: true
            };
        }

        return null;
    }

    static timeRequired(control: FormControl) {
        const value: { hour: number, minute: number } = control.value;

        if (!value || !Number.isInteger(value.hour) || !Number.isInteger(value.minute)) {
            return {
                required: true
            };
        }

        return null;
    }

    static dnsNamePattern(control: FormControl) {
        if ((control.touched || control.dirty) && !DNS_NAME_REGEXP.test(control.value)) {
            return {
                appDnsName: true,
            };
        }
        return null;
    }

    static hostNamePattern(control: FormControl) {
        if ((control.touched || control.dirty) && !HOST_NAME_REGEXP.test(control.value)) {
            return {
                hostName: true,
            };
        }
        return null;
    }
}
