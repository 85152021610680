/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roles-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./roles-list.component";
var styles_RolesListComponent = [i0.styles];
var RenderType_RolesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RolesListComponent, data: { "animation": [{ type: 7, name: "flyIn", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "translateX(0)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translateX(50%) translateZ(0)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateX(-15px) translateZ(0)", offset: 0.7 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translateX(0) translateZ(0)", offset: 1 }, offset: null }] }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_RolesListComponent as RenderType_RolesListComponent };
function View_RolesListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "role"]], [[24, "@flyIn", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isRoleSelected(_v.context.$implicit) ? null : _co.select.next(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "inline-button edit-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editClick(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "zmdi zmdi-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "inline-button delete-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.deleteClick(_v.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "zmdi zmdi-delete"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "role"; var currVal_2 = _co.roleStyles(_v.context.$implicit); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_3); }); }
function View_RolesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "roles-list"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "select-view-mode": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesListComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "roles-list"; var currVal_1 = _ck(_v, 3, 0, _co.viewSelectMode); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.roles; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RolesListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesListComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roles; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RolesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-roles-list", [], null, null, null, View_RolesListComponent_0, RenderType_RolesListComponent)), i1.ɵdid(1, 49152, null, 0, i3.RolesListComponent, [], null, null)], null, null); }
var RolesListComponentNgFactory = i1.ɵccf("wf-roles-list", i3.RolesListComponent, View_RolesListComponent_Host_0, { roles: "roles", selectedRole: "selectedRole", viewSelectMode: "viewSelectMode" }, { select: "select", edit: "edit", delete: "delete" }, []);
export { RolesListComponentNgFactory as RolesListComponentNgFactory };
