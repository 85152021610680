import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'wf-logout-page',
    templateUrl: './logout-page.component.html',
    styleUrls: ['./logout-page.component.scss']
})
export class LogoutPageComponent implements OnInit {

    constructor(private readonly auth: AuthService) {
    }

    ngOnInit() {
        this.auth.signOut();
    }

}
