import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// https://github.com/auth0/angular2-jwt/issues/481
import './app/global/helpers/angular-jwt-fix';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 * Moved from polyfill.ts and changed to require syntax
 * so that JIMP does not clobber the Promise implementation
 */
require('zone.js/dist/zone');

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
