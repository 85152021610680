import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../global/global.state';
import { Router } from '@angular/router';
import { DeleteApplication, SelectApplication } from '../../store/core.actions';
import { ModalService } from '../../services/modal.service';
import { map } from 'rxjs/operators';
import { Authorization, AuthorizationAction, AuthorizationResource } from '@wildflowerhealth/console-shared';
import { Application } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-applications-list-page',
    templateUrl: './applications-list-page.component.html',
    styleUrls: ['./applications-list-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationsListPageComponent implements OnInit {

    isEditMode: Observable<boolean>;

    constructor(public store: Store<GlobalState>, private router: Router, private modalService: ModalService) {
    }

    ngOnInit() {
        this.isEditMode = this.store
            .select(state => state.core.data.activeUserAuthorizations)
            .pipe(map(auths => Authorization.isAuthorized(auths, AuthorizationAction.ReadWrite, AuthorizationResource.Applications)));
    }

    create() {
        this.router.navigate(['/configuration/applications/form']);
    }

    select(application: Application) {
        this.store.dispatch(new SelectApplication({ selectedApplication: application }));
    }

    edit(application: Application) {
        this.router.navigate(['/configuration/applications/form', { id: application.id }]);
    }

    users(application: Application) {
        this.router.navigate(['/configuration/applications/users', { id: application.id }]);
    }

    delete(application: Application) {
        this.modalService
            .showDeleteConfirmation(`Are you sure want to delete application "${application.name}" ?`)
            .subscribe(() => this.store.dispatch(new DeleteApplication({ application })));
    }

}
