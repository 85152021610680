import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'wf-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    // ViewEncapsulation
})
export class SpinnerComponent implements OnInit {

    @Input() label = '';

    constructor() {
    }

    ngOnInit() {
    }

}
