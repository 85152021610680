<div class="side-menu">
    <div class="menu">
        <div class="section" *ngFor="let menuSection of (store| select:'core.data.menu' |async)">
            <div class="section-header">
                <div class="icon">
                    <i class="zmdi" [ngClass]="'zmdi-' + menuSection.icon"></i>
                </div>
                <div class="text">
                    {{menuSection.label}}
                </div>
            </div>
            <div class="items">
                <div class="item" *ngFor="let menuItem of menuSection.menuItems" wfHighlightOnHover
                    routerLink="{{menuItem.uri}}">
                    {{menuItem.label}}
                </div>
            </div>
        </div>
    </div>
</div>
