import { __decorate, __metadata } from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import { ActivateFailureAction, ActivateSuccessAction, AssignUsersToUnitFailure, AssignUsersToUnitSuccess, CheckAuthorizationFailure, CheckAuthorizationSuccess, CoreActionTypes, DeleteApplicationFailure, DeleteApplicationSuccess, DeleteClientFailure, DeleteClientSuccess, DeleteUnitFailure, DeleteUnitSuccess, DeleteUserFailure, DeleteUserSuccess, InitializationMenuSuccessAction, InitializeApplicationAction, InitializeApplicationFailureAction, InitializeApplicationSuccessAction, InitializeMenuAction, LoadUnitsFailure, LoadUnitsSuccess, NavigateAfterSignIn, NavigateAfterSignInDone, SaveApplicationFailure, SaveApplicationSuccess, SaveClientFailure, SaveClientSuccess, SaveUnitFailure, SaveUnitSuccess, SaveUserFailure, SaveUserSuccess, SetActiveUser, ShowErrorNotification, ShowSuccessNotification, SignInAction, SignInFailureAction, SignInSuccessAction, ShowSuccessCreatedUpdatedNotification, SaveRoleSuccess, SaveRoleFailure, DeleteRoleSuccess, DeleteRoleFailure, ChangeUserPasswordFailure, ChangeUserPasswordSuccess, ChangeUserAvatarSuccess, ChangeUserAvatarFailure, } from './core.actions';
import { of, zip } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { ClientService } from '../services/client.service';
import { UserService } from '../services/user.service';
import { UnitService } from '../services/unit.service';
import { ApplicationService } from '../services/application.service';
import { HttpHelper } from '../../global/helpers/HttpHelper';
import { MenuHelper } from '../helpers/MenuHelper';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { SocketService } from '../services/socket.service';
import { switchMap, map, tap, flatMap, first } from 'rxjs/operators';
import { User, Authorization, Role } from '@wildflowerhealth/console-shared';
import { BaseEffects } from './base.effects';
import { RoleService } from '../services/role.service';
import { DistributedConfigService } from '../services/distributed-config.service';
export class CoreEffects extends BaseEffects {
    constructor(actions, authService, localStorageService, clientService, userService, roleService, unitService, applicationService, store, router, socketService, distCfgSvc) {
        super(actions);
        this.authService = authService;
        this.localStorageService = localStorageService;
        this.clientService = clientService;
        this.userService = userService;
        this.roleService = roleService;
        this.unitService = unitService;
        this.applicationService = applicationService;
        this.store = store;
        this.router = router;
        this.socketService = socketService;
        this.distCfgSvc = distCfgSvc;
        this.signIn = this.catchEffect(CoreActionTypes.SIGNIN, actions => actions.pipe(switchMap((action) => this.authService
            .singIn(action.payload.email, action.payload.password)), map(res => new SignInSuccessAction(res))), (err) => of(new SignInFailureAction(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.signInSuccess = this.actions.pipe(ofType(CoreActionTypes.SIGNIN_SUCCESS), tap((action) => {
            this.localStorageService.setToken(action.payload.token);
            this.localStorageService.setUserId(action.payload.userId);
            this.localStorageService.setAuthorizations(action.payload.authorizations);
            this.router.navigate(['/home']);
        }), map(() => new InitializeApplicationAction()));
        this.initializeApplication = this.catchEffect(CoreActionTypes.APP_INITIALIZE, actions => actions.pipe(switchMap(() => zip(this.clientService.fetch(), this.userService.fetch(), this.roleService.fetch(), this.applicationService.fetch(), this.unitService.fetch(), this.distCfgSvc.fetch(), this.socketService.initSocket(this.localStorageService.getToken()))), flatMap(data => {
            const [clients, users, roles, applications, units, distCfg] = data;
            return [
                new InitializeApplicationSuccessAction({ clients, users, roles, applications, units, distCfg }),
                new InitializeMenuAction(),
                new NavigateAfterSignIn()
            ];
        })), (err) => of(new InitializeApplicationFailureAction(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.initializeMenu = this.actions.pipe(ofType(CoreActionTypes.MENU_INITIALIZE), map(() => new InitializationMenuSuccessAction({ menu: MenuHelper.prepareMenu(this.localStorageService.getAuthorizations()) })));
        this.setActiveUser = this.actions.pipe(ofType(CoreActionTypes.APP_INITIALIZE_SUCCESS), map((action) => {
            const activeUser = action.payload.users.find((user) => user.id === this.localStorageService.getUserId());
            return new SetActiveUser({
                activeUser,
                activeUserAuthorizations: Authorization.getUserAuthorizations(activeUser, action.payload.roles.toArray()),
            });
        }));
        this.checkAuthorization = this.actions.pipe(ofType(CoreActionTypes.CHECK_AUTHORIZATION), map(() => this.authService.isTokenExpired()
            ? new CheckAuthorizationFailure()
            : new CheckAuthorizationSuccess()));
        this.checkAuthorizationSuccess = this.actions.pipe(ofType(CoreActionTypes.CHECK_AUTHORIZATION_SUCCESS), map(() => new InitializeApplicationAction()));
        this.navigateAfterSignIn = this.actions.pipe(ofType(CoreActionTypes.NAVIGATE_AFTER_SIGNIN), switchMap(() => this.store.select(store => store.core.authorizationState.redirectUrl)), first(), tap((redirectUrl) => redirectUrl ? this.router.navigate([redirectUrl]) : null), map(() => new NavigateAfterSignInDone()));
        this.saveClient = this.catchEffect(CoreActionTypes.SAVE_CLIENT, actions => actions.pipe(map((action) => action.payload.client), switchMap((client) => {
            const c = client.isNew
                ? this.clientService.createNewClient(client)
                : this.clientService.updateClient(client);
            return c.pipe(tap(() => this.router.navigate(['/configuration/clients'])), flatMap(() => [
                new SaveClientSuccess({ client }),
                new ShowSuccessCreatedUpdatedNotification(client.isNew, 'Client')
            ]));
        })), (err) => of(new SaveClientFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.deleteClient = this.catchEffect(CoreActionTypes.DELETE_CLIENT, actions => actions.pipe(map((action) => action.payload.client), switchMap((client) => this.clientService
            .deleteClient(client)
            .pipe(flatMap(() => [
            new DeleteClientSuccess({ client }),
            new ShowSuccessNotification('Client was deleted')
        ])))), (err) => of(new DeleteClientFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.saveApplication = this.catchEffect(CoreActionTypes.SAVE_APPLICATION, actions => actions.pipe(map((action) => action.payload.application), switchMap((application) => {
            const a = application.isNew
                ? this.applicationService.createNewApplication(application)
                : this.applicationService.updateApplication(application);
            return a.pipe(tap(() => this.router.navigate(['/configuration/applications'])), flatMap(() => [
                new SaveApplicationSuccess({ application }),
                new ShowSuccessCreatedUpdatedNotification(application.isNew, 'Application')
            ]));
        })), (error) => of(new SaveApplicationFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(error))));
        this.deleteApplication = this.catchEffect(CoreActionTypes.DELETE_APPLICATION, actions => actions.pipe(map((action) => action.payload.application), switchMap((application) => this.applicationService
            .deleteApplication(application)
            .pipe(flatMap(() => [
            new DeleteApplicationSuccess({ application }),
            new ShowSuccessNotification('Application was deleted')
        ])))), (err) => of(new DeleteApplicationFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.saveUnit = this.catchEffect(CoreActionTypes.SAVE_UNIT, actions => actions.pipe(map((action) => action.payload.unit), switchMap((unit) => {
            const u = unit.isNew
                ? this.unitService.createNewUnit(unit)
                : this.unitService.updateUnit(unit);
            return u.pipe(tap(() => this.router.navigate(['/configuration/units'])), flatMap(() => [
                new SaveUnitSuccess({ unit }),
                new ShowSuccessCreatedUpdatedNotification(unit.isNew, 'Unit')
            ]));
        })), (err) => of(new SaveUnitFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.deleteUnit = this.catchEffect(CoreActionTypes.DELETE_UNIT, actions => actions.pipe(map((action) => action.payload.unit), switchMap((unit) => this.unitService
            .deleteUnit(unit)
            .pipe(flatMap(() => [
            new DeleteUnitSuccess({ unit }),
            new ShowSuccessNotification('Unit was deleted')
        ])))), (err) => of(new DeleteUnitFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.assignUsersToUnit = this.catchEffect(CoreActionTypes.ASSIGN_USER, actions => actions.pipe(switchMap((action) => this.unitService
            .setUsers(action.payload.unit, action.payload.userIds)
            .pipe(tap(() => this.router.navigate(['/configuration/units'])), flatMap(() => [
            new AssignUsersToUnitSuccess(action.payload),
            new ShowSuccessNotification('Users were assigned')
        ])))), (err) => of(new AssignUsersToUnitFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.changeUserAvatar = this.catchEffect(CoreActionTypes.CHANGE_USER_AVATAR, actions => actions.pipe(map((action) => action.payload), switchMap(({ avatar }) => {
            return this.userService.changeAvatar(avatar)
                .pipe(flatMap(() => [
                new ChangeUserAvatarSuccess({ avatar }),
                new ShowSuccessCreatedUpdatedNotification(false, 'Avatar')
            ]));
        })), (err) => of(new ChangeUserAvatarFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.changeUserPassword = this.catchEffect(CoreActionTypes.CHANGE_USER_PASSWORD, actions => actions.pipe(map((action) => action.payload), switchMap(({ oldPassword, newPassword }) => {
            return this.userService.changePassword(oldPassword, newPassword)
                .pipe(flatMap(() => [
                new ChangeUserPasswordSuccess(),
                new ShowSuccessCreatedUpdatedNotification(false, 'Password')
            ]));
        })), (err) => of(new ChangeUserPasswordFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.saveUser = this.catchEffect(CoreActionTypes.SAVE_USER, actions => actions.pipe(map((action) => action.payload.user), switchMap((user) => {
            const u = user.isNew
                ? this.userService.createNewUser(user)
                : this.userService.updateUser(user);
            return u.pipe(tap(() => this.router.navigate(['/configuration/users'])), flatMap(updatedUser => [
                new SaveUserSuccess({ user: new User(updatedUser) }),
                new ShowSuccessCreatedUpdatedNotification(user.isNew, 'User')
            ]));
        })), (err) => of(new SaveUserFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.deleteUser = this.catchEffect(CoreActionTypes.DELETE_USER, actions => actions.pipe(map((action) => action.payload.user), switchMap(user => this.userService
            .deleteUser(user)
            .pipe(tap(() => this.router.navigate(['/configuration/users'])), flatMap(() => [
            new DeleteUserSuccess({ user }),
            new ShowSuccessNotification('User was deleted')
        ])))), (err) => of(new DeleteUserFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.activation = this.catchEffect(CoreActionTypes.ACTIVATE, actions => actions.pipe(map((action) => action.payload), switchMap(payload => this.authService
            .activate(payload.password, payload.token, payload.userId)
            .pipe(tap(() => {
            if (payload.userId != null) {
                this.router.navigate(['/configuration/users']);
            }
        }), flatMap(response => {
            const ret = [new ActivateSuccessAction({ userId: payload.userId })];
            if (payload.userId == null) {
                ret.push(new ShowSuccessNotification('User activated, Logging in...'));
                ret.push(new SignInAction({ email: response['email'], password: payload.password }));
            }
            else {
                ret.push(new ShowSuccessNotification('User activated'));
            }
            return ret;
        })))), (err) => of(new ActivateFailureAction(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.saveRole = this.catchEffect(CoreActionTypes.SAVE_ROLE, actions => actions.pipe(map((action) => action.payload.role), switchMap((role) => {
            const c = role.isNew
                ? this.roleService.createNewRole(role)
                : this.roleService.updateRole(role);
            return c.pipe(tap(() => this.router.navigate(['/configuration/roles'])), flatMap(updatedRole => [
                new SaveRoleSuccess({ role: new Role(updatedRole) }),
                new ShowSuccessCreatedUpdatedNotification(role.isNew, 'Role')
            ]));
        })), (err) => of(new SaveRoleFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.deleteRole = this.catchEffect(CoreActionTypes.DELETE_ROLE, actions => actions.pipe(map((action) => action.payload.role), switchMap((role) => this.roleService
            .deleteRole(role)
            .pipe(flatMap(() => [
            new DeleteRoleSuccess({ role }),
            new ShowSuccessNotification('Role was deleted')
        ])))), (err) => of(new DeleteRoleFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
        this.loadUnits = this.catchEffect(CoreActionTypes.LOAD_UNITS, actions => actions.pipe(switchMap(() => this.unitService.fetch()), map((units) => new LoadUnitsSuccess({ units }))), (err) => of(new LoadUnitsFailure(), new ShowErrorNotification(HttpHelper.getErrorMessage(err))));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "signIn", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "signInSuccess", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "initializeApplication", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "initializeMenu", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "setActiveUser", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "checkAuthorization", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "checkAuthorizationSuccess", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "navigateAfterSignIn", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "saveClient", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "deleteClient", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "saveApplication", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "deleteApplication", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "saveUnit", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "deleteUnit", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "assignUsersToUnit", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "changeUserAvatar", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "changeUserPassword", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "saveUser", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "deleteUser", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "activation", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "saveRole", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "deleteRole", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CoreEffects.prototype, "loadUnits", void 0);
