import { Component, OnInit } from '@angular/core';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { ClearNotification } from '../../store/core.actions';
import '../../../global/helpers/rxjsExtension';
import { interval } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { whenTrue } from '../../../global/helpers/rxjsExtension';
import { Notification } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    public notification: Notification;

    constructor(private store: Store<GlobalState>) {
    }

    ngOnInit() {
        this.store.select(store => store.core.data.notification).pipe(
            tap((notification: Notification) => this.notification = notification),
            whenTrue(),
        ).subscribe((notification: Notification) => interval(notification.isSuccess() ? 3000 : 15000)
            .pipe(first())
            .subscribe(() => this.close())
        );
    }

    close() {
        this.store.dispatch(new ClearNotification());
    }

}
