import { Injectable } from '@angular/core';
import { IUserAuthorizations, AuthorizationAction, AuthorizationResource, Authorization } from '@wildflowerhealth/console-shared';

export const KEYS = Object.freeze({
    TOKEN: 'token', // FXIME: there is a copy of this constant in app.module.ts
    USER_ID: 'user_id',
    PERMISSION: 'permission',
    AUTHORIZATIONS: 'auths',
    CONTENT_GRID_CONFIG: 'content_grid_config',
});

@Injectable()
export class LocalStorageService {
    static get(key: string): string {
        return localStorage.getItem(key);
    }

    static set(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    constructor() {
    }

    setToken(token: string): void {
        LocalStorageService.set(KEYS.TOKEN, token);
    }

    getToken(): string {
        return LocalStorageService.get(KEYS.TOKEN);
    }

    setUserId(id: string): void {
        LocalStorageService.set(KEYS.USER_ID, id);
    }

    getUserId(): string {
        return LocalStorageService.get(KEYS.USER_ID);
    }

    setAuthorizations(auths: IUserAuthorizations) {
        LocalStorageService.set(KEYS.AUTHORIZATIONS, JSON.stringify(auths));
    }

    hasAuthorization(action: AuthorizationAction, ...resource: AuthorizationResource[]) {
        return Authorization.isAuthorized(this.getAuthorizations(), action, ...resource);
    }

    getAuthorizations(): IUserAuthorizations {
        const authStr = LocalStorageService.get(KEYS.AUTHORIZATIONS);
        return authStr ? JSON.parse(authStr) : {};
    }

    setContentGridConfig(config: string): void {
        LocalStorageService.set(KEYS.CONTENT_GRID_CONFIG, config);
    }

    getContentGridConfig(): string {
        return LocalStorageService.get(KEYS.CONTENT_GRID_CONFIG);
    }

    clearAuth(): void {
        const config = this.getContentGridConfig();
        localStorage.clear();
        this.setContentGridConfig(config);
    }

    clear(): void {
        localStorage.clear();
    }
}
