import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { IOption } from '@wildflowerhealth/console-shared';
import { List } from 'immutable';
import { ControlValueAccessor, DefaultValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'wf-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [DefaultValueAccessor],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() options: List<IOption>;
    @Input() wfDisabled = false;
    @Input() alwaysActivated = false;
    @Input() showDisabledFirstOption = true;

    public value: string;
    public onTouchCallback = () => {};
    private onChangeCallback = (_value: any) => {};

    constructor(@Optional() public parentNgControl: NgControl) {
        parentNgControl.valueAccessor = this;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(cb: (value: any) => void): void {
        this.onChangeCallback = cb;
    }

    registerOnTouched(cb: () => void): void {
        this.onTouchCallback = cb;
    }

    update(value): void {
        this.value = value;
        this.onChangeCallback(value);
    }

    get error(): string {
        return Object
            .keys(this.parentNgControl.errors || {})
            .reverse()
            .reduce((_prev, current) => current, '');
    }
}
