import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { map } from 'rxjs/operators';
import { Role } from '@wildflowerhealth/console-shared';

@Injectable()
export class RoleService {

    private API_URL = this.environmentService.getAPIUrl() + '/roles';

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    fetch(): Observable<List<Role>> {
        return this.http
            .get<any[]>(this.API_URL)
            .pipe(map(res => List(res)
                .map(item => new Role(item))
                .toList()
            ));
    }

    deleteRole(role: Role) {
        return this.http.delete(`${this.API_URL}/${role.id}`);
    }

    createNewRole(role: Role) {
        return this.http.post(this.API_URL, role.toJSON());
    }

    updateRole(role: Role) {
        return this.http.put(`${this.API_URL}/${role.id}`, role.toJSON());
    }

}
