import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { List } from 'immutable';
import { slideFromRightListAnimation } from '../../core.animations';
import { Role } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-roles-list',
    templateUrl: './roles-list.component.html',
    animations: slideFromRightListAnimation,
    styleUrls: ['./roles-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesListComponent {

    @Input() roles: List<Role>;
    @Input() selectedRole: Role;
    @Input() viewSelectMode = false;
    @Output() select: EventEmitter<Role> = new EventEmitter<Role>();
    @Output() edit: EventEmitter<Role> = new EventEmitter<Role>();
    @Output() delete: EventEmitter<Role> = new EventEmitter<Role>();

    roleStyles(role: Role): string {
        return this.isRoleSelected(role) ? 'selected' : '';
    }

    isRoleSelected(role: Role): boolean {
        return this.selectedRole && this.selectedRole.id === role.id;
    }

    editClick(role: Role): void {
        this.selectedRole && this.selectedRole.id === role.id
            ? this.edit.next(role)
            : this.select.next(role);
    }

    deleteClick(role: Role): void {
        this.selectedRole && this.selectedRole.id === role.id
            ? this.delete.next(role)
            : this.select.next(role);
    }
}
