import { List } from 'immutable';
import { TextLanguage, Locale, TextLevel } from '@wildflowerhealth/console-shared';

export const getLocaleKey = (language: TextLanguage, level: TextLevel) => `${language},${level}`;
export const getLocaleFromKey = (key: string): Locale => {
    const [lng, lvl] = key.split(',');
    return new Locale({
        language: TextLanguage[lng],
        level: TextLevel[lvl]
    });
};

export const getAllLocaleKeys = (): List<string> => Locale.ALL_LOCALES
    .map(locale => getLocaleKey(locale.language, locale.level))
    .toList();
