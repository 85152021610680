<div class="tile">
    <wf-tile-header [icon]="'smartphone-iphone'" [label]="'Applications'"></wf-tile-header>
    <wf-applications-list [applications]="store | select:'core.data.applications' | animateList | async"
        [selectedApplication]="store | select:'core.data.selectedApplication' | async" (select)="select($event)"
        (edit)="edit($event)" (users)="users($event)" (delete)="delete($event)"></wf-applications-list>

    <div class="controls controls-right" *ngIf="isEditMode | async">
        <button class="btn btn-primary btn-action" (click)="create()">
            <i class="zmdi zmdi-plus"></i>
        </button>
    </div>

    <wf-spinner *ngIf="store | select: 'core.data.isApplicationDeletion' | async"></wf-spinner>
</div>
