import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { CoreOptionsHelper } from '../../helpers/OptionsHelper';
import { IOption, User, Client, Role } from '@wildflowerhealth/console-shared';
import { WfValidators } from '../../validators/WfValidator';
import { SaveUser } from '../../store/core.actions';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'wf-user-form-page',
    templateUrl: './user-form-page.component.html',
    styleUrls: ['./user-form-page.component.scss']
})
export class UserFormPageComponent implements OnInit {

    public form: FormGroup;
    public clientOptions: List<IOption>;
    public user: User;
    public title: string;
    public roles: Role[];

    private selectedClient: Client;

    constructor(private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        public store: Store<GlobalState>,
        private router: Router) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'])),
            this.store.select(store => store.core.data.users),
            this.store.select(state => state.core.data.clients),
            this.store.select(state => state.core.data.selectedClient),
            this.store.select(state => state.core.data.roles),
        ]).subscribe(data => {
            this.user = data[1].find(user => user.id === data[0]) || null;
            this.clientOptions = CoreOptionsHelper.getClients(data[2]);
            this.selectedClient = data[3];
            this.roles = data[4].sortBy(r => r.name).toArray();
            this.title = this.user ? 'Edit User' : 'Create new User';
            this.buildForm();
        });
    }


    buildForm(): void {
        this.form = this.formBuilder.group({
            clientId: [this.user ? this.user.clientId : this.selectedClient ? this.selectedClient.id : '', Validators.required],
            firstName: [this.user ? this.user.firstName : '', Validators.required],
            lastName: [this.user ? this.user.lastName : '', Validators.required],
            email: [this.user ? this.user.email : '', Validators.compose([Validators.required, WfValidators.email])],
            roles: new FormArray([])
        });
        this.roles.forEach(r => {
            (this.form.controls.roles as FormArray)
                .push(new FormControl(this.user.roleIds.includes(r.id)));
        });
    }

    save() {
        if (this.user == null) {
            this.user = new User();
        }
        this.user.clientId = this.form.value.clientId;
        this.user.firstName = this.form.value.firstName;
        this.user.lastName = this.form.value.lastName;
        this.user.email = this.form.value.email;
        this.user.roleIds = this.roles.map((r, i) => ({r, s: this.form.value.roles[i]})).filter(x => x.s).map(x => x.r.id);
        this.store.dispatch(new SaveUser({ user: this.user }));
    }

    close() {
        this.router.navigate(this.user
            ? ['/configuration/users/details', { id: this.user.id }]
            : ['/configuration/users']
        );
    }

}
