<div class="tile">
    <wf-tile-header [label]="title" [icon]="'account-add'" [showCloseButton]="true" (close)="close()"></wf-tile-header>
    <form [formGroup]="form" (ngSubmit)="save()" *ngIf="form">
        <div class="final-name" *ngIf="activatedRoute.snapshot.params.selectedApplicationId">
            <div class="label">Final Unit Name</div>
            <div class="name">{{fullName | async}}</div>
        </div>

        <wf-select [label]="'Application*'" formControlName="applicationId" [options]="applicationOptions"></wf-select>
        <wf-select [label]="'Client*'" formControlName="clientId" [options]="clientOptions"></wf-select>
        <wf-select [label]="'Segment*'" formControlName="segmentConst" [options]="segmentOptions"></wf-select>
        <wf-input [label]="activatedRoute.snapshot.params.selectedApplicationId ? 'Name postfix*' : 'Name*' "
            formControlName="name"></wf-input>

        <div class="controls">
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
        </div>
    </form>
    <wf-spinner *ngIf="store | select: 'core.data.isUnitSaving' | async"></wf-spinner>
</div>
