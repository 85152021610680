import { Component, Input, OnInit } from '@angular/core';
import { MenuSection } from '../../constants/MenuSection';

@Component({
    selector: 'wf-menu-sticker',
    templateUrl: './menu-sticker.component.html',
    styleUrls: ['./menu-sticker.component.scss']
})
export class MenuStickerComponent implements OnInit {

    @Input() menuSection: MenuSection;

    constructor() {
    }

    ngOnInit() {
    }

}
