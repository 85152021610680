/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../menu-sticker/menu-sticker.component.ngfactory";
import * as i3 from "../menu-sticker/menu-sticker.component";
import * as i4 from "@angular/common";
import * as i5 from "./home-page.component";
import * as i6 from "@ngrx/store";
var styles_HomePageComponent = [i0.styles];
var RenderType_HomePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomePageComponent, data: {} });
export { RenderType_HomePageComponent as RenderType_HomePageComponent };
function View_HomePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-menu-sticker", [], null, null, null, i2.View_MenuStickerComponent_0, i2.RenderType_MenuStickerComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuStickerComponent, [], { menuSection: [0, "menuSection"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomePageComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.menu)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-home-page", [], null, null, null, View_HomePageComponent_0, RenderType_HomePageComponent)), i1.ɵdid(1, 114688, null, 0, i5.HomePageComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomePageComponentNgFactory = i1.ɵccf("wf-home-page", i5.HomePageComponent, View_HomePageComponent_Host_0, {}, {}, []);
export { HomePageComponentNgFactory as HomePageComponentNgFactory };
