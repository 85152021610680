import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { SaveClient } from '../../store/core.actions';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { Client } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-client-form-page',
    templateUrl: './client-form-page.component.html',
    styleUrls: ['./client-form-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientFormPageComponent implements OnInit {
    public client: Client;
    public title: string;
    public form: FormGroup;

    constructor(private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        public store: Store<GlobalState>,
        private router: Router) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'] as string)),
            this.store.select(store => store.core.data.clients),
        ]).pipe(
            map(([id, clients]) => clients.find(client => client.id === id)),
        ).subscribe(client => {
            this.client = client || null;
            this.title = client ? 'Edit Client' : 'Add new Client';
            this.form = this.formBuilder.group({
                name: [client ? this.client.name : '', Validators.required],
                prefix: [client ? this.client.prefix : '', [Validators.required, Validators.maxLength(5)]],
                logo: [client ? this.client.logo : '']
            });
        });
    }

    save() {
        this.store.dispatch(new SaveClient({
            client: this.client
                ? this.client.fillFromForm(this.form.value)
                : new Client().fillFromForm(this.form.value)
        }));
    }

    close() {
        this.router.navigate(['/configuration/clients']);
    }
}
