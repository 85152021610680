import { Component, OnInit } from '@angular/core';
import { Client, User } from '@wildflowerhealth/console-shared';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../global/global.state';
import { ChangeUserAvatar } from '../../store/core.actions';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../cms/components/BaseComponent';

@Component({
  selector: 'wf-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent extends BaseComponent implements OnInit {
    activeUser: User;
    activeClient: Client;
    isUploaderShown = false;
    isAvatarChangingInProgress = false;
    isPasswordChangingInProgress = false;

  constructor(private store: Store<GlobalState>) {
      super();
  }

  ngOnInit() {
     this.store.select(s => s.core.data)
         .subscribe(coreData => {
             this.activeUser = coreData.activeUser;
             this.activeClient = coreData.clients.find(client => client.id === this.activeUser.clientId);
         });

      this.store.select(state => state.core.data.isAvatarChangingInProgress).pipe(
          takeUntil(this.componentDestroy$)).subscribe(status => this.isAvatarChangingInProgress = status);

      this.store.select(state => state.core.data.isPasswordChangingInProgress).pipe(
          takeUntil(this.componentDestroy$)).subscribe(status => this.isPasswordChangingInProgress = status);

  }

    handleImageChanged(image) {
        this.store.dispatch(new ChangeUserAvatar({
            avatar: image
        }));
    }

    handleDeleteAvatar() {
        this.store.dispatch(new ChangeUserAvatar({
            avatar: null
        }));
    }

}
