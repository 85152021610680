import { Component, Input, OnInit } from '@angular/core';
import { User } from '@wildflowerhealth/console-shared';

@Component({
    selector: 'wf-user-initials',
    templateUrl: './user-initials.component.html',
    styleUrls: ['./user-initials.component.scss']
})
export class UserInitialsComponent implements OnInit {
    @Input() user: User;

    constructor() {
    }

    ngOnInit() {
    }

}
