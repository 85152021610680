<div class="application-unit-list" *ngIf="clients && applications">
    <div class="application-unit" *ngFor="let application of applications" [@flyIn]="'in'"
        (click)="selectApplication.next(application)">

        <div class="application horizontal-group" (click)="selectApplication.next(application)"
            [ngClass]="{'selected': selectedApplication && application.id===selectedApplication.id}">

            <wf-image-icon [image]="application.logo" icon="smartphone-iphone" [size]="4" wfRound radius="0.5em">
            </wf-image-icon>

            <div class="group">
                <div class="name">{{application.name}}</div>
                <div class="bundle-id">{{application.bundleId}}</div>
            </div>

            <div class="inline-button" (click)="addUnitClick(application);$event.stopPropagation();">
                <i class="zmdi zmdi-plus-circle-o"></i>
            </div>
        </div>

        <div class="units" *ngIf="getUnits(application).size > 0">
            <div class="unit horizontal-group" *ngFor="let unit of getUnits(application)"
                (click)="selectUnit.next(unit);$event.stopPropagation()">
                <div class="connection"></div>

                <div class="unit-content horizontal-group"
                    [ngClass]="{'selected': selectedUnit && unit.id===selectedUnit.id}">
                    <div class="icon">
                        <i class="zmdi zmdi-collection-item"></i>
                    </div>

                    <div class="group">
                        <div class="name">{{unit.name}}</div>
                        <div class="client">
                            <i class="zmdi zmdi-city"></i>
                            {{getClientName(unit)}}
                        </div>
                        <div class="segment">
                            <i class="zmdi zmdi-chart-donut"></i>
                            {{unit.getSegmentName()}}
                        </div>
                    </div>


                    <div class="inline-button edit" (click)="editUnitClick(unit);$event.stopPropagation();">
                        <i class="zmdi zmdi-edit"></i>
                    </div>

                    <div class="inline-button delete" (click)="deleteUnitClick(unit);$event.stopPropagation();">
                        <i class="zmdi zmdi-delete"></i>
                    </div>

                    <div class="inline-button assign" (click)="manageUsersClick(unit);$event.stopPropagation();">
                        <i class="zmdi zmdi-accounts"></i>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
