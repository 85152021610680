/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../ui-components/image-icon/image-icon.component.ngfactory";
import * as i3 from "../../../ui-components/image-icon/image-icon.component";
import * as i4 from "../../../ui-components/round.directive";
import * as i5 from "../../../ui-components/user-initials/user-initials.component.ngfactory";
import * as i6 from "../../../ui-components/user-initials/user-initials.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../ui-components/image-uploader/image-uploader.component.ngfactory";
import * as i9 from "../../../ui-components/image-uploader/image-uploader.component";
import * as i10 from "../../../ui-components/change-password/change-password.component.ngfactory";
import * as i11 from "../../../ui-components/change-password/change-password.component";
import * as i12 from "@angular/forms";
import * as i13 from "@ngrx/store";
import * as i14 from "../../../ui-components/spinner/spinner.component.ngfactory";
import * as i15 from "../../../ui-components/spinner/spinner.component";
import * as i16 from "./profile-page.component";
var styles_ProfilePageComponent = [i0.styles];
var RenderType_ProfilePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfilePageComponent, data: {} });
export { RenderType_ProfilePageComponent as RenderType_ProfilePageComponent };
function View_ProfilePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "reset-style-button delete-avatar-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleDeleteAvatar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "zmdi zmdi-close-circle-o"]], null, null, null, null, null))], null, null); }
function View_ProfilePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "wf-image-icon", [["wfRound", ""]], null, null, null, i2.View_ImageIconComponent_0, i2.RenderType_ImageIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.ImageIconComponent, [], { image: [0, "image"], size: [1, "size"] }, null), i1.ɵdid(2, 81920, null, 0, i4.RoundDirective, [i1.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeUser.avatar; var currVal_1 = 5; _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 2, 0); }, null); }
function View_ProfilePageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-user-initials", [], null, null, null, i5.View_UserInitialsComponent_0, i5.RenderType_UserInitialsComponent)), i1.ɵdid(1, 114688, null, 0, i6.UserInitialsComponent, [], { user: [0, "user"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeUser; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfilePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "page-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["User Details:"])), (_l()(), i1.ɵeld(3, 0, null, null, 23, "div", [["class", "user-details tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "user-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfilePageComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfilePageComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["userInitials", 2]], null, 0, null, View_ProfilePageComponent_3)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "wf-image-uploader", [], null, [[null, "imageChanged"], [null, "uploaderClosed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageChanged" === en)) {
        var pd_0 = (_co.handleImageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("uploaderClosed" === en)) {
        var pd_1 = ((_co.isUploaderShown = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_ImageUploaderComponent_0, i8.RenderType_ImageUploaderComponent)), i1.ɵdid(11, 114688, null, 0, i9.ImageUploaderComponent, [], { image: [0, "image"], isUploaderShown: [1, "isUploaderShown"], isRound: [2, "isRound"] }, { imageChanged: "imageChanged", uploaderClosed: "uploaderClosed" }), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [["class", "user-details-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Full Name: "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", " ", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Client: "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email: "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", ""])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "change-avatar-button reset-style-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isUploaderShown = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Change Avatar "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "wf-change-password", [], null, null, null, i10.View_ChangePasswordComponent_0, i10.RenderType_ChangePasswordComponent)), i1.ɵdid(28, 245760, null, 0, i11.ChangePasswordComponent, [i12.FormBuilder, i13.Store], null, null), (_l()(), i1.ɵeld(29, 0, null, null, 1, "wf-spinner", [], [[8, "hidden", 0]], null, null, i14.View_SpinnerComponent_0, i14.RenderType_SpinnerComponent)), i1.ɵdid(30, 114688, null, 0, i15.SpinnerComponent, [], { label: [0, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeUser.avatar; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.activeUser.avatar; var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = ((_co.activeUser == null) ? null : _co.activeUser.avatar); var currVal_4 = _co.isUploaderShown; var currVal_5 = true; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); _ck(_v, 28, 0); var currVal_11 = "Changing in progress..."; _ck(_v, 30, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = ((_co.activeUser == null) ? null : _co.activeUser.firstName); var currVal_7 = ((_co.activeUser == null) ? null : _co.activeUser.lastName); _ck(_v, 16, 0, currVal_6, currVal_7); var currVal_8 = ((_co.activeClient == null) ? null : _co.activeClient.name); _ck(_v, 20, 0, currVal_8); var currVal_9 = ((_co.activeUser == null) ? null : _co.activeUser.email); _ck(_v, 24, 0, currVal_9); var currVal_10 = !_co.isPasswordChangingInProgress; _ck(_v, 29, 0, currVal_10); }); }
export function View_ProfilePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wf-profile-page", [], null, null, null, View_ProfilePageComponent_0, RenderType_ProfilePageComponent)), i1.ɵdid(1, 245760, null, 0, i16.ProfilePageComponent, [i13.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfilePageComponentNgFactory = i1.ɵccf("wf-profile-page", i16.ProfilePageComponent, View_ProfilePageComponent_Host_0, {}, {}, []);
export { ProfilePageComponentNgFactory as ProfilePageComponentNgFactory };
