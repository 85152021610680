import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SocketService } from '../core/services/socket.service';

// newrelic agent conditionally injected in index.html
declare const NREUM;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // this is called before DI is initialized
    // so it must be done in the handleError() method
    constructor(private injector: Injector) { }

    handleError(error) {
        try {
            if (typeof NREUM !== 'undefined' && typeof NREUM.noticeError === 'function' && error != null) {
                NREUM.noticeError(error);
            }

            const message = error == null ? 'error is null' : error.message ? error.message : error.toString();
            const stack = error == null ? null : error.stack;

            console.error('GlobalErrorHandler.handleError() message:', message, 'error:', error, 'stack:', stack);

            const socketSvc = this.injector.get(SocketService);
            socketSvc.send({
                type: 'GlobalErrorHandler',
                message,
                stack: stack,
            });
        } catch (err) {
            console.error('GlobalErrorHandler.handleError() error handling error 😵🔫:', err);
        }
    }
}
