<div class="modal" [ngClass]="{'show': modal}" (click)="modal?.closeOnOutsideClick && modalService.cancelModal()">
    <div class="tile" (click)="$event.stopPropagation()" *ngIf="modal">
        <wf-tile-header [label]="modal.header" [icon]="modal.isQuestion() ? 'help-outline': 'alert-circle-o'">
        </wf-tile-header>
        <div class="text-content">{{modal.message}}</div>
        <form [formGroup]="form" *ngIf="modal.isPassword()">
            <wf-input [label]="'Enter your password'" formControlName="password" [type]="'password'"></wf-input>
            <wf-input [label]="'Confirm password'" formControlName="passwordConfirm" [type]="'password'"></wf-input>
        </form>
        <div class="controls controls-center">
            <button *ngIf="modal.isQuestion() || modal.isPassword()" class="btn" [ngClass]="{
                  'btn-flat': modal.isCancelButtonRegular(),
                  'btn-primary': modal.isCancelButtonPrimary(),
                  'btn-danger': modal.isCancelButtonDanger(),
                  'btn-warning': modal.isCancelButtonWarning()
                }" (click)="modalService.cancelModal()">
                {{modal.cancelButton}}
            </button>
            <button class="btn" [ngClass]="{
                  'btn-flat': modal.isConfirmationButtonRegular(),
                  'btn-primary': modal.isConfirmationButtonPrimary(),
                  'btn-danger': modal.isConfirmationButtonDanger(),
                  'btn-warning': modal.isConfirmationButtonWarning()
                }" [disabled]="modal.isPassword() && !form.valid" (click)="confirm()">
                {{modal.confirmButton}}
            </button>
        </div>
    </div>
</div>
