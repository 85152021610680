import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { IDistributedConfig } from '@wildflowerhealth/console-shared';

@Injectable()
export class DistributedConfigService {

    private API_URL = this.environmentService.getAPIUrl() + '/config/distributed';

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    fetch(): Observable<IDistributedConfig> {
        return this.http.get<IDistributedConfig>(this.API_URL);
    }

}
