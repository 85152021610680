<div class="input-group">
    <input *ngIf="textStub" [value]="textStub" disabled class="ng-touched ng-valid disabled" [ngClass]="{
                'wf-empty': textStub === '',
                'wf-filled': textStub !== ''
    }">
    <input #wfInput autocomplete="new-password" *ngIf="!textStub" [ngClass]="{
                'ng-touched': parentNgControl.touched,
                'ng-untouched': !parentNgControl.touched,
                'ng-valid': parentNgControl.valid,
                'ng-invalid': !parentNgControl.valid,
                'ng-pristine': parentNgControl.pristine,
                'ng-dirty': !parentNgControl.pristine,
                'wf-empty': value === '',
                'wf-filled': value !== '' && value !== null,
                'wf-active': alwaysActivated
            }" type="{{type}}" [value]="value" [disabled]="wfDisabled" (blur)="touch()" (keyup)="update(wfInput.value)"
        (focus)="focus.next()">
    <span class="bar"></span>
    <label>{{label}}</label>
    <wf-input-error [ngClass]="{'always-show-errors': displayErrors}" [error]="error"></wf-input-error>
</div>
