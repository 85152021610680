import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { SaveApplication } from '../../store/core.actions';
import { ProductOptions } from '../../constants/ProductOptions';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Application, IOption } from '@wildflowerhealth/console-shared';
import { List } from 'immutable';
import { WfValidators } from '../../validators/WfValidator';

@Component({
    selector: 'wf-application-form-page',
    templateUrl: './application-form-page.component.html',
    styleUrls: ['./application-form-page.component.scss']
})
export class ApplicationFormPageComponent implements OnInit {

    private application: Application;
    public title: string;
    public form: FormGroup;
    public productOptions = ProductOptions;

    wfhEnvOptions: List<IOption> = List([
        { name: 'dev', value: 'dev' },
        { name: 'stage', value: 'stage' },
        { name: 'iat', value: 'iat' },
        { name: 'uat', value: 'uat' },
        { name: 'prod', value: 'prod' },
    ]);

    constructor(private formBuilder: FormBuilder,
                private activatedRoute: ActivatedRoute,
                public store: Store<GlobalState>,
                private router: Router) {
    }

    ngOnInit() {
        combineLatest([
            this.activatedRoute.params.pipe(map(params => params['id'] as string)),
            this.store.select(store => store.core.data.applications),
        ]).pipe(
            map(([id, applications]) => applications.find(application => application.id === id)),
        ).subscribe(application => {
            this.application = application || null;
            this.title = application ? 'Edit Application' : 'Add new Application';
            const appDnsNames = application?.appDnsNames?.map(dnsConfig => this.createAppDnsNameFormGroup(dnsConfig));
            this.form = this.formBuilder.group({
                fullName: [application?.fullName || '', Validators.required],
                name: [application?.name || '', Validators.required],
                bundleId: [application?.bundleId || '', Validators.required],
                productConst: [application?.productConst || '', Validators.required],
                logo: [application?.logo || ''],
                iTunesID: [application?.iTunesID || ''],
                appHostName: [application?.appHostName || '', WfValidators.hostNamePattern],
                appDnsNames: this.formBuilder.array(appDnsNames?.length
                    ? appDnsNames
                    : [this.createAppDnsNameFormGroup()]),
            });
        });
    }

    public addAppDnsNameFormGroup() {
        const appDnsNames = this.form.get('appDnsNames') as FormArray;
        appDnsNames.push(this.createAppDnsNameFormGroup());
    }

    public removeOrClearEmail(i: number) {
        const appDnsNames = this.form.get('appDnsNames') as FormArray;
        if (appDnsNames.length > 1) {
            appDnsNames.removeAt(i);
        } else {
            appDnsNames.reset();
        }
    }

    private createAppDnsNameFormGroup(dnsConfig?): FormGroup {
        return new FormGroup({
            appDnsName: new FormControl(dnsConfig?.appDnsName || '', WfValidators.dnsNamePattern),
            wfhEnv: new FormControl(dnsConfig?.wfhEnv || 'dev')
        });
    }

    save() {
        if (!this.form.valid) {
            return;
        }
        this.store.dispatch(new SaveApplication({
            application: this.application
                ? this.application.fillFromForm(this.form.value)
                : new Application().fillFromForm(this.form.value)
        }));
    }

    close() {
        this.router.navigate(['/configuration/applications']);
    }

}
