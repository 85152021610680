import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalState } from '../../../global/global.state';
import { Store } from '@ngrx/store';
import { ActivateAction } from '../../store/core.actions';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WfValidators } from '../../validators/WfValidator';
import { map } from 'rxjs/operators';

@Component({
    selector: 'wf-activate-page',
    templateUrl: './activate-page.component.html',
    styleUrls: ['./activate-page.component.scss']
})
export class ActivatePageComponent implements OnInit {

    public error = 'Sorry, but you have provided incorrect link. Please, try again or ask administrator to provide new one';
    private token: string;
    public form: FormGroup;

    constructor(private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private store: Store<GlobalState>,
        private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.authService.reset();
        this.activatedRoute.params
            .pipe(map(params => params['token']))
            .subscribe((token: string) => {
                this.error = null;
                this.token = decodeURI(token).replace(/\s/g, '.'); // Unescaping dots because of webpack bug
            });

        this.form = this.formBuilder.group({
            password: ['', Validators.compose([Validators.required, WfValidators.minEightChars, WfValidators.numberPresent, WfValidators.letterPresent])],
            passwordConfirm: ['', Validators.compose([Validators.required, this.validateEquality.bind(this)])]
        });
    }

    validateEquality(control: AbstractControl) {
        const result = this.form ? control.value !== this.form.controls['password'].value : false;
        return result ? { notEqual: result } : null;
    }

    submit() {
        this.store.dispatch(new ActivateAction({ token: this.token, password: this.form.value['password'] }));
    }

}
