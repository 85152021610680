import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TileHeaderComponent } from './tile-header/tile-header.component';
import { HighlightOnHoverDirective } from './highlight-on-hover.directive';
import { ImageIconComponent } from './image-icon/image-icon.component';
import { RoundDirective } from './round.directive';
import { ImageInputComponent } from './image-input/image-input.component';
import { ImageCropperModule } from 'ngx-img-cropper';
import { SelectComponent } from './select/select.component';
import { LabelRowComponent } from './label-row/label-row.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { PermissionSelectorComponent } from './permission-selector/permission-selector.component';
import { ChartComponent } from './chart/chart.component';
import { TableComponent } from './table/table.component';
import { TileComponent } from './tile/tile.component';
import { LabelMultiRowComponent } from './label-multi-row/label-multi-row.component';
import { TextareaComponent } from './textarea/textarea.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ApiDocComponent } from './api-doc/api-doc.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { TabsComponent } from './tabs/tabs.component';
import { TagSelectorComponent } from './tag-selector/tag-selector.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { ToolTipDirective } from './tool-tip.directive';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { UserInitialsComponent } from './user-initials/user-initials.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ImageCropperModule
    ],
    declarations: [InputComponent, SpinnerComponent, DropdownComponent, TileHeaderComponent,
        HighlightOnHoverDirective, ImageIconComponent, RoundDirective, ImageInputComponent,
        SelectComponent, LabelRowComponent, CheckboxComponent, PermissionSelectorComponent,
        ChartComponent, TableComponent, TileComponent, LabelMultiRowComponent, TextareaComponent,
        DatePickerComponent, CalendarComponent, ApiDocComponent, DateRangePickerComponent, TabsComponent,
        TagSelectorComponent, InputErrorComponent, ToolTipDirective, TimePickerComponent, UserInitialsComponent,
        ImageUploaderComponent, ChangePasswordComponent],
    exports: [InputComponent, SpinnerComponent, DropdownComponent, TileHeaderComponent,
        HighlightOnHoverDirective, ImageIconComponent, RoundDirective, ImageInputComponent,
        SelectComponent, LabelRowComponent, CheckboxComponent, PermissionSelectorComponent,
        ChartComponent, TableComponent, TileComponent, LabelMultiRowComponent, TextareaComponent,
        DatePickerComponent, CalendarComponent, ApiDocComponent, DateRangePickerComponent, TabsComponent,
        TagSelectorComponent, InputErrorComponent, ToolTipDirective, TimePickerComponent, UserInitialsComponent,
        ImageUploaderComponent, ChangePasswordComponent]
})
export class UiComponentsModule {
}
