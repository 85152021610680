import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircularProgressComponent } from './circular-progress.component';



@NgModule({
    declarations: [CircularProgressComponent],
    exports: [
        CircularProgressComponent
    ],
    imports: [
        CommonModule
    ]
})
export class CircularProgressModule { }
