import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@wildflowerhealth/console-shared';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

const ACCEPTABLE_RESPONSE_STATUS_ALL = 'ALL';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
    constructor(private readonly router: Router) { }

    /**
     * pass acceptable response statuses for the error interceptor to ignore
     */
    static BuildAccpetableResponseStatusHeaders(...statuses: number[]) {
        return { [HttpHeaders.ACCEPTABLE_RESPONSE_STATUS]: statuses.join(',') };
    }

    /**
     * disable the error interceptor for this request
     */
    static BuildAcceptAllResponseStatusHeaders() {
        return { [HttpHeaders.ACCEPTABLE_RESPONSE_STATUS]: ACCEPTABLE_RESPONSE_STATUS_ALL };
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req.clone({
            headers: req.headers.delete(HttpHeaders.ACCEPTABLE_RESPONSE_STATUS),
        })).pipe(
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
